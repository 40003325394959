.person-intro__title {
    font-size: calc(30rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        font-size: calc(24rem/16);
    }
}

.person-intro__date {
    font-size: calc(18rem/16);
    margin-bottom: calc(20rem/16);
}
.person-intro__country {
    font-size: calc(18rem/16);
    margin-bottom: calc(20rem/16);
}

.person-intro__icons {
    font-size: calc(30rem/16);
}
.person-intro__icons .icon {
    color: var(--color-dark-grey);
    transition: color 0.2s ease;
}
.person-intro__icons .icon:hover {
    color: var(--color-default);
    cursor: pointer;
}
.person-intro__category {
    text-transform: uppercase;
    margin-bottom: calc(15rem/16);
}