/* no gutter */
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}


/* pixel gutters */
.row.row--gutter-width-5 {
    margin-left:calc(-2.5rem/16);
    margin-right:calc(-2.5rem/16);
}
.row--gutter-width-5 > .col,
.row--gutter-width-5 > [class^="col"],
.row--gutter-width-5 > [class*=" col"] {
    padding-left:calc(2.5rem/16);
    padding-right:calc(2.5rem/16);
}

.row.row--gutter-width-10 {
    margin-left:calc(-5rem/16);
    margin-right:calc(-5rem/16);
}
.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:calc(5rem/16);
    padding-right:calc(5rem/16);
}

.row.row--gutter-width-20 {
    margin-left:calc(-10rem/16);
    margin-right:calc(-10rem/16);
}
.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:calc(10rem/16);
    padding-right:calc(10rem/16);
}

.row.row--gutter-width-60 {
    margin-left:calc(-30rem/16);
    margin-right:calc(-30rem/16);
}
.row--gutter-width-60 > .col,
.row--gutter-width-60 > [class^="col"],
.row--gutter-width-60 > [class*=" col"] {
    padding-left:calc(30rem/16);
    padding-right:calc(30rem/16);
}

@media screen and (max-width: 767px){
    .row.row--gutter-width-10-xs {
        margin-left:calc(-5rem/16);
        margin-right:calc(-5rem/16);
    }
    .row--gutter-width-10-xs > .col,
    .row--gutter-width-10-xs > [class^="col"],
    .row--gutter-width-10-xs > [class*=" col"] {
        padding-left:calc(5rem/16);
        padding-right:calc(5rem/16);
    }

    .row.row--gutter-width-0-xs {
        margin-left:0;
        margin-right:0;
    }
    .row--gutter-width-0-xs > .col,
    .row--gutter-width-0-xs > [class^="col"],
    .row--gutter-width-0-xs > [class*=" col"] {
        padding-left:0;
        padding-right:0;
    }

}


/* rem gutters */
.row-gutter--1 {
    margin-left: -.125rem;
    margin-right: -.125rem;
}
.row-gutter--1 > .col,
.row-gutter--1 > [class^="col"],
.row-gutter--1 > [class*=" col"] {
    padding-left: .125rem;
    padding-right: .125rem;
}
.row-gutter--2 {
    margin-left: -.25rem;
    margin-right: -.25rem;
}
.row-gutter--2 > .col,
.row-gutter--2 > [class^="col"],
.row-gutter--2 > [class*=" col"] {
    padding-left: .25rem;
    padding-right: .25rem;
}
.row-gutter--3 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.row-gutter--3 > .col,
.row-gutter--3 > [class^="col"],
.row-gutter--3 > [class*=" col"] {
    padding-left: .5rem;
    padding-right: .5rem;
}
.row-gutter--4 {
    margin-left: -.75rem;
    margin-right: -.75rem;
}
.row-gutter--4 > .col,
.row-gutter--4 > [class^="col"],
.row-gutter--4 > [class*=" col"] {
    padding-left: .75rem;
    padding-right: .75rem;
}


/* Vertical gutter **/

.row--vertical-gutter-5 {
    margin-top: calc(-5rem/16);
    clear: both;
}
.row--vertical-gutter-5 > [class^="col"],
.row--vertical-gutter-5 > [class*=" col"] {
    margin-top: calc(5rem/16);
}


.row--vertical-gutter-10 {
    margin-top: calc(-10rem/16);
    clear: both;
}
.row--vertical-gutter-10 > [class^="col"],
.row--vertical-gutter-10 > [class*=" col"] {
    margin-top: calc(10rem/16);
}

.row--vertical-gutter-30 {
    margin-top: calc(-30rem/16);
    clear: both;
}
.row--vertical-gutter-30 > [class^="col"],
.row--vertical-gutter-30 > [class*=" col"] {
    margin-top: calc(30rem/16);
}


.row--vertical-gutter-36 {
    margin-top: calc(-36rem/16);
    clear: both;
}
.row--vertical-gutter-36 > [class^="col"],
.row--vertical-gutter-36 > [class*=" col"] {
    margin-top: calc(36rem/16);
}

.row--vertical-gutter-37 {
    margin-top: calc(-37rem/16);
    clear: both;
}
.row--vertical-gutter-37 > [class^="col"],
.row--vertical-gutter-37 > [class*=" col"] {
    margin-top: calc(37rem/16);
}

.row--vertical-gutter-40 {
    margin-top: calc(-40rem/16);
    clear: both;
}
.row--vertical-gutter-40 > [class^="col"],
.row--vertical-gutter-40 > [class*=" col"] {
    margin-top: calc(40rem/16);
}

.row--vertical-gutter-60 {
    margin-top: calc(-60rem/16);
    clear: both;
}
.row--vertical-gutter-60 > [class^="col"],
.row--vertical-gutter-60 > [class*=" col"] {
    margin-top: calc(60rem/16);
}


@media screen and (max-width: 767px){
    .row--vertical-gutter-80-xs {
        margin-top: calc(-80rem/16);
        clear: both;
    }
    .row--vertical-gutter-80-xs > [class^="col"],
    .row--vertical-gutter-80-xs> [class*=" col"] {
        margin-top: calc(80rem/16);
    }

    .row--vertical-gutter-40-xs {
        margin-top: calc(-40rem/16);
        clear: both;
    }
    .row--vertical-gutter-40-xs > [class^="col"],
    .row--vertical-gutter-40-xs> [class*=" col"] {
        margin-top: calc(40rem/16);
    }

    .row--vertical-gutter-20-xs {
        margin-top: calc(-20rem/16);
        clear: both;
    }

    .row--vertical-gutter-20-xs > [class^="col"],
    .row--vertical-gutter-20-xs> [class*=" col"] {
        margin-top: calc(20rem/16);
    }

    .row--vertical-gutter-10-xs {
        margin-top: calc(-10rem/16);
        clear: both;
    }

    .row--vertical-gutter-10-xs > [class^="col"],
    .row--vertical-gutter-10-xs> [class*=" col"] {
        margin-top: calc(10rem/16);
    }
}

@media screen and (min-width: 768px) {
    .row.row--gutter-width-6-md {
        margin-left: calc(-3rem / 16);
        margin-right: calc(-3rem / 16);
    }

    .row--gutter-width-6-md > .col,
    .row--gutter-width-6-md > [class^="col"],
    .row--gutter-width-6-md > [class*=" col"] {
        padding-left: calc(3rem / 16);
        padding-right: calc(3rem / 16);
    }
}


/*use a media query for bigger row gutters; otherwise the page is scrollable on mobile devices */
@media screen and (min-width: 768px) {
    .row-gutter--5 {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
    .row-gutter--5 > .col,
    .row-gutter--5 > [class^="col"],
    .row-gutter--5 > [class*=" col"] {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}


/* -- */


.row--col-border>.col:not(:last-child),
.row--col-border>[class^="col"]:not(:last-child),
.row--col-border>[class*=" col"]:not(:last-child){
    border-right: 1px solid var(--color-grey);
}


.row--skew>.col,
.row--skew>[class*=col-] {
    transform: skew(-15deg) translateZ(0);
    overflow: hidden;

    margin: 0 calc(-1rem/16);
}

.row--skew-md>.col,
.row--skew-md>[class*=col-] {
    @media screen and (min-width: 768px){
        transform: skew(-15deg) translateZ(0);
        overflow: hidden;
    }
    margin: 0 calc(-1rem/16);
}


.row--skew__reskew-md{
    @media screen and (min-width: 768px){
        transform: skew(15deg) translateZ(0);

    }
}

.row--skew>.row--skew__overflow-visible[class^="col"],
.row--skew>.row--skew__overflow-visible[class*=col-]{
    overflow: visible;
}

.row--skew-md>.row--skew__overflow-visible[class^="col"],
.row--skew-md>.row--skew__overflow-visible[class*=col-]{
    @media screen and (min-width: 768px){
        overflow: visible;
    }
}