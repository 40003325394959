.voucher-form{
    @media screen and (min-width: 768px){
        display: flex;
    }
}
.voucher-form__input{
    width: 100%;
    @media screen and (min-width: 768px){
        flex-grow: 1;
    }
}
.voucher-form__input .form-control{
    height: calc(45rem/16);
}
.voucher-form__submit{
    width: 100%;
    height: calc(45rem/16);
    @media screen and (min-width: 768px){
        width: auto;
    }
}