.customer-account-dropdown__container {
    display: flex;
    justify-content: center;
}
.customer-account-dropdown__button {
    border-radius: calc(30rem / 16);
    border: 1px solid #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text-default);
    font-family: var(--font-title-default);
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    text-transform: uppercase;
    padding: calc(11rem/16) calc(30rem/16);
}
.customer-account-dropdown__button:hover,
.customer-account-dropdown__button:active,
.customer-account-dropdown__button:focus,
.customer-account-dropdown__button:focus-visible{
    color: var(--color-text-default);
    background-color: var(--color-light-grey);
    cursor: pointer;
}
.customer-account-dropdown {
    width: calc(400rem/16);
    margin-bottom: calc(29rem/16);
    max-width: calc(100vw - calc(40rem/16));
}
.customer-account-dropdown__icon {
    transition: transform 200ms ease-in-out;
    font-size: calc(8rem/16);
}
.customer-account-dropdown__button[aria-expanded=true] .customer-account-dropdown__icon {
    transform: rotate(180deg);
}
.customer-account-dropdown__menu {
    width: 100%;
    border: 1px solid var(--color-text-default);
    margin-top: calc(3rem/16);
}
.customer-account-dropdown__item-link {
    padding: calc(8rem / 16);
    display: block;
}
.customer-account-dropdown__item-link:focus-visible,
.customer-account-dropdown__item-link:hover{
    background-color: var(--color-light-grey);
}