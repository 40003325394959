.social-media-login{
    background-color: transparent;
    padding: calc(3rem/16) 0;
    margin-top: 30px;
}

.social-media-login__toggle{
    font-size: 8px;
    margin-left: 12px;
    transform: rotate(180deg);
}

.collapsed .social-media-login__toggle{
    transform: rotate(0deg);
}
.checkout-titles{
    font-family: var(--font-title-default);
}
.checkout-subtitles{
    font-family: var(--font-title-bold);
}
.checkout-labels{
    font-family: var(--font-default) !important;
    color: var(--color-text-muted);
    font-size: calc(12rem/16);
    line-height: calc(20rem/16);
}