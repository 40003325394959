.img-grid-wrapper{
    padding-top: calc(75rem/16);
    padding-bottom: calc(80rem/16);

    @media screen and (min-width: 768px) {
        padding-top: calc(93rem/16);
        padding-bottom: calc(94rem/16);
    }
}

.img-grid-wrapper .content-heading__title{
    margin-bottom: calc(11rem/16);
    font-size: calc(25rem/16);
    line-height: calc(36/25);
}

.img-grid-wrapper .content-heading{
    margin-bottom: calc(17rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(39rem/16);
    }
}

.img-grid-wrapper .content-heading__title{
    @media screen and (max-width: 768px) {
        font-size: calc(18rem/16);
        line-height: calc(20rem/16);
        letter-spacing: calc(1rem/16);
        margin-bottom: calc(12rem/16);
    }
}

.img-grid-wrapper .btn-inline-list{
    margin-top: calc(15rem/16);

    @media screen and (min-width: 769px){
        margin-top: calc(20rem/16);
    }
}

.bottom-title{
    font-family: var(--font-title-default);
    @media screen and (min-width: 769px){
        max-width: calc(852rem/16);
    }
}

.img-grid{
    margin-bottom: calc(70rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(77rem/16);
    }
}

.img-grid__container{
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: calc(37rem/16) calc(36rem/16);
    grid-template-areas:
        "Img1 Img3 Img5"
        "Img1 Img3 Img5"
        "Img1 Img3 Img5"
        "Img1 Img4 Img5"
        "Img2 Img4 Img6"
        "Img2 Img4 Img6"
        "Img2 Img4 Img6";

    @media screen and (max-width: 768px) {
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: calc(10rem/16) calc(10rem/16);
        grid-template-areas:
            "Img1 Img3"
            "Img1 Img3"
            "Img1 Img3"
            "Img1 Img2"
            "Img5 Img2"
            "Img5 Img2"
            "Img5 Img6"
            "Img5 Img6"
            "Img4 Img6"
            "Img4 ."
            "Img4 ."
            "Img4 .";
    }
}
.Img1 { grid-area: Img1; }
.Img2 { grid-area: Img2; }
.Img3 { grid-area: Img3; }
.Img4 { grid-area: Img4; }
.Img5 { grid-area: Img5; }
.Img6 { grid-area: Img6; }

.img-grid__img {
    position:relative;
    cursor:pointer;
}

/* hover */
.img-grid__img .img-grid__overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 1;
    transition: all 0.4s ease-in-out 0s;
}

.img-grid__img:hover .img-grid__overlay{
    opacity: 1;
}
.img-grid__button-wrapper {
    position: absolute;
    text-align: center;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
    width: 100%;
    top: 50%;
    left: 20%;
    opacity: 0;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.3s ease-in-out 0s;
}
.img-grid__img:hover .img-grid__button-wrapper{
    top: 50%;
    left: 50%;
    opacity: 1;
}
.img-grid__button {
    height: calc(50rem/16);
    width: calc(50rem/16);
    border-radius: 50%;
    background-color: #fff;
}