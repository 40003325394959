/*.lg-backdrop {
    background-color: #fff;
}*/
.lg-close.lg-icon,
.lg-next.lg-icon,
.lg-prev.lg-icon {
   color: #dedede;
}

.lightbox-video .btn-play{
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 50px;
    height: 50px;
    font-size: 26px;
    transition: box-shadow 250ms ease;

    @media screen and (min-width: 768px){
        width: 80px;
        height: 80px;
        font-size: 36px;
    }
}

.lightbox-video:hover .btn-play{
    box-shadow: 0 0 18px #fff;
}

.lightbox-video__text{
    position: absolute;
    bottom: 0;
    background-color: rgb(0 0 0 / 39%);
    color: #fff;
    left: 0;
    right: 0;
    padding: 7px 20px;
    font-size: 13px;
    text-align: center;
}

.lightbox-video-new .btn{
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(40rem/16);
    text-align: center;
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(78rem/16);
    }
}

.lightbox-video-new .btn .icon{
    transform: scale(0.93);
    transition: transform 250ms ease;
}

.lightbox-video-new:hover .btn .icon{
    @media screen and (min-width: 768px){
        transform: scale(1);
    }
}

.lightbox-video-new__btn-text{
    display: block;
    font-size: calc(14rem/16);
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(1.5rem/16);
    margin-top: calc(5rem/16);
}

.lg-outer .lg-video-cont{
    @media screen and (min-width: 768px){
       max-width: calc(1280rem/16)!important;
    }
}