.product-teaser-row{
    margin-bottom: calc(76rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(94rem/16);
    }
}

.product-teaser-row .product-slider .slider__arrow.slick-next{
    right: calc(5rem/16);
    top: 45%;

    @media screen and (min-width: 768px){
        right: calc(-15rem/16);
    }

    @media screen and (min-width: 1600px){
        right: calc(-25rem/16);
    }
}

.product-teaser-row .product-slider .slider__arrow.slick-prev{
    left: calc(5rem/16);
    top: 45%;

    @media screen and (min-width: 768px){
        left: calc(-25rem/16);
    }
}

.simple-img-teaser{
    position: relative;
    height: 100%;
    width: 100%;
}

.simple-img-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(30,30,30,0) 0%, rgba(30,30,30,0.8) 100%);
    opacity: 1;
    transition: opacity 350ms ease;
}

.simple-img-teaser:hover:before{
    opacity: 0.8;
}

.simple-img-teaser img{
    transition: transform 350ms ease;
    transform: scale(1.03);
}

.simple-img-teaser:hover img{
    transform: scale(1);
}

.simple-img-teaser__body{
    position: absolute;
    bottom: calc(18rem/16);
    z-index: 2;
    color: #fff;
    width: 100%;
    padding: 0 1.5rem;

    @media screen and (min-width:  768px) and (max-width: 1600px){
        bottom: calc(20rem/16);
        padding: 0 1rem;
    }

    @media screen and (min-width: 1601px){
        bottom: calc(32rem/16);
    }
}

.simple-img-teaser__title{
    text-transform: uppercase;
    line-height: calc(20/18);
    margin-bottom: calc(14rem/16);
    font-family: var(--font-title-default);

    @media screen and (min-width: 768px) and (max-width: 1600px){
        font-size: calc(18rem/16);
    }

    @media screen and (min-width: 1600px){
        line-height: calc(30/25);
        margin-bottom: calc(18rem/16);
    }
}

.simple-img-teaser__title--small{
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(22rem/16);
    }
}
.product-grid .simple-img-teaser__body{
    left: 0;
    right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.product-grid .simple-img-teaser__btns{
    padding: calc(18rem/16);
}
.product-grid .simple-img-teaser__title{
    font-size: calc(24rem/16);
    line-height: calc(32/24);
    margin-bottom: calc(12rem/16);
    font-family: var(--font-title-default);

    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
        line-height: calc(24/15);
    }
}

.product-grid .simple-img-teaser__btns .btn{
    font-family: var(--font-default);
}


.simple-img-teaser-slider{
    margin: 0 calc(-20rem/16) 0 calc(-5rem/16);

    @media screen and (min-width: 768px){
        margin: 0 calc(-18rem/16);
    }
}
.simple-img-teaser-slider .slick-slide{
    padding: 0 calc(5rem/16);

    @media screen and (min-width: 768px){
        padding: 0 calc(18rem/16);
    }
}

.simple-img-teaser__btns .btn{
    white-space: pre-wrap;
    display: flex;
    align-items: center;
}

.product-teaser-row .content-heading__title{
    font-size: calc(30rem/16);
    line-height: calc(35/30);
    margin-bottom: calc(21rem/16);

@media screen and (min-width: 1200px){
    font-size: calc(35rem/16);
    margin-bottom: calc(26rem/16);
}
/*
@media screen and (min-width: 1600px){
    font-size: calc(50rem/16);
    line-height: calc(60/50);
    margin-bottom: calc(26rem/16);
}*/
}
.content-page .product-teaser-row .content-heading__title{
    font-size: calc(18rem/16);
    line-height: calc(24/18);

    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
        line-height: calc(36/25);
        margin-bottom: calc(24rem/16)
    }
}
.product-teaser-row .content-heading{
    @media screen and (max-width: 768px) {
        margin-bottom: calc(29rem/16);
    }

    @media screen and (min-width: 768px) {
        margin-top: calc(96rem/16);
    }
}