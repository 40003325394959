.teaser-sujet-slide{
    position: relative;
    padding-top: calc(50rem/16);
    @media screen and (min-width: 768px){
       padding-top: calc(30rem/16);
    }

    @media screen and (min-width: 1200px){
        padding-top: calc(100rem/16);
    }
}

.teaser-sujet-slide__bg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    margin-bottom: -5.5%;
    background-position: bottom;
}


.teaser-sujet-slide:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(490rem/16);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    z-index: 1;
}

.teaser-sujet-slide>.container{
    z-index: 2;
    position: relative;
}

.teaser-sujet-slide__toptitle{
    font-size: calc(10rem/16);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);

    letter-spacing: calc(1rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(2rem/16);
        font-size: calc(12rem/16);
    }
}

.teaser-sujet-slide__title{
    margin-bottom: calc(15rem/16);
    font-size: calc(28rem/16);

    @media screen and (min-width: 768px){
        font-size: clamp(22px, 1.9vw, 35px);
        line-height: calc(50/35);
        letter-spacing: calc(2.5rem/16);
        margin-bottom: calc(8rem/16);
    }

    @media screen and (min-width: 1200px){
        letter-spacing: calc(4rem/16);
        margin-bottom: calc(15rem/16);
    }
}

.teaser-sujet-slide__content .btn{
    margin-top: calc(20rem/16);
}

.teaser-sujet-slide__text{
    font-size: calc(13rem/16);
    line-height: calc(26/14);

    @media screen and (min-width: 768px){
        font-size: clamp(11px, 1vw, 14px);
    }
}

.teaser-sujet-slide .simple-img-teaser{
    height: auto;
}

.teaser-sujet-slide .embed-responsive-simple-img-teaser-vertical-small:before{
    @media screen and (max-width: 767px){
       padding-top: 100%;
    }
}
.teaser-sujet-slider [class^="col"],
.teaser-sujet-slider [class*=" col"]{
    @media screen and (max-width: 767px){
        padding: 0 calc(5rem/16);
    }
}