.btn.btn-wishlist {
    background: transparent;
    color: var(--color-text-default);
    padding: 0;
    width: calc(51rem / 16);
    height: calc(51rem / 16);
    border: 1px solid var(--color-text-default);
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn.btn-wishlist--active {
    background: var(--color-text-default);
    color: var(--color-white);
}
.btn-wishlist .icon {
    font-size: calc(20rem/16);
    width: calc(23rem/16);
}
.btn.btn-wishlist:hover {
    background: var(--color-text-default);
    color: var(--color-white);
}

.navbar-right__item .icon-heart{
    font-size: calc(20rem/16);
}

/* wishlist-area */

.wishlist-area__title-block {
    border-bottom: 2px solid #191914;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(11rem/16);
}

.wishlist-area__title {
    margin-bottom: calc(30rem / 16);
}
@media screen and (min-width: 768px) {
    .wishlist-area__title {
        margin-bottom: calc(40rem / 16);
    }
}
.wishlist-list-item {
    padding-bottom: calc(40rem/16);
    padding-top: calc(30rem/16);
    border-bottom: 1px solid #E3E3E3;
}
@media screen and (max-width: 767px) {
    .wishlist-list-item {
        padding-bottom: calc(31rem/16);
    }
}
.wishlist-list-item__grid{
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: calc(37rem / 16);
}
@media screen and (max-width: 767px) {
    .wishlist-list-item__grid{
        gap: calc(12rem / 16);
    }
}
.wishlist-list-item__title {
    font-size: calc(13rem/16);
    line-height: calc(18/13);
}
@media screen and (min-width: 768px) {
    .wishlist-list-item__title {
        font-size: calc(18rem/16);
        line-height: calc(26/18);
    }
}
.wishlist-list-item__title-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.wishlist-list-item__content {
    width: 100%;
}
.wishlist-list-item__delivery-status.delivery-status {
    margin-bottom: 0;
}
.wishlist-list-item__data-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(30rem / 16);
    margin-top: calc(10rem / 16);
}

@media screen and (max-width: 767px) {
    .wishlist-list-item__data-price {
        flex-direction: column;
    }
    .wishlist-list-item__product-discount {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: calc(13rem / 16);
    }
    .wishlist-list-item__btn{
        width: 100%;
    }
}
@media screen and (min-width: 768px) {
    .wishlist-list-item__btn-container {
        display: grid;
        gap: calc(37rem / 16);
        grid-template-columns: 1fr 3fr;
        grid-template-areas: ". btn";
    }
    .wishlist-list-item__btn{
        grid-area: btn;
        width: max-content ;
    }
}
.wishlist-list-item__data-text {
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    color: #000000;
}
@media screen and (min-width: 768px) {
    .wishlist-list-item__data-text {
        font-size: calc(14rem/16);
        line-height: calc(18/14);
    }
}

.btn-back-in-stock-active {
    background-color: var(--color-white);
    align-items: center;
    display: flex;
    justify-content: center;
    width: max-content;
    border-color: #e3e3e3;
}
.btn-outline-default.btn-back-in-stock-active {
    border-color: #e3e3e3;
}
.btn-back-in-stock-active .icon {
    font-size: calc(20rem/16);
    margin-right: calc(10rem / 16);
}
.product-discount__discountprice--grey {
    color: #999999;
}
.wishlist-list-item__delivery-status.is-not-available{
    color: var(--color-secondary);
}
.wishlist-area__share-btn {
    appearance: none;
    background: transparent;
    border: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: calc(30rem/16);
}
@media screen and (min-width: 768px) {
    .wishlist-area__share-btn {
        margin-bottom: calc(40rem/16);
    }
}
.wishlist-share-btn__text{
    color: var(--color-text-default);
    text-decoration: underline;
    font-family: var(--font-title-bold);
    font-size: calc(11rem/16);
    line-height: calc(26/11);
    margin-left: calc(10rem / 16);
}
.wishlist-area__share-btn  .icon{
    font-size: calc(18rem/16);
    color: var(--color-text-default);
}
.wishlist-list-item__remove{
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-text-default);
}
.wishlist-area__empty {
    text-align: center;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: calc(40rem / 16);
}
.wishlist-area__empty-text {
    font-size: calc(18rem/16);
    line-height: calc(26/18);
    font-family: var(--font-title-default);
    text-transform: none;
    margin-top: calc(40rem / 16);
    margin-bottom: calc(30rem / 16);
}
.wishlist-area__user-name {
    font-size: calc(10rem/16);
    line-height: calc(15/10);
    font-family: var(--font-title-bold);
    margin-bottom: calc(30rem/16);
}
.wishlist-area__title.wishlist-area__title--sm-margin{
    margin-bottom: calc(10rem/16);
}
@media screen and (min-width: 768px) {
    .wishlist-area__user-name {
        font-size: calc(12rem/16);
        line-height: calc(20/12);
    }
    .wishlist-area__title.wishlist-area__title--sm-margin{
        margin-bottom: calc(15rem/16);
    }
}
.wishlist-list-item__price--grey {
    color: #999999;
}