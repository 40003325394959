.product-fact{
    font-size: calc(12rem/16);
    letter-spacing: calc(2.6rem/16);

    @media screen and (min-width: 768px) {
        max-width: calc(220rem/16);
    }

    @media screen and (min-width: 1200px) {
        max-width: calc(343.5rem/16);
    }
}

.product-fact+.product-fact{
    margin-top: calc(28rem/16);
}

.product-fact__score-item{
    height: calc(6rem/16);
    width: 100%;
    background-color: var(--color-grey);
    display: block;
}

.product-fact__score-item.active{
    background-color: var(--color-default);
}

.product-fact__title{
    font-size: calc(14rem/16);
    letter-spacing: 0;
    font-family: var(--font-default-bold);
}
