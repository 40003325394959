.product-discount{
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: calc(14rem/16);
    line-height: calc(26/14);

    @media screen and (max-width: 768px) {
        font-size: calc(12rem/16);
        line-height: calc(16/12);
    }
}
.cart-item__body-item .product-discount{
    @media screen and (max-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(14/14);
    }

    @media  screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(14/14);
    }
}
.product-discount--small{
    justify-content: space-between;

    font-size: calc(12rem/16);
    line-height: calc(16/12);
}
.product-discount--small-cart{
    justify-content: flex-start;

    font-size: calc(12rem/16);
    line-height: calc(16/12);
}

.product-discount__strikeprice{
    font-family: var(--font-default-bold);
    text-decoration: line-through;
    opacity: 0.5;
    white-space: nowrap;
}

.product-discount__discountprice{
    font-family: var(--font-title-default);
    white-space: nowrap;
}

.product-discount__discount{
    color: var(--color-secondary);
    font-family: var(--font-default-bold);
}

.product-discount__priceinfo{
    font-family: var(--font-default-bold);
}