.image-teaser {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    align-items: center;
    /*padding-bottom: calc(24rem/16);*/
}

.r-offset .embed-responsive:nth-of-type(2n) {
    margin-top: calc(30rem/16);
    margin-left: calc(30rem/16);
}

.r-offset .embed-responsive:first-of-type {
    margin-right: calc(30rem/16);
}

.l-offset .embed-responsive:first-of-type {
    margin-bottom: calc(30rem/16);
    margin-left: calc(30rem/16);
}

.l-offset .embed-responsive:nth-of-type(2n) {
    margin-right: calc(30rem/16);
}

.image-teaser__img {
    object-fit: cover;
}

@media (min-width: 768px) {
    .image-teaser {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: nowrap;
    }

    .r-offset,
    .l-offset {
        gap: calc(24rem/16);
    }

    .r-offset .embed-responsive:nth-of-type(2n) {
        margin-top: calc(49rem/16);
        margin-left: unset;

    }

    .r-offset .embed-responsive:first-of-type {
        margin-right: unset;
    }

    .l-offset .embed-responsive:first-of-type {
        margin-top: calc(49rem/16);
        margin-left: unset;
    }

    .l-offset .embed-responsive:nth-of-type(2n) {
        margin-bottom: unset;
        margin-right: unset;
    }

}

@media (min-width: 1200px) {
    .r-offset,
    .l-offset {
        gap: calc(38rem/16);
    }
}