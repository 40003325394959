.article-grid {
    position: relative;
    background: linear-gradient(39.98deg, #131313 0%, #191919 100%);
    z-index: 1;
    padding-top: calc(30rem/16);
    padding-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(45rem/16);
        padding-bottom: calc(70rem/16);
    }
}

.article-grid__bg{
    position: absolute;
    top: calc(-35rem/16);
    bottom: -45%;
    left: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
    transform: skewY(8deg);

    @media screen and (min-width: 1200px){
        top: calc(-136rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px){
        top: calc(-69rem/16);
    }
}
.article-grid__button .btn{
    background: #fff !important;
}
.article-grid__button {
    margin-top: calc(40rem/16);

    @media screen and (max-width: 767px){
        margin-top: calc(20rem/16);
    }
}

.article-grid__filter {
    margin-bottom: calc(20rem/16);
}
.article-grid__filter-item {
    margin: 0 calc(5rem/16);
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(20/14);
    text-transform: uppercase;
    padding: calc(15rem/16) calc(40rem/16);
    background-color: #fff;
    border: 1px solid var(--color-default);
    transition: all 250ms ease;
    border-radius: 50vh;

    @media screen and (max-width: 767px){
        margin: calc(5rem/16) calc(5rem/16);
    }
}

.article-grid__filter-item.active,
.article-grid__filter-item:hover {
    background-color: #000;
    color: #fff;
    cursor: pointer;
}
.article-grid__search-input {
    max-width: calc(350rem/16);
    margin-bottom: calc(60rem/16);

    @media screen and (max-width: 767px){
        margin-bottom: calc(40rem/16);
    }
}

.article-grid__search-input .form-control{
    border-radius: 50vh;
}


.article-grid__sub-filter-item {
    margin: 0 calc(5rem/16);
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(20/14);
    text-transform: uppercase;
    padding: calc(10rem/16) calc(20rem/16);
    background-color: #fff;
    border-bottom: 1px solid transparent;
    transition: all 250ms ease;

    @media screen and (max-width: 767px){
        margin: calc(5rem/16) calc(5rem/16);
    }
}

.article-grid__sub-filter-item.active,
.article-grid__sub-filter-item:hover {
    border-bottom: 1px solid var(--color-default);
}