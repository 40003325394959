.delivery-status{
    text-transform: none;
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    margin-bottom: calc(28rem/16);
    display: flex;

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
        line-height: calc(18/14);
        margin-bottom: calc(36rem/16);
    }
}

.delivery-status__circle{
    width: calc(8rem/16);
    height: calc(8rem/16);
    border-radius: 50%;
    display: block;
    margin-top: calc(5rem/16);
    margin-right: calc(10rem/16);
    background-color: var(--color-danger);
}

.is-available .delivery-status__circle{
    background-color: #65EF76;
}