.product-grid{
    position: relative;
}

.product-grid__title-block{
    font-size: calc(13rem/16);
    line-height: calc(24/13);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(26/16);
        margin-bottom: 0;
    }
}

.product-grid__title-block .h2{
    font-family: var(--font-title-default);
    font-size: calc(25rem/16);
    line-height: calc(32/25);
    hyphens: auto;

    @media screen and (min-width: 768px){
        font-size: calc(40rem/16);
        line-height: calc(50/40);
    }
}

.product-grid__title-block-btn.btn{
    margin-top: calc(-34rem/16);
    display: inline-block;
    font-family: var(--font-default-bold);
    letter-spacing: 0;

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.product-grid__title-block-btn.btn:hover{
    text-decoration: none;
}


.product-grid__title{
    margin-bottom: calc(14rem/16);
}

.product-grid__form{
    @media screen and (max-width: 767px){
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 101;
        transform: translateX(100%);
        background-color: #fff;
        transition: transform 300ms ease;
    }
}

.product-grid__form.is-open{
    @media screen and (max-width: 767px){
       transform: translateX(0);
    }
}

.product-grid__form-header{
    font-size: calc(12rem/16);
    line-height: calc(21/12);
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px){
        font-family: var(--font-title-bold);
    }

    @media screen and (max-width: 767px){
        background-color: #000;
        text-align: center;
        display: block;
        position: relative;
        color: #fff;
        font-size: calc(12rem/16);
        line-height: calc(21/12);
        letter-spacing: 1px;
        padding: calc(12rem/16) calc(20rem/16);
    }
}

.product-grid__form-header>.icon{
    margin-right: calc(10rem/16);
    font-size: calc(13rem/16);

    @media screen and (max-width: 767px){
        vertical-align: calc(-1rem/16);
        line-height: 0;
        margin-right: calc(12rem/16);
    }
}


.product-grid__form-title{
    @media screen and (max-width: 767px){
       font-size: calc(25rem/16);
        line-height: calc(32/25);
        font-family: var(--font-title-bold);
        text-transform: uppercase;
        margin-bottom: calc(25rem/16);
    }
}

.product-grid__form-close{
    color: var(--color-white);
    @media screen and (max-width: 767px){
        padding: calc(5rem/16);
        line-height: 0;
        font-size:  calc(15rem/16);
        position: absolute;
        right: calc(20rem/16);
        top: 50%;
        transform: translateY(-50%);
    }
}

.product-grid__sort-select{
    display: inline-block;
    z-index: 2;
    position: relative;
    cursor: pointer;
    margin-left: calc(30rem/16);
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(5rem/16);
    }
}

.product-grid__sort-select .form-control{
    padding: calc(11rem/16) calc(30rem/16);
    height: auto;
    width: 16rem;
    color: #000;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: calc(23rem/16);
    border: 1px solid #191914;
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    font-family: var(--font-title-bold);
    appearance: none;
    transition: all 200ms ease;

    @media screen and (max-width: 767px){
        font-size: calc(11rem/16);
        letter-spacing: calc(2.75rem/16);
    }
}

.product-grid__sort-select:hover .form-control {
    background-color: var(--color-default);
    color: #fff;
}

.product-grid__sort-select:hover .form-control  option{
    background-color: #fff;
    color: var(--color-default);
}

.product-grid__sort-select:hover:before{
    color: #fff;
}

.product-grid__sort-select:before{
       content: '\EA04';
        position: absolute;
        right: 1.875rem;
        top: calc(24rem/16);
        font-size: .4375rem;
        color: var(--color-default);
}

.product-grid__sort-select .form-control>option{
    text-transform: none;
    font-size: calc(14rem/16);
    padding: 1rem!important;
    appearance: none;
    font-family: var(--font-default);
}

.product-grid__loading-overlay{
    @media screen and (max-width: 767px){
       position: fixed;
        display: flex;
        align-items: center;
        z-index: 1000;
    }
}

.product-grid-quick-filter{
    @media screen and (max-width: 767px){
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.product-grid-quick-filter .list-inline-item{
    @media screen and (max-width: 767px){
       margin-right: 0;
    }
}


.product-grid-quick-filter__item {
    border-radius: calc(23rem/16);
    border: 1px solid #e3e3e3;
    background-color: #fff;
    padding: calc(4rem/16) calc(15rem/16);
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    font-family: var(--font-default);
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease;
}

.product-grid-quick-filter  .product-grid-quick-filter__item:hover{
    background-color: var(--color-default);
    color: #fff;
}

.product-grid-quick-filter__remove {
    margin-left: calc(4rem/16);
}

.product-grid-quick-filter__remove-btn{
    @media screen and (max-width: 767px){
       margin: calc(15rem/16) 0;
    }
}

.product-grid-quick-filter__remove-btn-text {
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    text-decoration: underline;
    font-family: var(--font-default-bold);
    cursor: pointer;
}
.product-grid__product-amount {
    font-family: var(--font-default);
    font-size: 1rem;
    letter-spacing: 0;
    line-height: calc(26/16);
    //margin-bottom: 2.8rem;
}
.show-all-content__max-height .product-grid__show-all-content {
    max-height: 3rem;
}
.show-all-content__max-height:not(.show) .product-grid__show-all-content:after{
    height: 0;
}
.product-grid__show-all-content__btn {
    text-transform: none;
    text-decoration: underline;
    margin-left: 0;
    padding: 0!important;
    margin-top: 0.5rem;
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(24/13);
}

.product-grid__show-all-content__btn:focus{
    text-decoration: underline;
}

.product-grid__filter-container {

    @media screen and (min-width: 768px){
        margin-top: calc(30rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-top: calc(60rem/16);
    }
}

.product-grid__filter{
    gap: calc(15rem/16);
    @media (max-width: 767px){
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;
        padding: calc(30rem/16) calc(30rem/16) calc(140rem/16);
    }
}


.accordion--quick-filter {
    border-left: none;
    border-right: none;
    margin-bottom: calc(27rem / 16);
    border-bottom: 1px solid #E3E3E3;

    @media screen and (max-width: 767px){
        display: none;
        margin-top: calc(10rem/16);
    }
}

.accordion--quick-filter.show{
    @media screen and (max-width: 767px){
        display: block;
    }
}

.accordion--quick-filter .card {
    border: none;
}

.accordion--quick-filter .card-header {
    background: #fff;
    border-radius: 0;
    border-bottom: none;
    padding: 0;
    border-top: 1px solid #E3E3E3;
}

.accordion--quick-filter .accordion-button {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-default);
    font-size: calc(13rem / 16);
    letter-spacing: 0;
    line-height: calc(26 / 13);
    transition: all 0.3s ease-in-out;
    padding: calc(11rem / 16) 0;

    @media screen and (min-width: 768px){
        display: none;
    }
}

.accordion--quick-filter .accordion-button:focus{
    outline: none;
}

.accordion--quick-filter .accordion-button[aria-expanded="true"] .icon {
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
}

.accordion--quick-filter .accordion-button .icon{
    transition: all 0.3s ease-in-out;
}

.accordion--quick-filter .card-body {
    padding: 0;
}


.collapse--quick-filter{
    @media screen and (min-width: 768px){
       display: block!important;
    }
}


@media screen and (min-width: 768px){
    .accordion--quick-filter {
        border: 0!important;
    }

    .accordion--quick-filter  .card-header{
        border: 0!important;
    }
}
