@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-POI:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-arrow-big:before { content: "\EA03" }
.icon-arrow-down:before { content: "\EA04" }
.icon-arrow-left:before { content: "\EA05" }
.icon-arrow-right-big:before { content: "\EA06" }
.icon-arrow-right:before { content: "\EA07" }
.icon-arrow-thin-right:before { content: "\EA08" }
.icon-arrow-up:before { content: "\EA09" }
.icon-bike:before { content: "\EA0A" }
.icon-bin:before { content: "\EA0B" }
.icon-calendar:before { content: "\EA0C" }
.icon-calender-magazine:before { content: "\EA0D" }
.icon-cart:before { content: "\EA0E" }
.icon-check:before { content: "\EA0F" }
.icon-checkbox-active:before { content: "\EA10" }
.icon-checkbox:before { content: "\EA11" }
.icon-checkmark:before { content: "\EA12" }
.icon-clock:before { content: "\EA13" }
.icon-close:before { content: "\EA14" }
.icon-cross-ski:before { content: "\EA15" }
.icon-delivery:before { content: "\EA16" }
.icon-doc:before { content: "\EA17" }
.icon-double-arrow-left:before { content: "\EA18" }
.icon-double-arrow-right:before { content: "\EA19" }
.icon-elements:before { content: "\EA1A" }
.icon-facebook:before { content: "\EA1B" }
.icon-filter:before { content: "\EA1C" }
.icon-gebirge:before { content: "\EA1D" }
.icon-heart-filled:before { content: "\EA1E" }
.icon-heart:before { content: "\EA1F" }
.icon-hike:before { content: "\EA20" }
.icon-instagram:before { content: "\EA21" }
.icon-jpeg:before { content: "\EA22" }
.icon-jpg:before { content: "\EA23" }
.icon-langlauf:before { content: "\EA24" }
.icon-linkedin:before { content: "\EA25" }
.icon-location:before { content: "\EA26" }
.icon-mail:before { content: "\EA27" }
.icon-martini-m:before { content: "\EA28" }
.icon-meme:before { content: "\EA29" }
.icon-minus:before { content: "\EA2A" }
.icon-mobile-menu-close:before { content: "\EA2B" }
.icon-mobile-menu:before { content: "\EA2C" }
.icon-mobile-phone:before { content: "\EA2D" }
.icon-mouse:before { content: "\EA2E" }
.icon-nav-arrow:before { content: "\EA2F" }
.icon-nordic:before { content: "\EA30" }
.icon-pause:before { content: "\EA31" }
.icon-pdf:before { content: "\EA32" }
.icon-pen:before { content: "\EA33" }
.icon-pinterest:before { content: "\EA34" }
.icon-play:before { content: "\EA35" }
.icon-plus:before { content: "\EA36" }
.icon-poi:before { content: "\EA37" }
.icon-produkt-reparatur:before { content: "\EA38" }
.icon-profile:before { content: "\EA39" }
.icon-recycling:before { content: "\EA3A" }
.icon-ruler:before { content: "\EA3B" }
.icon-running:before { content: "\EA3C" }
.icon-search:before { content: "\EA3D" }
.icon-share:before { content: "\EA3E" }
.icon-ski:before { content: "\EA3F" }
.icon-skitouring:before { content: "\EA40" }
.icon-speed-performance:before { content: "\EA41" }
.icon-summer:before { content: "\EA42" }
.icon-svg:before { content: "\EA43" }
.icon-teamevent:before { content: "\EA44" }
.icon-tipp:before { content: "\EA45" }
.icon-triangle-right:before { content: "\EA46" }
.icon-twitter:before { content: "\EA47" }
.icon-video-play:before { content: "\EA48" }
.icon-walk:before { content: "\EA49" }
.icon-warning:before { content: "\EA4A" }
.icon-whatsapp:before { content: "\EA4B" }
.icon-winter:before { content: "\EA4C" }
.icon-youtube:before { content: "\EA4D" }


:root {
--icon-POI: "\EA01";
    --icon-addthis: "\EA02";
    --icon-arrow-big: "\EA03";
    --icon-arrow-down: "\EA04";
    --icon-arrow-left: "\EA05";
    --icon-arrow-right-big: "\EA06";
    --icon-arrow-right: "\EA07";
    --icon-arrow-thin-right: "\EA08";
    --icon-arrow-up: "\EA09";
    --icon-bike: "\EA0A";
    --icon-bin: "\EA0B";
    --icon-calendar: "\EA0C";
    --icon-calender-magazine: "\EA0D";
    --icon-cart: "\EA0E";
    --icon-check: "\EA0F";
    --icon-checkbox-active: "\EA10";
    --icon-checkbox: "\EA11";
    --icon-checkmark: "\EA12";
    --icon-clock: "\EA13";
    --icon-close: "\EA14";
    --icon-cross-ski: "\EA15";
    --icon-delivery: "\EA16";
    --icon-doc: "\EA17";
    --icon-double-arrow-left: "\EA18";
    --icon-double-arrow-right: "\EA19";
    --icon-elements: "\EA1A";
    --icon-facebook: "\EA1B";
    --icon-filter: "\EA1C";
    --icon-gebirge: "\EA1D";
    --icon-heart-filled: "\EA1E";
    --icon-heart: "\EA1F";
    --icon-hike: "\EA20";
    --icon-instagram: "\EA21";
    --icon-jpeg: "\EA22";
    --icon-jpg: "\EA23";
    --icon-langlauf: "\EA24";
    --icon-linkedin: "\EA25";
    --icon-location: "\EA26";
    --icon-mail: "\EA27";
    --icon-martini-m: "\EA28";
    --icon-meme: "\EA29";
    --icon-minus: "\EA2A";
    --icon-mobile-menu-close: "\EA2B";
    --icon-mobile-menu: "\EA2C";
    --icon-mobile-phone: "\EA2D";
    --icon-mouse: "\EA2E";
    --icon-nav-arrow: "\EA2F";
    --icon-nordic: "\EA30";
    --icon-pause: "\EA31";
    --icon-pdf: "\EA32";
    --icon-pen: "\EA33";
    --icon-pinterest: "\EA34";
    --icon-play: "\EA35";
    --icon-plus: "\EA36";
    --icon-poi: "\EA37";
    --icon-produkt-reparatur: "\EA38";
    --icon-profile: "\EA39";
    --icon-recycling: "\EA3A";
    --icon-ruler: "\EA3B";
    --icon-running: "\EA3C";
    --icon-search: "\EA3D";
    --icon-share: "\EA3E";
    --icon-ski: "\EA3F";
    --icon-skitouring: "\EA40";
    --icon-speed-performance: "\EA41";
    --icon-summer: "\EA42";
    --icon-svg: "\EA43";
    --icon-teamevent: "\EA44";
    --icon-tipp: "\EA45";
    --icon-triangle-right: "\EA46";
    --icon-twitter: "\EA47";
    --icon-video-play: "\EA48";
    --icon-walk: "\EA49";
    --icon-warning: "\EA4A";
    --icon-whatsapp: "\EA4B";
    --icon-winter: "\EA4C";
    --icon-youtube: "\EA4D";
    
}