.magazine-anchor-nav__container{
    border-bottom: 2px solid var(--color-default);
    padding: calc(24rem/16) 0 0;
    @media (min-width: 768px) {
        padding: calc(24rem/16) 0 calc(28rem/16);
        display: flex;
        justify-content: space-between;
        gap: calc(16rem/16);
    }
}
.magazine-anchor-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1rem;
}
.magazine-anchor-nav__header{
    display: flex;
    align-items: center;
    margin-right: auto;
}
.magazine-anchor-nav__item {
    margin-right: calc(37rem/16);
    margin-bottom: calc(15rem/16);
    font-size: calc(11rem/16);
    line-height: calc(26/11);
    font-family: var(--font-title-bold);
    text-decoration: underline;
    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}
.magazine-anchor-nav__item:last-of-type {
    margin-right: 0;
}

.magazine-anchor-nav__header-title {
    font-family: var(--font-title-bold);
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    text-transform: uppercase;
    margin-right: 0.5rem;
    white-space: nowrap;
}