.btn.btn-number-spinner{
    padding: 0;
    width: calc(22rem/16);
    height: calc(22rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(9rem/16);
    background-color: var(--color-light-grey);
    transition: all 250ms ease;
    letter-spacing: 0;
    color: var(--color-default);
}
.btn.btn-number-spinner:not(:disabled):hover{
    background-color: var(--color-default);
    color: #fff;
}
.btn.btn-number-spinner:disabled{
    cursor: default;
}

.number-spinner .form-control,
.number-spinner .form-control[readonly]{
    height: calc(22rem/16);
    padding: calc(5rem/16);
    border: none;
    width: calc(25rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(10rem/16);
    text-align: center;
    background-color: #fff;
}

/* Chrome, Safari, Edge, Opera */
.number-spinner input::-webkit-outer-spin-button,
.number-spinner input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-spinner input[type=number] {
    -moz-appearance: textfield;
}