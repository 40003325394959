.order-table__head{
    background-color: #f5f5f5;
    padding: calc(10rem/16) calc(10rem/16);
    font-size: calc(18rem/16);
    line-height: calc(16/18);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(30rem/16);
    }
}
.order-table__head strong{
    font-size: calc(15rem/16);
}


.delivery-state--open{
    color: #F5A623;
}

.delivery-state--complete{
    color: var(--color-success);
}

.delivery-state--cancelled, .delivery-state--refunded{
    color: var(--color-danger);
}

.order-table__head__price{
    font-size: calc(18rem/16);
}

.order-table__table{
    width: 100%;
    
    @media screen and (max-width: 767px){
       display: block;
    }
}

.order-table__table tr{
    border-bottom: 1px solid #f5f5f5;

    @media screen and (max-width: 767px) {
        display: inline-block
    }
}

.order-table__table td{
    padding: calc(6rem/16) calc(10rem/16);

    @media screen and (min-width: 768px){
        padding: calc(30rem/16);
    }
    
    @media screen and (max-width: 767px){
       display: block;
    }
}
@media screen and (max-width: 767px){
    .order-table__table tr>td:first-child{
        padding-top: calc(15rem/16);
    }
    .order-table__table tr>td:last-child{
        padding-bottom: calc(15rem/16);
    }
}

.order-table__table__td-title{
    @media screen and (min-width: 768px){
       width: 30%;
    }

    @media screen and (min-width: 992px){
        width: 25%;
    }
}

.order-table__table__td-amount{
    @media screen and (min-width: 768px){
        width: 15%;
    }
}

.order-table__table__td-link{
    @media screen and (min-width: 768px){
        width: auto;
    }
}
.order-table__table__td-price{
    @media screen and (min-width: 768px){
        width: auto;
    }
}
