.header-sujet-container{
    margin-top: calc(90rem/16);
    padding-bottom: calc(27rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(105rem/16);
        padding-bottom: calc(95rem/16);
    }

    @media screen and (min-width: 1601px) {
        margin-top: calc(120rem/16);
        padding-bottom: calc(95rem/16);
    }
}

.header-sujet-left {
    position: relative;
    color: #fff;
    width: 100%;

    @media screen and (min-width: 768px){
        width: 72.9vw;
    }
}

.header-sujet-right{
    position: relative;
    width: 0%;

    @media screen and (min-width: 768px){
        width: 28.5vw;
        height: auto;
    }
}


.test{
@media screen and (min-width: 768px){
    gap: 7px;
    }
}

/*evtl Shadow*/
/*.header-sujet:before{*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    z-index: 1;*/
/*    background: linear-gradient(0deg, rgba(26,40,51,0.7) 0%, rgba(26,40,51,0) 100%);*/
/*    @media screen and (min-width: 768px){*/
/*        background: linear-gradient(0deg, rgba(26,40,51,0.7) 0%, rgba(26,40,51,0) 100%), linear-gradient(90deg, rgba(26,40,51,0.7) 0%, rgba(26,40,51,0) 100%);*/
/*    }*/
/*}*/


.header-sujet__body{
    position: absolute;
    width: 100%;
    z-index: 2;

    @media screen and (min-width: 768px){
        bottom: calc(80rem/16);
    }

    @media screen and (min-width: 1400px){
        bottom: calc(205rem/16);
    }

    @media screen and (max-width: 767px){
        bottom: calc(39rem/16);
        padding: 0 calc(30rem/16);
    }
}

.header-sujet__body--edit{
    bottom: 0;
    top: 0;
    width: calc(300rem/16);
}

.header-sujet__text{
    width: calc(300rem/16);

    @media screen and (min-width: 768px) {
        width: 80%;
        padding: 0 calc(18rem/16);
    }

    @media screen and (min-width: 1781px){
        width: 80%;
        margin-left: 5%;
    }
}
.header-sujet__text--right{
    margin-left: 70%
}

.header-sujet__text-dark{
    color: var(--color-primary);
}

.header-sujet__title{
    margin-bottom: calc(12rem/16);
    font-family: var(--font-title-default);

    @media screen and (min-width: 768px){
        margin-bottom: calc(15rem/16)
    }

    @media screen and (min-width: 768px) and (max-width: 1300px){
        font-size: calc(30rem/16)
    }
}

.header-sujet__toptitle{
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    letter-spacing: calc(0.5rem/16);
    text-transform: uppercase;
    align-content: start;
    display: inline-block;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        line-height: calc(26/18);
        letter-spacing: calc(1rem/16);
        margin-bottom: calc(4rem/16);
    }
}

.header-sujet .btn-inline-list{
    margin-top: 0;

    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}

.header-sujet .btn-inline-list .btn{
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-sujet__body .list-inline-item{
    margin-top: calc(10rem/16);
}

.header-sujet__discover{
    width: calc(152rem/16);
    height: calc(152rem/16);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: calc(14rem/16);
    left: 50%;
    transform: translateY(50%) translateX(-50%);
    z-index: 2;

    @media screen and (min-width: 768px){
        width: calc(230rem/16);
        height: calc(230rem/16);
        bottom: calc(30rem/16);
    }
}

.header-sujet__discover:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.35);
    border-radius: 50%;
    animation: pulse 3s infinite;
    animation-timing-function: ease-in-out;
    z-index: -1;
}

.header-sujet__discover__body{
    width: calc(106rem/16);
    height: calc(106rem/16);
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;

    @media screen and (min-width: 768px){
        width: calc(160rem/16);
        height: calc(160rem/16);
    }
}

.header-sujet__discover__text{
    font-size: calc(10rem/16);
    text-transform: uppercase;
    margin-top: calc(7rem/16);
    letter-spacing: calc(1.11rem/16);

    @media screen and (max-width: 767px){
       margin-top: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-80%);
        font-size: calc(8rem/16);
        letter-spacing: calc(1.6rem/16);
        line-height: calc(15/10);
    }
}

.scroll-down{
    display: inline-block;
    margin-top: calc(22rem/16);
    animation: scrollDown 3s infinite;
    animation-timing-function: ease-out;

    @media screen and (max-width: 767px){
       display: none;
    }
}

.scroll-down__mouse {
    position: relative;
    border: calc(2rem/16) solid #fff;
    border-radius: calc(10rem/16);
    height: calc(30rem/16);
    width: calc(20rem/16);
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    animation: mouseFade 2s infinite;
}


.scroll-down__wheel {
    background: #fff;
    position: absolute;
    left: 50%;
    width: calc(2rem/16);
    height: calc(6rem/16);
    border-radius: calc(2rem/16);
    margin-left: calc(-1rem/16);
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    transform: translateY(5px);
    animation: wheelScroll 3s infinite;
}


@keyframes scrollDown {
    0% {
        transform: translateY(0); }
    75% {
        transform: translateY(5px); }
    100% {
        transform: translateY(0); } }



@keyframes mouseFade {
    0% {
        opacity: 1; }
    50% {
        opacity: .75; }
    100% {
        opacity: 1; }
}


@keyframes wheelScroll {
    0% {
        transform: translateY(5px);
        opacity: 1; }
    75% {
        transform: translateY(15px);

        opacity: 0; }
    80% {
        transform: translateY(5px);

        opacity: 0; }
    100% {
        opacity: 1; } }

@keyframes pulse {
    0% {
        transform: scale(0.4);
        opacity: 0;
    }
    60% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}