.navbar-parent {
    @media screen and (min-width: 768px){
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1001;
    }
}

.navbar-top {
    height: calc(30rem / 16);
    font-family: var(--font-default);
    font-size: calc(8rem / 16);
    line-height: calc(12 / 8);
    letter-spacing: calc(1rem / 16);
    text-transform: uppercase;
    background-color: var(--color-dark);
    color: var(--color-white);
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 0 calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        padding: 0 calc(41rem / 16);
    }

    @media screen and (min-width: 768px) {
        padding: 0 calc(20rem/16);
        height: calc(40rem / 16);
        font-size: calc(12rem / 16);
        line-height: calc(26 / 12);
    }
}

.navbar-top:after{
    @media screen and (max-width: 767px){
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: calc(64rem/16);
        background: linear-gradient(90deg, rgba(30,30,30,0) 0%, #1E1E1E 100%);
    }
}

.navbar-top>ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @media screen and (max-width: 767px){
        white-space: nowrap;
        overflow-x: auto;
    }
}

.navbar-top>ul::-webkit-scrollbar {
    display: none;
}

.navbar-top .list-inline-item:not(:last-child){
    margin-right: calc(26rem/16);
}

.navbar-top .list-inline-item:last-child{
    @media screen and (max-width: 767px){
        padding-right: calc(30rem/16);
    }
}

.navbar__country-select {
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.navbar__country-select-text {
    font-size: calc(8rem/16);
    line-height: calc(12/8);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        line-height: calc(26/12);
    }
}

.navbar-wrapper{
    display: flex;
    width: 100%;

    @media screen and (min-width: 768px){
        justify-content: space-between;
    }
}

.navbar-main{
    height: calc(60rem/16);
    background-color: var(--color-white);
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    transition: all 150ms ease;
    box-shadow: 0 0 40px 0 rgba(0,0,0,0.1);

    @media screen and (min-width: 768px){
        height: calc(80rem/16);
        padding: 0 calc(15rem/16) 0 calc(30rem/16);
    }

    @media screen and (min-width: 1200px){
        padding: 0 calc(30rem/16);
    }
    
    @media screen and (min-width: 768px) and (max-height: 700px){
        height: calc(65rem/16);
    }
}

.has-navbar-top .navbar-main{
    @media screen and (max-width: 767px){
        background-color: var(--color-white);
        top: calc(30rem/16);
    }
}

.navbar-parent .navbar-main{
    @media screen and (min-width: 768px){
        top: calc(40rem/16);
        padding-right: 0;
        padding-left: 0;
    }
}

.navbar-parent .navbar-main.is-sticky{
    top: 0;
}

.navbar-main .navbar-collapse{
    @media screen and (min-width: 768px){
        flex-grow: 0;
    }
}

.navbar-main__item-hover:before {
    content: '';
    display: block;
    width: 100%;
    background-color: var(--color-white);
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(7rem/16);
    transition: all .25s ease-in-out;
    transform: scaleY(0);
    transform-origin: bottom;
}

.navbar-main .navbar-nav{
    @media screen and (max-width: 767px){
        width: 100%;
        padding: 0 calc(40rem/16);
    }
}

.navbar-main .navbar-nav>li:hover .navbar-main__item-hover:before,
.navbar-main .navbar-nav>li.active .navbar-main__item-hover:before {
    @media screen and (min-width: 768px) {
        transform: scaleY(1);
    }
}

.navbar-main .navbar-nav.is-open>li:not(.is-open){
    display: none;
}

.navbar-main .navbar-nav .nav-link{
    font-family: var(--font-default);
    color: #A5A5A5;
    font-size: calc(18rem/16);
    line-height: calc(26/18);
    padding: calc(18rem/16) calc(30rem/16);
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        color: var(--color-white);
    }

    @media screen and (min-width: 768px){
        color: var(--color-dark);
        font-size: calc(14rem/16);
        background-color: var(--color-white);
        padding: calc(27rem/16) calc(8rem/16);
        height: calc(80rem/16);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
    }

    @media screen and (min-width: 1100px){
        font-size: calc(16rem/16);
        padding: calc(27rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 1400px){
        padding: calc(27rem/16) calc(39rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
       height: calc(64rem/16);
        padding: calc(25rem/16) calc(22rem/16);
    }
}

.navbar-main .navbar-nav a.nav-link{
    cursor: pointer;
}

.navbar-main .navbar-nav .nav-item:hover .nav-link,
.navbar-main .navbar-nav .nav-item.active .nav-link{
    @media screen and (max-width: 767px){
        color: var(--color-white);
    }
}

.navbar-main .navbar-nav .nav-link:before{
    @media screen and (max-width: 767px){
        content: var(--icon-arrow-right);
        font-family: iconfont;
        position: absolute;
        left: 0;
        top: calc(25rem/16);
        font-size: calc(8rem/16);
        transition: transform 250ms ease;
    }

    @media screen and (min-width: 768px){
        content: '';
        height: calc(5rem/16);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
        background-color: var(--color-default);
        transition: transform 250ms ease;
    }
}

.navbar-main .navbar-nav .nav-item.is-open .nav-link:before,
.navbar-main .navbar-nav .nav-item.active .nav-link:before{
    @media screen and (max-width: 767px){
        transform: rotate(180deg);
    }

    @media screen and (min-width: 768px){
        transform: translateY(0);
    }
}

.navbar-brand{
    margin-left: calc(10rem/16);
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-left: calc(41rem/16);
    }
}

.navbar-brand>img{
    width: calc(120rem/16);

    @media screen and (min-width: 1200px){
        width: calc(180rem/16);
    }

    @media screen and (min-width: 768px){
        width: calc(110rem/16);
    }
}

.navbar-toggler{
    padding: 0;
    width: calc(60rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    background-color: var(--color-dark);
    border-radius: 0;
}

.navbar-toggler__bar {
    display: block;
    width: calc(16rem/16);
    height: calc(2rem/16);
    background-color: var(--color-white);
    transition: transform .25s ease-in-out,opacity .25s ease-in-out;
    border-radius: calc(2rem/16);
    margin: 0 auto;
}

@media screen and (max-width: 767px){
    .navbar-collapse {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(60rem/16);
        background-color: var(--color-dark);
        color: var(--color-white);
        transform: translateX(100%);
        transition: transform .25s ease;
        padding-top: calc(30rem/16);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 20;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .has-navbar-top .navbar-collapse{
        top: calc(90rem/16);
    }

    .is-sticky .navbar-collapse,
    .has-navbar-top .is-sticky .navbar-collapse {
        top: calc(60rem/16);
    }

    .nav-open .navbar-collapse{
        transform: translateX(0);
    }

    .navbar-toggler__bar+.navbar-toggler__bar {
        margin-top: calc(4rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__bar:first-child {
        transform: rotate(-45deg) translateX(-3px) translateY(4px);
        width: calc(16rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(-5px) translateY(-6px);
        width: calc(16rem/16);
    }

    .navbar-collapse__country-select{
        font-family: var(--font-default);
        color: var(--color-white);
        font-size: calc(14rem/16);
        line-height: calc(20/14);
        border: none;
        margin-top: calc(10rem/16);
    }

    .navbar-collapse__country-select .icon{
        font-size: calc(15rem/16);
        vertical-align: calc(-2rem/16);
        margin-right: calc(7rem/16);
    }
}

/* navbar right */
.navbar-right{
    @media screen and (min-width: 768px){
        margin-right: calc(20rem/16);
    }
    @media screen and (min-width: 1200px){
        margin-right: calc(42rem/16);
    }
    @media screen and (max-width: 767px){
        display: flex;
        margin-left: auto;
    }
}

.navbar-right>ul{
    list-style: none;
    padding: 0;
    display: flex;
}

.navbar-right>ul>li{
    position: relative;
}

.navbar-right button{
    cursor: pointer;
}

.navbar-right__item{
    width: calc(42rem /16);
    height: calc(60rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(19rem/16);

    @media screen and (min-width: 768px){
        width: calc(45rem/16);
        height: calc(80rem/16);
        font-size: calc(20rem/16);
    }

    @media screen and (min-width: 1200px){
        width: calc(60rem/16);
        font-size: calc(22rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
        height: calc(65rem/16);
    }

}

.navbar-right__dropdown{
    position: absolute;
    right: -15px;
    background-color: var(--color-dark);
    color: var(--color-white);
    padding: calc(20rem/16) 0;
    min-width: calc(210rem/16);
    display: none;
    z-index: 21;
}

.navbar-right__dropdown:after{
    content: '';
    width: 0;
    height: 0;
    border-left: calc(10rem/16) solid transparent;
    border-right: calc(10rem/16) solid transparent;
    border-bottom: calc(10rem/16) solid var(--color-dark);
    position: absolute;
    top: calc(-10rem/16);
    right: calc(30rem/16);
}

.navbar-right__dropdown ul>li:not(:last-child){
    margin-bottom: calc(5rem/16);
}

.navbar-right>ul>li:hover .navbar-right__dropdown{
    display: block;
}

.dropdown--cart-body{
    padding: 10px 48px 20px;
}

.dropdown--cart-body__title{
    font-size: calc(14rem/16);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(12rem/16);
}

.dropdown--cart-body__text{
    max-width: calc(150rem/16);
    margin: 0 auto;
    line-height: 1.25;
}

.dropdown--cart__item{
    font-size: calc(16rem/16);
    padding: calc(5rem/16);
}

.dropdown--cart__item:hover {
    background-color: #313131;
}

.dropdown--cart__item__title{
    text-transform: uppercase;
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
}

.dropdown--cart__item__price{
    font-size: calc(15rem/16);
    font-family: var(--font-default-bold);
}

.navbar-right__cart,
.navbar-right__wishlist{
    position: relative;
    line-height: 1;
}

.navbar-right__cart__count,
.navbar-right__wishlist__count {
    font-size: calc(10rem/16);
    background: var(--color-primary);
    color: var(--color-white);
    width: calc(16rem/16);
    height: calc(16rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default-bold);
    position: absolute;
    bottom: calc(-4rem/16);
    right: calc(-6rem/16);
}

/* Subnav */
.navbar-subnav{
    background-color: var(--color-dark);
    color: var(--color-white);

    @media screen and (min-width: 768px){
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
    }

    @media screen and (max-width: 767px){
        padding-top: calc(8rem/16);
        display: none;
    }
}

.navbar-nav>li.is-open .navbar-subnav{
    display: block;
}

.navbar-subnav__inner{
    padding-bottom: calc(30rem/16);
    @media (max-width: 767px ) {
        overflow-x: hidden;
        height: 60vh;
        overflow-y: auto;
    }
    @media screen and (min-width: 768px){
        padding: calc(58rem/16) calc(20rem/16);
        max-width: calc(1600rem/16);
        margin: 0 auto;
    }

    @media screen and (min-width: 768px) and (max-height: 900px){
       padding: calc(20rem/16);
        padding-bottom: 0;
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
        padding: calc(5rem/16);
    }
}

.navbar-subnav__block+.navbar-subnav__block{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(38rem/16);
    }
}

.navbar-subnav__block ul>li>a{
    font-size: calc(14rem/16);
    color: var(--color-white);
    line-height: calc(30/14);
    margin-bottom: calc(20rem/16);
    display: inline-block;
    position: relative;

    @media screen and (min-width: 768px){
        color: #A5A5A5;
        margin-bottom: calc(8rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 900px){
        margin-bottom: calc(2rem/16);
        font-size: calc(11rem/16);
        line-height: 1.5;
    }

    @media screen and (min-width: 1200px) and (max-height: 700px){
        font-size: calc(13rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
        font-size: calc(11rem/16);
        margin-bottom: 0;

    }
}

.navbar-subnav__block ul>li.active>a{
    text-decoration: underline;
}

.navbar-subnav__block ul>li>a:after {
    color: var(--color-white);
    content: var(--icon-arrow-right);
    font-family: iconfont;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: calc(5rem/16);
    font-size: calc(10rem/16);
    transform: translateX(0);

    @media screen and (min-width: 768px) and (max-width: 1600px){
        top: calc(3rem/16);

    }

    @media screen and (min-width: 1600px) {
        top: calc(4rem/16);
        font-size: calc(10rem/16);
    }
}

.navbar-subnav__block ul>li>a:hover:after {
    opacity: 1;
    transform: translateX(17px);
}

.navbar-subnav__title{
    font-family: var(--font-title-bold);
    color: var(--color-white);
    font-size: calc(16rem/16);
    line-height: calc(36/16);
    display: inline-block;

    @media screen and (min-width: 768px){
        margin-bottom: calc(16rem/16);
        transition: color 0.2s ease;
    }

    @media screen and (max-width: 767px){
        margin-left: calc(25rem/16);
        padding-left: calc(20rem/16);
        position: relative;
    }

    @media screen and (min-width: 768px) and (max-height: 900px){
        margin-bottom: calc(5rem/16);
    }

    @media screen and (min-width: 1200px) and (max-height: 700px){
        font-size: calc(13rem/16);
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
        margin-bottom: calc(5rem/16);
        font-size: calc(10rem/16);
    }
}


.navbar-subnav__title:before{
    @media screen and (max-width: 767px){
        content: var(--icon-arrow-right);
        font-family: iconfont;
        position: absolute;
        left: 0;
        top: calc(8rem/16);
        font-size: calc(10rem/16);
    }
}

.navbar-subnav__title--all{
    @media screen and (max-width: 768px){
        padding-left: 0;
    }
}

.navbar-subnav__title--all:before{
    @media screen and (max-width: 767px){
        content: " ";
    }
}

.navbar-subnav__block.is-open .navbar-subnav__title{
    @media screen and (max-width: 767px){
        color: var(--color-white);
    }
}

.navbar-subnav__block.is-open .navbar-subnav__title:before{
    @media screen and (max-width: 767px){
        transform: scaleX(-1);
    }
}

.navbar-subnav__all-link{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    color: #A5A5A5;
    font-size: calc(14rem/16);
    margin-top: calc(7rem/16);
    display: inline-block;
}
.navbar__all-link{
    text-transform: none;
    font-family: var(--font-title-bold);
    color: var(--color-white);
    font-size: calc(16rem/16);
    display: inline-block;
}

.navbar-subnav__container{
    @media screen and (min-width: 768px){
        max-width: calc(1330rem/16);
        width: 100%;
        float: right;
        padding-left: calc(40rem/16);
    }

    @media screen and (min-width: 1550px){
        margin-right: calc(160rem/16);
        padding-left: 0;
    }
}

.navbar-subnav__back{
    @media screen and (max-width: 767px) {
        color: var(--color-white);
        font-size: calc(18rem/16);
        letter-spacing: calc(1.3rem/16);
        margin-bottom: calc(10rem/16);
        width: 100%;
        text-align: left;
        padding: calc(10rem/16) 0;
    }
}

.navbar-subnav__back .icon{
    @media screen and (max-width: 767px){
        font-size: calc(13rem/16);
        margin-right: calc(15rem/16);
    }
}

.navbar-subnav__filter-tag {
    margin: calc(5rem/16) calc(5rem/16);
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(20/14);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        padding: calc(15rem/16) calc(40rem/16);
        background-color: transparent;
        border: 1px solid var(--color-white);
        transition: all 250ms ease;
        margin: 0 calc(5rem/16);
        margin-bottom: calc(15rem/16);
    }
}

.navbar-subnav__filter-tag.active,
.navbar-subnav__filter-tag:hover {
    @media screen and (min-width: 768px) {
        background-color: var(--color-white);
        color: var(--color-text-default);
        cursor: pointer;
    }
}

.navbar-collapse__bottom {
    @media screen and (max-width: 767px){
        margin-bottom: calc(46rem/16);
    }
}

.nav-first-level-open .navbar-collapse__bottom{
    display: none;
}

.navbar-collapse__bottom__link{
    @media screen and (max-width: 767px){
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: calc(1.26rem/16);
    }
}

.navbar-collapse__bottom__link .icon{
    @media screen and (max-width: 767px){
        font-size: calc(30rem/16);
        color: var(--color-dark-grey);
        margin-right: calc(10rem/16);
    }
}

.navbar-collapse__lang{
    font-size: calc(16rem/16);
    line-height: calc(16/16);

    @media screen and (max-width: 767px){
        margin-top: calc(55rem/16);
    }
}

.navbar-right__dropdown__item,
.btn.navbar-right__dropdown__item {
    font-family: var(--font-default);
    color: var(--color-white);
    font-size: calc(14rem/16);
    line-height: calc(20/14);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(5rem/16) calc(15rem/16);
    text-transform: uppercase;
    white-space: nowrap;
}

.navbar-right__dropdown__item:hover{
    background-color: #313131;
}

.navbar-right__dropdown__item.disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.navbar-right__dropdown__item.disabled>span{
    opacity: 0.5;
}

.navbar-search{
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 500;
    background-color: #000;
    padding: calc(20rem/16) calc(15rem/16);
    transform: scaleY(0);
    transition: all 300ms ease;
    transform-origin: top;
    opacity: 0;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16);
        z-index: -1;
    }
}

.navbar-search.is-open{
    transform: scaleY(1);
    opacity: 1;
}

.navbar-search__form{
    max-width: calc(780rem/16);
    width: 100%;
    margin: 0 auto;
}

.navbar-search__form .form-control{
    border: none;
    font-size: calc(14rem/16);
}

.navbar-search-group{
    position: relative;
}

.navbar-search-group__btn{
    background: var(--color-white);
    height: 100%;
    border: none;
    padding: calc(10rem/16) calc(25rem/16);
    font-size: calc(20rem/16);
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--color-default);
}

/*set season color*/
.navbar-subnav__bottom{
    background-color: var(--color-season-7);
    color: var(--color-default);
    padding: calc(25rem/16) calc(20rem/16);
    text-align: center;

    @media screen and (max-width: 767px){
        width: 100%;
        padding: calc(20rem/16);
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media screen and (min-width: 768px) and (max-height: 700px){
       padding: calc(10rem/16);
    }
}

.navbar-subnav__bottom .btn{
    @media screen and (min-width: 768px) and (max-height: 700px){
        padding: calc(11rem/16) calc(15rem/16);
    }
}

.nav-first-level-open .navbar-subnav__bottom{
    @media screen and (max-width: 767px){
        display: block;
    }
}

.navbar-subnav__third-level{
    @media screen and (max-width: 767px){
        display: none;
        margin-left: calc(45rem/16);
        margin-top: calc(20rem/16);
    }
}

.navbar-subnav__block.is-open .navbar-subnav__third-level{
    @media screen and (max-width: 767px){
        display: block;
    }
}

.navbar-subnav__third-level li.active a{
    color: var(--color-white);
    text-decoration: underline;
}

.navbar-subnav.is-open .navbar-subnav__block:not(.is-open) {
    display: none;
}

.navbar-subnav__block{
    margin-bottom: calc(18rem/16);

    @media screen and (min-width: 768px) and (max-height: 700px){
       margin-bottom: calc(5rem/16);
    }
}
.navbar-checkout{
    top: 0!important;
}