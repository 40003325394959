.author-item {
    max-width: calc(400rem/16);
}
.author-item__contents {
    padding-top: calc(30rem/16);
}
.author-item__img{
    position: relative;
    margin: 0 auto;

    @media screen and (max-width: 767px){
        width: calc(236rem/16);
    }
}

.author-item__img img{
    position: relative;
    z-index: 2;

    @media screen and (max-width: 1199px) {
        width: calc(200rem/16);
        height: calc(200rem/16);
    }
}

.author-item__img:before{
    content: '';
    position: absolute;
    width: calc(290rem/16);
    height: calc(290rem/16);
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    opacity: 0.6;

    @media screen and (max-width: 1199px) {
        width: calc(240rem/16);
        height: calc(240rem/16);
    }
}

.author-item__body {
    margin-top: calc(47rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(25rem/16);
    }
}
.author-item__name {
    font-family: var(--font-special);
    font-size: calc(40rem/16);
    line-height: calc(40/40);
}

.author-item__link {
    max-width: calc(300rem/16);
}
.author-item__link .btn-dynamic-arrow__size-fill {
    transform: translateX(0);
    transition: all 0.2s ease;
}
.author-item:hover .btn-dynamic-arrow__size-fill {
    @media screen and (min-width: 768px) {
        transform: translateX(10px);
    }
}