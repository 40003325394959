.product-img-slider{

    @media screen and (min-width: 768px){
         display: flex;
        align-items: center;
    }
   /* padding-left: calc(120rem/16);*/
    position: relative;
}



.product-img-slider-main{
    overflow: hidden;

    @media screen and (min-width: 768px){
        opacity: 0;
        transition: all 350ms ease;

    }
}

.product-img-slider-main.visible{
    opacity: 1;
}


.product-img-slider__item{
    position: relative;
    max-width: calc(646rem/16);
    height: auto;
    
    @media screen and (max-width: 767px){
       min-height: calc(335rem/16);
    }
}

.product-img-slider__item a{
    display: block;
}


.product-img-slider__item .btn-lightbox{
    position: absolute;
    left: calc(80rem/16);
    bottom: calc(30rem/16);
    width: calc(50rem/16);
    height: calc(50rem/16);
    border-radius: 50%;
    color: #fff;
    background-color: var(--color-dark-grey);
    font-size: calc(25rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    @media screen and (min-width: 768px){
        bottom: calc(175rem/16);
        width: calc(70rem/16);
        height: calc(70rem/16);
        font-size: calc(36rem/16);
    }
}

.product-img-slider-nav{

    opacity: 0;
    transition: all 350ms ease;

    @media screen and (min-width: 768px){
      /*  width: 401px;*/
        max-height: 400px;
        overflow: hidden;
        padding: 40px 0;
        bottom: calc(40rem/16);

    }
    @media screen and (min-width: 1400px){
        bottom: calc(70rem/16);
        /*width: 549px;*/
        max-height: 450px;
        min-width: calc(90rem/16);
    }
    
    @media screen and (max-width: 767px){
        margin: 0 calc(12rem/16) 0 calc(20rem/16);
        height: calc(72rem/16);
    }
}

.product-img-slider-nav.visible{
    opacity: 1;
}



.product-img-slider-nav__item{
    width: calc(65rem/16)!important; /* overwrite slick slider inline style */
    height: calc(65rem/16);
    cursor: pointer;
    position: relative;
    opacity: 0.5;
    transition: all 150ms ease;

    @media screen and (min-width: 1400px){
        width: calc(90rem/16)!important; /* overwrite slick slider inline style */
        height: calc(90rem/16);
    }
}

.product-img-slider-nav__item .btn-play{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.product-img-slider-nav .slick-list{
    height: auto!important;  /* overwrite slick slider inline style */
}

.slick-current .product-img-slider-nav__item,
.product-img-slider-nav__item:hover{
    opacity: 1;
}

.product-img-slider-nav .slick-arrow{
    position: absolute;
    z-index: 2;

    @media screen and (min-width: 768px){
        left: 50%;
        transform: translateX(-50%);
    }

    @media screen and (max-width: 767px){
        top: calc(15rem/16);
    }

}

.product-img-slider-nav .slider__arrow{

    padding: calc(5rem/16);
    height: calc(40rem/16);
    width: calc(30rem/16);
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        height: calc(30rem/16);
        width: calc(100% - 2px);
        font-size: calc(18rem/16);
    }
}

.product-img-slider-nav .slider__arrow.slick-disabled{
    display: none!important;
}

.product-img-slider-nav .slider__arrow.slick-prev{
    @media screen and (min-width: 768px){
        top: calc(10rem/16);
    }

    @media screen and (max-width: 767px){
        left: calc(-34rem/16);
    }
}

.product-img-slider-nav .slider__arrow.slick-next{
    @media screen and (min-width: 768px){
        bottom: 0;
    }
    
    @media screen and (max-width: 767px){
       right: calc(-24rem/16);
    }
}

.product-img-slider-nav .slider__arrow__icon {
    @media screen and (min-width: 768px){
        transform: translateX(-50%) translateY(-50%) rotate(90deg);

    }
}
