.hero-two-col{
    background-color: var(--color-dark);
    color: #fff;
}

.hero-two-col__body{
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: calc(20rem/16) calc(20rem/16) calc(40rem/16);

    @media screen and (min-width: 1400px){
        padding: calc(35rem/16) calc(115rem/16);
    }

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(50rem/16);
    }
}

.hero-two-col__body:after{
    content: var(--icon-martini-m);
    font-family: iconfont;
    position: absolute;
    left: calc(-142rem/16);
    bottom: calc(-112rem/16);
    color: var(--color-default);
    z-index: 1;
    line-height: 1;
    font-size: calc(450rem/16);

    @media screen and (min-width: 768px){
        left: calc(-240rem/16);
        bottom: calc(-145rem/16);
        font-size: calc(730rem/16);
    }
}

.hero-two-col__content{
    z-index: 2;
    position: relative;

    @media screen and (min-width: 1400px){
        width: calc(100% - 230px);
    }

    @media screen and (min-width: 768px){
        width: calc(100% - 100px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.hero-two-col__toptitle{
    font-size: calc(10rem/16);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
    
    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-top: calc(30rem/16);
    }
}

.hero-two-col__title{
    font-size: calc(22rem/16);
    line-height: calc(60/40);
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 1200px){
       font-size: clamp(26px, 2.5vw, 40px);
        line-height: calc(60/40);
    }

    @media screen and (min-width: 992px){
        font-size: calc(23rem/16);

    }
    
    @media screen and (min-width: 768px){
       font-size: calc(20rem/16);
        line-height: calc(55/40);
        letter-spacing: calc(6rem/16);

    }
}

.hero-two-col .wysiwyg{
    font-size: calc(14rem/16);
    line-height: calc(26/14);

    @media screen and (min-width: 768px){
        font-size: clamp(11px, 1vw, 14px);
        line-height: calc(22/14);
    }

    @media screen and (min-width: 1200px){
        line-height: calc(26/14);

    }
}

.hero-two-col .breadcrumb{
    padding: 0;
    position: relative;
    z-index: 2;
}

.hero-two-col .container.container{
    padding: 0;
}

.hero-two-col .breadcrumb .breadcrumb-item+.breadcrumb-item:before{
    background: #fff;
}

.hero-two-col .breadcrumb .breadcrumb-item.active{
    color: #fff;
}