.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.content-block + .pimcore_area_content > .content-block{
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16);
    }

    @media screen and (min-width: 1200px){
        @media screen and (min-width: 768px) {
            margin-top: calc(100rem/16);
        }
    }
}
.content-block + .content-block.content-block--margin-bottom,
.content-block > .content-block.content-block--margin-bottom,
.pimcore_area_content + .pimcore_area_content > .content-block.content-block--margin-bottom,
.content-block + .pimcore_area_content > .content-block.content-block--margin-bottom{
    margin-bottom: calc(80rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(140rem/16);
    }

    @media screen and (min-width: 1200px){
        margin-bottom: calc(200rem/16);
    }
}
