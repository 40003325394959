/*@media (max-width: 1200px) and (min-width: 768px){
    .magazine-grid{
        display: grid;
        grid-template-columns: 3fr 3fr 3fr;
        gap: calc(37rem/16);
        grid-template-areas:
        "hl hl . "
        ". . . "
        ". . . "
        ". hr hr";
        margin-top: calc(41rem/16);
    }

    .magazine-grid__highlight--left {
        grid-area: hl;
    }
    .magazine-grid__highlight--right {
        grid-area: hr;
    }
}
@media (min-width: 768px){
 .magazine-grid{
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        gap: calc(37rem/16);
        grid-template-areas:
        "hl hl . ."
        ". . . ."
        ". . hr hr";
        margin-top: calc(41rem/16);
    }

    .magazine-grid__highlight--left {
        grid-area: hl;
    }
    .magazine-grid__highlight--right {
        grid-area: hr;
    }

}*/

.magazine-grid{
   /* display: grid;
    grid-template-columns: 1fr;
    gap: calc(20rem/16);*/
    margin-top: calc(41rem/16);
}
.magazine-form{
    margin-top: calc(16rem/16);
    @media (min-width: 768px){
        margin-top: calc(50rem/16);
    }
}
.magazine-header-backdrop,
.magazine-header-backdrop--small{
    position: relative;
}
.magazine-header-backdrop:before {
    content: " ";
    background-color: var(--color-light-grey);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(calc(316rem/16) + calc(80rem/16));
    z-index: -1;
    @media (min-width: 768px){
        height: calc(calc(640rem/16) + calc(80rem/16));
    }
}
.magazine-header-backdrop--small:before {
    content: " ";
    background-color: var(--color-light-grey);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(calc(370rem/16) + calc(80rem/16));
    z-index: -1;
}
.magazine-header-backdrop .filter-dropdown__btn,
.magazine-header-backdrop .product-grid__sort-select .form-control{
    background-color: transparent;
}

.magazine-form__filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: calc(20rem/16);
}
.magazine-form__form {
    display: flex;
    @media (max-width: 767px){
        flex-direction: column;
    }
}
.magazine-form__quickfilter .product-grid-quick-filter__item{
    @media (min-width: 768px) {
        background-color: transparent;
    }
}
.magazine-form__quickfilter .product-grid-quick-filter__item > *{
    pointer-events: none;
}
.magazine-form__filter{
    gap: calc(15rem/16);
    @media (min-width: 768px) {
        display: flex;
    }
    @media (max-width: 767px){
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100vh;
        padding: calc(30rem/16) calc(30rem/16) calc(140rem/16);
    }
}
.magazine-form .card {
    background-color: transparent;
}
.magazine-accordion--quick-filter .card-header{
    background-color: transparent;
}