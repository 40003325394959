.product-colors__list{
    overflow-x: auto;
    display: inline-block;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    position: relative;

    @media screen and (min-width: 1300px){
        width: calc(100% - 55px);
    }

    @media screen and (min-width: 768px){
        width: calc(100% - 45px);
    }

    @media screen and (max-width: 767px){
        width: calc(100% + 40px);
        margin: 0 calc(-20rem/16);
    }
}
.product-detail__colors .product-colors__list{
    @media screen and (min-width: 1600px){
        width: calc(100% - 5px);
    }

    @media screen and (min-width: 768px){
        width: calc(100% - 55px);
    }
}

.product-colors__list::-webkit-scrollbar{
    display: none;
}

.product-colors__list .product-colors__item{
    display: inline-flex;
}

.product-colors__list .product-colors__item:last-child{
    margin-right: calc(5rem/16);
}

.product-color__input,
.product-size__input{
    display: none;
}

.product-color__radio>label{
    padding: calc(1rem/16);
    cursor: pointer;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(60rem/16);
    height: calc(60rem/16);
    background-color: var(--color-light-grey);

    @media screen and (max-width: 768px) {
        width: calc(50rem/16);
        height: calc(50rem/16);
    }
}

.product-color__input:checked ~ label,
.product-color__radio>label:hover{
    border-color: var(--color-dark);
}
.product-detail-sticky-bar .product-color__input:checked ~ label{
    border: none;
}

.product-color__name {
    text-align: center;
    font-size: 0.875rem;
    color: var(--color-dark-grey);
    word-break: break-word;
    max-width: calc(70rem/16);
    @media screen and (min-width: 768px){
        max-width: calc(70rem/16);
    }
}

.product-color__input:checked ~ .product-color__name {
    color: var(--color-dark);
}

.product-color__radio {
    text-align: center;
}

.product-color__radio img{
    pointer-events: none;
}