.seo-tabs__title {
    font-size: calc(25rem/16);
    text-align: center;
    line-height: calc(36/25);
    font-family: var(--font-title-bold);
    margin-bottom: calc(37rem/16);
}
.seo-tabs__subtitle {
    font-size: calc(18rem/16);
    line-height: calc(26/18);
    font-family: var(--font-title-bold);
    margin-bottom: calc(11rem/16);
    text-transform: none;
}

.seo-tabs__show-all-content__btn {
    text-transform: none;
    text-decoration: underline;
    margin-left: 0;
    padding: 0!important;
    margin-top: calc(20rem/16);
    font-size: calc(13rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(24/13);
    color: #0a0a0a;

    &:focus{
        text-decoration: underline;
    }
}
.seo-tabs__show-all-content__content{
    overflow: hidden;
    position: relative;
}
.show-all-content__max-height .show-all-content__content.seo-tabs__show-all-content__content{
    //max-height: calc(160rem/16);

    &::after{
        height: 0!important;
    }
}
.seo-tabs__nav-tabs{
    @media(max-width: 767px){
        flex-wrap: nowrap;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: auto;
        width: 100vw;
        padding-right: 2rem;
        .nav-link{
            overflow: unset;
            margin: 0 calc(27rem/16) 1rem 0;
        }
    }

}