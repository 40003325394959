.product-facts{
    padding: calc(50rem/16) 0;
}

.product-facts__icon{
    width: calc(58rem/16);
    height: calc(58rem/16);
    border-radius: 50%;
    background-color: #000;
    color: #fff;
    font-size: calc(30rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(15rem/16);
}

.product-facts__title{
    font-family: var(--font-title-bold);
    font-size: calc(18rem/16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem/16);
        line-height: calc(18/13);
    }
  /*  margin-bottom: calc(3rem/16);
    position: absolute;
    bottom: 0;
    transform-origin: left;
    left: calc(19rem/16);
    transform: rotate(-90deg);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        left: calc(38rem/16);
    }*/

}
.progress .progress-bar {
    width: 0;
    transition: width 1.2s ease-in-out;
}
.product-facts__list>li{
    margin-bottom: calc(5rem/16);
}

.show-all-content__btn{
    display: none;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    margin: 0 auto;
    color: var(--color-text-default);
}

.show-all-content__btn .icon {
    font-size: 0.5rem;
    margin-left: 0.25rem;
    color: var(--color-text-default);
}

.show-all-content__content{
    overflow: hidden;
    position: relative;
}

.show-all-content__max-height .show-all-content__content{
    max-height: calc(210rem/16);
}

.show-all-content__max-height .show-all-content__btn{
    display: block;
}

.show-all-content__max-height.show .show-all-content__content{
    max-height: 100%;
}

.show-all-content__btn__less{
    display: none;
}

.show-all-content__max-height.show .show-all-content__btn__less{
    display: block;
}

.show-all-content__max-height.show .show-all-content__btn__more{
    display: none;
}


.show-all-content__max-height:not(.show) .show-all-content__content:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(60rem/16);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}


.progress-bar-vertical {
    width: calc(20rem/16);
    min-height: calc(220rem/16);
    display: flex;
    align-items: flex-end;
    margin: 0 auto;
    position: relative;
}

.progress-bar-vertical .progress-bar {
    width: 100%!important;
    height: 0;
    transition: height 1.2s ease;
}

.product-facts__progress--vertical{
    @media screen and (min-width: 768px){
       max-width: calc(108rem/16);
    }
}

.product-facts__progress--vertical .product-facts__title{
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}