
.filter-dropdown__menu.dropdown-menu{
    @media screen and (max-width: 767px){
       position: relative;
        padding-top: 0;
        margin-top: -4px;
    }
}

.filter-dropdown__menu.show {
    @media screen and (min-width: 768px){
        border: 1px solid #191914;
        border-radius: calc(10rem/16);
    }
    border: none;
    width: calc(101% + calc(60rem/16));
    padding: calc(30rem/16);
    margin-top: calc(9rem/16);
    margin-left:  calc( -30rem/16);

}

.filter-dropdown__list{
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        max-height: calc(250rem/16);
        overflow-y: auto;

    }
   @media screen and (max-height: 850px){
        max-height: calc(200rem / 16);
    }

    @media screen and (max-height: 820px){
        max-height: calc(150rem / 16);
    }

    @media screen and (max-width: 767px){
        display: flex;
        flex-direction: column;
        gap: calc(4rem/16) 0;
        max-height: none!important;
    }
}

.filter-dropdown__item {
    color: #191914;
    border: 1px solid #E3E3E3;
    border-radius: 23px;
    margin-bottom: 0.3125rem;
    padding: 9px 20px;
    font-size: calc(12rem/16);
    line-height: calc(20/12);
    font-family: var(--font-default);
    width: 100%;
    display: block;
}

.filter-dropdown__btn{
    cursor: pointer;
    transition: all 200ms ease;
    color: var(--color-text-default) ;
        @media screen and (min-width: 768px){
        border: 1px solid #191914;
        border-radius: calc(29rem/16);
        font-family: var(--font-title-bold);
        font-size: calc(12rem/16);
        line-height: calc(20/12);
        padding: calc(11rem/16) calc(30rem/16);
        text-transform: uppercase;
        background-color: #fff;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 767px){
        font-size: 1rem;
        line-height: calc(26/16);
        text-transform: none;
        letter-spacing: 0;
        font-family: var(--font-default);
        padding: calc(23rem/16) calc(15rem/16);
        display: flex;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #000;
        cursor: pointer;
        transition: all .25s ease;
    }

}

.filter-dropdown__btn:focus{
    @media screen and (max-width: 767px){
        outline: none;
        border: none;
    }
}

.filter-dropdown__btn:hover{
    @media screen and (min-width: 768px){
        background-color: var(--color-default);
        color: #fff;
    }
}

.filter-dropdown__btn .icon{
    font-size:calc(7rem/16);
    font-weight: bold;
    margin-left: calc(20rem/16);
}

.filter-dropdown.show .icon{
    @media screen and (min-width: 768px){
        transform: rotate(180deg)
    }
}

.filter-dropdown__submit-btn {
    border-radius: calc(23rem/16);
    background: #000;
    color: #fff;
    text-transform: none;
    padding: 11px 0;
    width: 100%;
    text-align: center;
    font-size: calc(12rem/16);
    font-family: var(--font-default);
    line-height: calc(21/12);
    margin-top: calc(30rem/16);
    border: none;
    letter-spacing: 1px;
    cursor: pointer;
}
.dropdown-color-circle {
    border-radius: 50%;
    width: calc(26rem/16);
    height: calc(26rem/16);
    display: block;
    background-color: #000;
    @media (min-width: 768px) {
        margin-right: calc(10rem/16);
        width: calc(30rem/16);
        height: calc(30rem/16);
    }
}
.dropdown-color-circle.dropdown-color-circle--multicolored{
    border-right-color: var(--background-color-right);
    border-top-color: var(--background-color-top);
    border-bottom-color: var(--background-color-bottom);
    border-left-color: var(--background-color-left);
    border-width: 1rem;
    border-style: solid;
    transform: rotate(45deg);
}
.dropdown-color-circle.dropdown-color-circle--outline{
    outline: 1px solid #E3E3E3;
}
.filter-dropdown__item--colorFilter{
    display: flex;
    align-items: center;
    padding: calc(5rem/16) calc(20rem/16) calc(5rem/16) calc(5rem/16);
}
.filter-dropdown__input.is-checked + .filter-dropdown__item,
.filter-dropdown__input:checked + .filter-dropdown__item {
    /*background-color: #000;*/
    color: #000;
    border: 2px solid #000;

}
.filter-dropdown__input:checked:hover + .filter-dropdown__item {
   color: #000;
}

.filter-dropdown__input{
    position: absolute;
    inset: 0 0 0 0;
    opacity: 0;
    cursor: pointer;
}
.dropdown__item label:hover .filter-dropdown__item {
    border-color: #000;
}
.dropdown__item label:hover + .filter-dropdown__input:checked .filter-dropdown__item {
    border-color: #fff!important;
}
.filter-dropdown__counter {
    margin-left: 0.3rem;
    width: 1.5rem;
}
.filter-dropdown__counter--opacity{
    opacity: 0;
}
@media screen and (max-width: 767px) {
    .filter-box{
        padding: 0 calc(20rem/16);
        overflow-y: auto;
        height: calc(100vh - 300px);
    }
    .filter-box__bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: calc(10rem/16) calc(30rem/16) calc(20rem/16);
        background-color: #fff;
        z-index: 1000;
    }
    .filter-box__submit-btn{
        width: 100%;
    }
    .filter-dropdown__group{
        border-top: 1px solid var(--color-grey);
    }

    .filter-dropdown  .icon{
        transition: all 0.25s ease;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
        font-size: 12px;
    }

    .filter-dropdown.show .icon:before{
        display: inline-block;
        transform: rotate(45deg);
        transition: all 0.25s ease;
    }

    .product-grid__form-title--mobile {
        color: #191914;
        font-family: var(--font-title-bold);
        font-size: calc(25rem/16);
        line-height: calc(32/25);
        padding: calc(30rem/16);
    }

    .filter-box__color-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
        gap: 0 5px;
    }

    .filter-box__color-list .dropdown__item {
        width: calc(33.333% - 5px);
    }
}
.dropdown__item label.is-checked:hover .filter-dropdown__item {
    color: #ffffff!important;
}

.filter-dropdown__menu--season .filter-dropdown__list {
    @media(max-width: 767px)  {
        display: flex;
        flex-wrap: wrap;
        gap: calc(4rem/16) calc(5rem/16);
        flex-direction: row;
        .dropdown__item{
            flex-grow: 1;
        }
    }
}
.filter-dropdown__mobile-toggle{
    border: none;
}

.filter-dropdown__mobile-toggle.is-affix{
    z-index: 100;
    width: 100vw!important;
    border-radius: 0;
    padding-left: 0;
    left: 0!important;
}