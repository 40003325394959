.popup-teaser__title {
    font-size: calc(14rem/16);
    margin-bottom: 0;
}

.popup-teaser__img {
    object-fit: cover;
}

.popup-teaser__header {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    padding: calc(14rem/16) calc(24rem/16);
    display: flex;
    flex-direction: column;
}

.popup-teaser__link {
    text-decoration: underline;
    font-size: calc(12rem/16);
    font-family: var(--font-title-bold);
    text-transform: uppercase;
    color: inherit;
}

.popup-teaser__text {
    margin-top: calc(6rem/16);
    margin-bottom: calc(6rem/16);
}

.img-split-teaser--anchor-teaser .popup-teaser__text {
    width: 100%;
}

.popup-teaser .text-bottom {
    justify-content: end;
}

.popup-teaser.text-below .popup-teaser__header {
    display: none;
}

.popup-teaser.text-picture .popup-teaser__content {
    display: none;
}

@media (min-width: 768px) {
    .popup-teaser__title {
        font-size: calc(18rem/16);
    }

    .popup-teaser__header {
        padding: calc(32rem/16);
    }

}

@media (min-width: 1200px) {
    .popup-teaser__text {
        width: 75%;
        margin-top: 0;
    }

    .popup-teaser__title {
        font-size: calc(25rem/16);
    }
}