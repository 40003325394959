body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(13rem / 16);
    line-height: calc(26 / 14);

    @media screen and (min-width: 1200px) {
        font-size: var(--font-size-default);
    }

}


b,
strong,
.strong,
.font-bold{
    font-family: var(--font-default-bold)!important;
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    font-family: var(--font-title-bold);
    text-transform: uppercase;
}

h1, .h1 {
    font-size: calc(30rem/16);
    line-height: calc(35/30);
    font-family: var(--font-title-default);
}
h2, .h2 {
    font-size: calc(25rem/16);
    line-height: calc(32/25);
    font-family: var(--font-title-default);
}
h3, .h3 {
    font-size: calc(18rem/16);
    line-height: calc(24/18);
}
h4, .h4 {
    font-size: calc(16rem/16);
    line-height: calc(23/16);
}
h5, .h5 {
    font-size: calc(14rem/16);
}
h6, .h6 {
    font-size: 1em;
}

@media screen and (min-width: 768px){
    h1, .h1 {
        font-size: calc(50rem/16);
        line-height: calc(60/50);
    }
    h2, .h2 {
        font-size: calc(40rem/16);
        line-height: calc(50/40);
    }
    h3, .h3 {
        font-size: calc(25rem/16);
        line-height: calc(36/25);
    }
}