.payment-box{
    padding: 1rem;
    border: 1px solid var(--color-grey);
}
.payment-method {
    margin-top: 0.625rem;
    border: 1px solid var(--color-grey);
    /*padding: 1rem 0.75rem;*/
    /*@media screen and (min-width: 768px){*/
    /*    padding: calc(17rem/16) calc(64rem/16);*/
    /*}*/
}
.payment-method.payment-method__disabled{
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

.payment-method__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.payment-method label{
    cursor: pointer;
    /*padding: calc(15rem/16) 0;*/
}

.payment-radio:before,
.payment-radio:after {
    content: " ";
    display: table;
}.payment-radio:after {
     clear: both;
 }
.payment-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.payment-radio__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: calc(1rem/16) solid var(--color-primary);
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: calc(6rem/16);
    border-radius: 50%;
}
.payment-radio__box:before {
    content: var(--icon-check);
    font-family: iconfont;
    position: absolute;
    font-size: 7px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    visibility: hidden;
}
.payment-radio__input:checked ~ .payment-radio__box:before {
    visibility: visible;
}
.payment-radio__input:checked ~ .payment-radio__box,
.payment-radio__input:focus ~ .payment-radio__box {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
}
.has-error .payment-radio__box {
    border-color: var(--color-danger)
}
.payment-radio__input:checked ~ .payment-radio__text{
    font-family: var(--font-default-bold);
}
.payment-radio__text {
    display: block;
    overflow: hidden;
    font-family: var(--font-default);
    font-size: 1rem;
}
.payment-radio {
    /* padding: 1.0625rem 4rem; */
    width: 100%;
}
.payment-method__label {
    width: 100%;
    padding: 2.0625rem 4.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.75rem;
    @media screen and (min-width: 768px){
        padding: calc(17rem/16) calc(20rem/16);
    }
}
.payment-method__label--creditcard{
    @media screen and (max-width: 768px) {
        display: inherit;
        position: relative;

    }
}

.payment-radio__card-symbol{
    @media screen and (max-width: 768px) {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}
