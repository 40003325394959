.accordion--technologies .btn-accordion {
    padding-bottom: calc(10rem / 16);
    position: relative;
    overflow: hidden;
    height: calc(52rem/16);
    transform: translateZ(0);
}


.accordion--technologies .btn-accordion:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: red;
    background: linear-gradient(29.22deg,#43505a,#e81e39);
    transform: translateY(100%);
    transition: all 250ms ease;
}

.accordion--technologies .btn-accordion.collapsed:after{
    transform: translateY(100%);

}
.accordion--technologies .card-body {
    border: none;
}

.accordion--technologies .btn-accordion:after,
.accordion--technologies .btn-accordion:hover:after{
    transform: translateY(0);
}

/*default accordion */
.accordion--default .accordion__item {
    border: none;
    background-color: var(--color-white);
    transition: background-color 0.2s ease;
}

    .accordion--default .accordion__item:hover {
        background-color: var(--color-white);
    }
    .accordion--default .accordion__item:not(:last-child) {
        margin-bottom: calc(5rem/16);
    }

    .accordion--default .accordion__header-title {
        font-size: calc(16rem/16);
        font-family: var(--font-title-default);
    }
    .accordion--default .accordion__header {
        padding: 0;
        background-color: transparent;
    }
    .accordion--default .accordion__header-link.collapsed {
        background-color: transparent;
    }
    .accordion--default .accordion__header-link {
        padding: calc(15rem/16);
        background-color: var(--color-white);
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .accordion--default .accordion__header-link:focus {
        outline: none;
    }
    .accordion--default .accordion__body {
        @media (min-width: 768px) {
            padding: calc(16rem/16) calc(75rem/16) calc(16rem/16) calc(15rem/16);
        }
        background-color: transparent;
    }
    .accordion--default .show .accordion__body {
        background-color: var(--color-white);
    }
    .accordion--default .accordion__header-icon {
        position: relative;
        height: calc(40rem/16);
        width: calc(40rem/16);
        border-radius: 50%;
        background: var(--color-white);

        @media screen and (max-width: 767px) {
            height: calc(32rem/16);
            width: calc(32rem/16);
        }
    }

    .accordion--default .accordion__header-icon:before {
        content: var(--icon-minus);
        font-family: iconfont;
        font-size: calc(18rem/16);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        line-height: 1;
    }
    .accordion--default .collapsed .accordion__header-icon:before {
        content: var(--icon-plus);
    }
    /*Quickfilter Accordion*/
    .accordion--quick-filter .card-header button,
    .accordion--quick-filter .card-body button{
        color: #0a0a0a;
    }

    .accordion__item--border{
        border-bottom: 1px solid var(--color-grey)!important;
    }
    .accordion__item--border:first-child {
        border-top: 1px solid var(--color-grey)!important;
    }
    .accordion__header-link--no-hover:hover {
        cursor: text;
    }

    /* accordion--primary */

    .accordion--primary .card-header{
        padding: 0;
    }

    .accordion--primary .accordion__title{
        padding: calc(20rem/16) calc(15rem/16);
        font-size: calc(15rem/16);
        font-family: var(--font-default);
        text-transform: none;
        width: 100%;
        text-align: left;
        letter-spacing: 0;
        color: var(--color-default);
        border-radius: 0;

        @media screen and (min-width: 768px){
            padding: calc(25rem/16) calc(15rem/16);
            font-size: calc(16rem/16);
        }
    }

    .accordion--primary .card{
        border: 0;
        border-top: 1px solid var(--color-grey);
    }
    .accordion--primary .card:last-of-type{
        border-bottom: 1px solid var(--color-grey);
    }

    .accordion--primary .card-header{
        background-color: var(--color-white);
        border: 0;
    }

    .accordion--primary .accordion__icon{
        position: absolute;
        right: calc(15rem/16);
        top: calc(27rem/16);
        width: calc(16rem/16);
        height: calc(16rem/16);
        font-size: calc(12rem/16);
    }

    .accordion--primary .accordion__icon:before{
        content: var(--icon-plus);
        transform: rotate(45deg);
        font-family: iconfont;
        position: absolute;
    }

    .accordion--primary .collapsed .accordion__icon:before{
        content: var(--icon-plus);
        transform: rotate(0);
    }

    .accordion--primary .card-body{
        padding: 0 calc(15rem/16) calc(30rem/16);
    }

    .accordion__body-title{
        font-size: calc(14rem/16);
        font-family: var(--font-default-bold);
    }

.accordion__body-title--question{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(16rem/16);
    text-transform: none;
    @media (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}

.accordion-area .content-heading__left-text {
    width: auto;
}

/*accordion object btn*/

.accordion__body .wysiwyg a.btn-outline-default {
    text-decoration: none;
}

/*safari mobile blue color fix*/
.accordion .accordion__header-link.btn-no-styling {
    color: var(--color-default);
}