.hotspot-container{
    position: relative;

    @media screen and (max-width: 768px) {
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }
}

.hotspot{
    position: absolute;
    width: calc(40rem / 16);
    height: calc(40rem / 16);
}

.hotspot__btn:before{
    content: var(--icon-plus);
    font-family: iconfont;
    font-size: calc(15rem/16);

    @media screen and (max-width: 1200px) {
        font-size: calc(9rem/16);
        padding: calc(13rem/16);
    }
}

.hotspot__btn{
    border-radius: 50%;
    background: var(--color-light-grey);
    opacity: .4;
    color: var(--color-primary);
    height: calc(40rem/16);
    width: calc(40rem/16);
    outline: 1px solid var(--color-light-grey);
    outline-offset: 6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media screen and (max-width: 1200px) {
        height: calc(35rem/16);
        width: calc(35rem/16);
        outline-offset: 3px;
    }
}

.hotspot__btn:hover {
    outline: none;
    cursor: pointer;
    opacity: 1;
}

.hotspot__btn:focus{
    outline: 1px solid var(--color-light-grey);
}

.is-open.hotspot__btn{
    transform: rotate(45deg);
    content: var(--icon-plus);
    font-family: iconfont;
    opacity: 1;
}

.hotspot__detail{
    @media screen and (max-width: 768px) {
        position: relative;
        top: -36px;
    }
}
