
.download-teaser{
    position: relative;

    @media screen and (max-width: 767px){
        margin: 0 calc(-20rem/16);
    }
}
.download-teaser{
    margin-bottom: calc(85rem/16);

    @media screen and (max-width: 767px){
        margin-bottom: calc(30rem/16);
    }
}
.download-teaser__icon {
    background-color: var(--color-light-grey);
    font-size: calc(70rem/16);

    @media screen and (max-width: 1200px) {
        font-size: calc(50rem/16);
    }
}
.download-teaser__icon .icon {
    background-color: var(--color-white);
    border-radius: 50%;
    width: calc(130rem/16);
    height: calc(130rem/16);

    @media screen and (max-width: 1200px) {
        height: calc(90rem/16);
        width: calc(90rem/16);
        margin-bottom: calc(40rem/16);
    }
}
.download-teaser__icon .icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.download-teaser__body{
    position: absolute;
    bottom: calc(-44rem/16);
    left: calc(38rem/16);
    right: calc(38rem/16);
    box-shadow: 0 50px 40px 0 rgba(0,0,0,0.15);

    @media screen and (min-width: 768px){
        bottom: calc(-55rem/16);
        left: calc(20rem/16);
        right: calc(20rem/16);
    }

    @media screen and (min-width: 992px){
        left: 13%;
        right: 13%;
    }
}
.download-teaser__body:hover .btn.btn-active-gradient,
.download-teaser__body:hover .btn.btn-theme-gradient{
    background-position: right center;
}

.download-teaser__text{
    background-color: #fff;
    padding: calc(18rem/16) calc(25rem/16);

    @media screen and (min-width: 768px){
        padding: calc(24rem/16) calc(30rem/16);
    }

}

.download-teaser__toptitle{
    font-size: calc(12rem/16);
    text-transform: uppercase;
}

.download-teaser__title{
    font-size: calc(15rem/16);
    margin-bottom: 0;
    margin-bottom: calc(3rem/16);
}

.download-teaser .btn-md{
    @media screen and (max-width: 767px){
        padding: calc(10rem/16) calc(25rem/16);
        font-size: calc(13rem/16);
        line-height: calc(21/13);
    }
}