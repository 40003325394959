.radio-custom-select__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.radio-custom-select__label{
    width: 100%;
    cursor: pointer;
    font-size: calc(12rem/16);
    line-height: calc(16/14);

    @media screen and (min-width: 1550px){
        font-size: calc(14rem/16);
    }
}

.radio-custom-select__inner{
    padding: calc(10rem/16);
}

.radio-custom-select:not(.disabled) .radio-custom-select__label:hover{
    background-color: var(--color-light-grey);
}

.radio-custom-select.disabled .radio-custom-select__label{
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
}

.radio-custom-select__img{
    margin-right: calc(7rem/16);
}

.radio-custom-select.disabled .radio-custom-select__text-label{
    text-decoration: line-through;
}
