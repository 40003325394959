.video-js{
  width: 100%;
  height: 100%;
}

.content-video video{
  background: #fff;
}

.vjs-default-skin .vjs-big-play-button {
  border-radius: 50%;
  width: calc(50rem/16);
  height:calc(50rem/16);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: transparent;
  color: var(--color-white);
  transition: background-color 0.3s ease, color 0.3s ease;
  border: none;

  @media screen and (min-width: 768px){
    width: calc(110rem/16);
    height:calc(110rem/16);
  }
}

.vjs-big-play-button .vjs-icon-placeholder {
  position: absolute;
  top: 10%;
  left: 0;
  display: block;
  font-size: calc(50rem / 16);

  @media screen and (min-width: 768px){
    top: 25%;
    font-size: calc(110rem / 16);
  }
}

.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
  color: var(--color-default);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.content-video .video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: var(--icon-video-play);
  font-family: iconfont;
}
.video-js .vjs-play-control.vjs-playing .vjs-icon-placeholder:before,
.vjs-icon-play:before {
  content: var(--icon-pause);
  font-family: iconfont;
}

.btn-play{
  width: calc(45rem/16);
  height: calc(45rem/16);
  border-radius: 50%;
  background-color: #fff;
  color: var(--color-default);
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
.content-video{
  position: relative;
}

.content-video__caption{
  position: absolute;
  right: 0;
  top: 0;
  padding: calc(6rem/16) calc(15rem/16);
  font-family: var(--font-default-bold);
  
  @media screen and (max-width: 767px){
     font-size: calc(12rem/16);
    padding: calc(3rem/16) calc(10rem/16);

  }
}

.content-video__caption:before{
  content: var(--icon-pinsel-cut-left);
  font-family: iconfont;
  color: var(--color-primary);
  position: absolute;
  line-height: 1;
  top: 0;
  left: calc(-9rem/16);
  font-size: calc(26rem/16);

  @media screen and (min-width: 768px){
    left: calc(-15rem/16);
    font-size: calc(42rem/16);
  }
}
*/

/*
.lightbox-video{
  position: relative;
}

.lightbox-video .btn-play{
  background-color: #fff;
  color: var(--color-primary);
  width: calc(50rem/16);
  height: calc(50rem/16);
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  box-shadow: 0 0 15px 0 rgba(0,0,0,0.5);
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  transition: all 250ms ease;

  @media screen and (min-width: 768px){
    width: calc(80rem/16);
    height: calc(80rem/16);
  }
}

.lightbox-video:hover .btn-play {
  background: var(--color-primary);
  color: #fff;
  opacity: 0.8;
  border: 2px solid #fff;
}

.lightbox-video .btn-play .icon{
  font-size: calc(18rem/16);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);

  @media screen and (min-width: 768px){
    font-size: calc(20rem/16);
  }
}
.js-lightbox-group__item{
  cursor: pointer;
}
*/

