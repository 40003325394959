.product-size__radio {
    max-width: 10rem;
}
.product-size__radio>label{
    background-color: var(--color-light-grey);
    font-size: calc(9rem/16);
    line-height: calc(11/9);
    letter-spacing: calc(1.8rem/16);
    height: calc(45rem/16);

    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: calc(5rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
        line-height: calc(26/14);
        padding: calc(11rem/16);
    }
}
.product-size__radio-quickadd>label{
    font-size: calc(10rem/16);
    line-height: calc(10/10);
    padding: calc(7rem/16);
    background-color: var(--color-light-grey);
    height: calc(45rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.product-size__radio-quickadd>label span {
    text-align: center;
}

.product-size__row{
    width: 100%;
    margin-bottom: calc(10rem/16);
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px) {
        width: calc(480rem/16);
        margin-bottom: calc(15rem/16);
        margin-top: calc(28rem/16);
    }
}
.product-size__row--small{
    width: 100%;

    @media screen and (min-width: 768px) {
        width: calc(400rem/16);
    }
}

.product-size__radio>label span {
    text-align: center;
}

.product-size__radio>label{
    cursor: pointer;
}

.product-size__radio-label{
    position: relative;
    display: inline-block;
}

.product-size__radio.sold-out .product-size__radio-label:before{
    content: '';
    height: calc(0.5rem/16);
    background-color: var(--color-text-default);
    position: absolute;
    left: calc(-1rem/16);
    right: 0;
    top: 50%;
    width: 100%;
}
.product-size__radio.sold-out > .product-size__input:checked + label > .product-size__radio-label:before{
    background-color: var(--color-text-default);
}


.product-size__radio:not(.sold-out)>label:hover,
.product-size__input:checked ~ label{
    border: var(--color-primary) 1px solid;
}
.product-quick-add__dropdown.show{
    display: flex;
    flex-direction: column;
    min-width: 250px;
    padding-right: calc(5rem/16);
}
.product-quick-add__dropdown-menu {
    display: flex;
    flex-wrap: wrap;
}

.product-quick-add__dropdown-item {
    flex-grow: 1;
    margin-bottom: calc(4rem/16);
    min-width: 3rem;
    margin-right: calc(4rem/16);
}