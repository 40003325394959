.page-heading {
    padding-top: calc(60rem/16);

    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.page-heading__title {
    font-size: calc(44rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}

.page-heading__icon {
    font-size: calc(30rem/16);
    color: var(--color-third);
    line-height: 1.1;
}
.page-heading__icon-text {
    font-family: var(--font-default-bold);
}