.modal--meili-search.show {
    padding-right: 0 !important;
    top: calc(90rem / 16);
    background-color: var(--color-white);
    @media screen and (min-width: 768px) {
        top: calc(120rem / 16);
    }
    &.is-sticky {
        top: calc(60rem / 16);
        @media screen and (min-width: 768px) {
            top: calc(80rem / 16);
        }
    }
}
.navbar-parent .navbar-main.is-sticky{
    .modal--meili-search.show {
        top: 20px;
    }
}
.modal--meili-search .modal-dialog--meili-search{
    width: 100%;
    max-width: none;
    margin: 0;
    height: 100%;
    background-color: var(--color-white);
}

.modal.modal--meili-search .fade & {
    transform: translate(0, 0);
}
.modal--meili-search .modal-content--meili-search {
    height: 100%;
    border: none;
}
.modal-body.modal-body--meili-search {
    padding-top: calc(35rem/16);
    background-color: var(--color-white);
    padding-bottom: calc(65rem/16);
    @media screen and (min-width: 768px) {
        padding-top: calc(43rem/16);
        padding-bottom:calc(101rem/16);
    }
}
.modal-header--meili-search {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    background: var(--color-light-grey);
    @media screen and (min-width: 768px) {
        padding-bottom: calc(50rem / 16);
    }
}
.meili-search-form{
    position: relative;
    @media screen and (min-width: 768px) {
        width: 70%;
    }
}
.meili-search__input {
    border: none;
    background-color: transparent;
    border-bottom: 2px solid;
    text-transform: uppercase;
    color: var(--color-text-default);
    padding-left: 2rem;
    /*@media screen and (max-width: 767px) {*/
    /*    width: 75%;*/
    /*}*/
    @media screen and (min-width: 768px) {
        /*width: 70%;*/
        padding-left: 3rem;
    }
}
.meili-search__input:focus {
    background-color: transparent;
    color: var(--color-text-default);
    border-color: var(--color-text-default);
}
.meili-search__btn {
    appearance: none;
    border: none;
    position: absolute;
    left: 0;
    top: calc(10rem/16);
    background: transparent;
    color: var(--color-text-default);
    cursor: pointer;
    font-size: calc(20rem/16);
    padding-left: 0;
    @media screen and (min-width: 768px) {
        font-size: calc(33rem/16);
        top: 0;
    }
}
.meili-search__reset-btn {
    appearance: none;
    border: none;
    background: transparent;
    position: absolute;
    right: 0;
    top: 1rem;
    cursor: pointer;
    color: var(--color-text-default);
    font-size: calc(15rem / 16);
    padding-right: 0;
    @media screen and (max-width: 767px) {
        /*right: 25%;*/
        top: calc(14rem/16);
    }
}
.form-control.meili-search__input:focus~.animate-border,
.form-control.meili-search__input:focus~.animate-border:after,
.form-control.meili-search__input:focus~.animate-border:before{
    display: none;
}
.meili-search__input,
.meili-search__input::placeholder {
    font-family: var(--font-title-default);
    color: var(--color-text-default);
    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
        line-height: 1;
    }
}
.meili-search__close-btn-text{
    text-transform: uppercase;
    font-family: var(--font-title-default);
    font-size: calc(12rem / 16);
    line-height: calc(20 / 12);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.modal-header--meili-search .close {
    font-weight: bolder;
    padding: calc(23rem/16) calc(34rem/16);
    @media screen and (min-width: 768px) {
        padding: calc(23rem/16) calc(34rem/16);
        display: flex;
        align-items: center;
    }
}
[hidden] {
    display: none !important;
}

/*Search Terms*/
.meili-search__search-term-title {
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
        line-height: calc(24 / 13);
    }
}
.meili-search__search-term-container {
    margin-top: calc(8rem/16);
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        margin-top: calc(9rem/16);
    }
}
.meili-search__search-term {
    font-family: var(--font-default);
    border: 1px solid #191914;
    border-radius: calc(23rem / 16);
    padding: calc(5rem / 16) calc(16rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(24 / 13);
    cursor: pointer;
    transition: all .2s ease;

    @media screen and (min-width: 768px) {
        margin-right: calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-bottom: calc(10rem/16);
    }
}
.meili-search__search-term:hover{
    background-color: var(--color-text-default);
    color: var(--color-white)!important;
    transition: all .2s ease;
}
.meili-search__product-grid-container{
    margin-top: calc(30rem/16);
    @media screen and (min-width: 768px) {
        margin-top: calc(50rem/16);
    }
}
/* TABS */
.meili-search-tabs__nav{
    margin-bottom: calc(17rem/16);
}
.nav-link--search {
    font-family: var(--font-title-default);
    font-size: calc(10rem / 16);
    line-height: calc(15 / 10);
    color: #868E96;
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding: 0;
    margin-right: calc(23rem / 16);
    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
        line-height: calc(20 / 12);
        margin-right: calc(29rem / 16);
    }
}
.nav-link--search.active {
    color: var(--color-default);
    border-bottom: 3px solid var(--color-default);
    padding-bottom: calc(6rem/16);
}

/*.modal-open.overlay-open .modal--meili-search{*/
/*    overflow: hidden;*/
/*}*/
.modal--meili-search .product-grid__filter{
    max-height: 70vh;
   /* padding: calc(30rem/16) calc(40rem/16) calc(40rem/16);*/
}
.modal-open .modal.modal--meili-search.overflow-hidden{
    overflow: hidden;
}

.title--black{
    color: var(--color-text-default);
}

.meili-search-filter-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}