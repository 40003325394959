.hero-img {
    position: relative;
    overflow: hidden;
}

.hero-video{
    width: 100% !important;
    height: auto !important;
}


.scroll-down-container{
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(62%);
    width: calc(120rem/16);
    text-align: center;
    height: calc(120rem/16);
    border-radius: 50%;
}

.hero-img .hero-img__scroll-down{
    z-index: 2;
    background-color: #000;
    width: calc(120rem/16);
    text-align: center;
    height: calc(120rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.hero-img .scroll-down{
    position: relative;
    bottom: 16px;
}

.scroll-down-container:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.35);
    border-radius: 50%;
    animation: pulse 3s infinite;
    animation-timing-function: ease-in-out;
    z-index: -1;
}

@keyframes pulse {
    0% {
        transform: scale(0.4);
        opacity: 0;
    }
    60% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}