.author-intro {
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
}
.author-intro__title {
    font-size: calc(30rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        font-size: calc(24rem/16);
    }
}

.author-intro__date {
    font-size: calc(18rem/16);
    margin-bottom: calc(20rem/16);
}
.author-intro__country {
    text-transform: uppercase;
    font-size: calc(18rem/16);
    margin-bottom: calc(20rem/16);
}

.author-intro__icons {
    font-size: calc(30rem/16);
}
.author-intro__icons .icon {
    color: var(--color-dark-grey);
    transition: color 0.2s ease;
}
.author-intro__icons .icon:hover {
    color: var(--color-default);
}

.author-intro__img{
    position: relative;
    margin: 0 auto;

    @media screen and (max-width: 767px){
        margin-bottom: calc(30rem/16);
    }
}

.author-intro__img img{
    position: relative;
    z-index: 2;

    @media screen and (max-width: 767px) {
        width: calc(260rem/16);
        height: calc(260rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(328rem/16);
        height: calc(328rem/16);
    }
}

.author-intro__img:before{
    content: '';
    position: absolute;
    width: calc(500rem/16);
    height: calc(500rem/16);
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.6;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-24px);

    @media screen and (max-width:767px) {
        width: calc(310rem/16);
        height: calc(310rem/16);
        right: 50%;
        transform: translateY(-50%) translateX(50%);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(378rem/16);
        height: calc(378rem/16);
    }
}