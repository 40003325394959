.nav--content{
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(20/14);
    text-transform: uppercase;
    margin-top: calc(30rem/16);
}

.nav--content .nav-link{
    border: 1px solid var(--color-default);
    padding: calc(10rem/16) calc(25rem/16);
    margin: 0 calc(3rem/16);
    transition: all 250ms ease;

    @media screen and (min-width: 768px){
        margin: 0 calc(5rem/16);
        padding: calc(15rem/16) calc(40rem/16);
    }
}

.nav--content .nav-link.active,
.nav--content .nav-link:hover,
.nav--content a:not([href]):not([tabindex]),
.nav--content a:not([href]):not([tabindex]):focus,
.nav--content a:not([href]):not([tabindex]):hover{
    background-color: #000;
    color: #fff;
}

.nav--content .nav-item{
    @media screen and (max-width: 767px) {
        margin-top: calc(6rem/16);
    }
}


/** nav content links */

.nav--content-links .nav-link{
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(15rem/16);
    position: relative;
}

.nav--content-links .nav-link:before {
    content: '';
    position: absolute;
    height: calc(3rem/16);
    width: calc(40rem/16);
    background: #000;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transform-origin: center;
    transition: all 250ms ease;
    margin-left: calc(7rem/16);
}

.nav--content-links .nav-link:hover:before,
.nav--content-links .nav-link.active:before{
    transform: translateX(-50%) scale(1);
}

.nav--content-links .nav-link .icon{
    font-size: calc(11rem/16);
    margin-right: calc(5rem/16);
}