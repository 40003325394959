
@media(max-width: 767px){
    .content-text-teaser__content {
        padding: calc(50rem/16) calc(30rem/16);
    }
    .content-text-teaser__content.small-padding,
    .content-teaser--lg .content-text-teaser__content.small-padding{
        padding: calc(25rem/16) calc(30rem/16);
    }
    .content-text-teaser__content.small-padding-top,
    .content-teaser--lg .content-text-teaser__content.small-padding-top {
        padding: calc(25rem/16) calc(30rem/16) calc(50rem/16) calc(30rem/16);
    }
    .content-text-teaser__content.small-padding-bottom,
    .content-teaser--lg .content-text-teaser__content.small-padding-bottom {
        padding: calc(50rem/16) calc(30rem/16) calc(25rem/16) calc(30rem/16);
    }
}
/* CONTENT TEXT TEASER */

.content-text-teaser__text {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.content-text-teaser__img {
    object-fit: cover;
}

.content-text-teaser--big .wysiwyg{
    font-size: calc(18rem/16);
    line-height: calc(26/18);
}
.content-text-teaser--big .content-teaser__title {
    font-size: calc(25rem/16);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.content-text-teaser--img {
    background-size: cover;
    background-repeat: no-repeat;
}


@media (min-width: 768px) {
    .content-text-teaser__content {
        padding: calc(67rem/16) calc(30rem/16);
        width: 100%;
        display: flex;
        justify-content: center;
    }

    /*.content-text-teaser__text {*/
    /*    width: 70%;*/
    /*}*/

    .content-text-teaser--big .wysiwyg {
        font-size: calc(30rem/16);
        line-height: calc(40/30);
    }

    .content-text-teaser--big .content-teaser__title {
        font-size: calc(40rem/16);
    }

    .content-text-teaser .wysiwyg__title {
        margin-top: 0;
        display: block;
        font-family: var(--font-title-bold);
        text-transform: uppercase;
        font-size: calc(16rem/16);
        line-height: calc(23/16);
    }

    .content-text-teaser.content-text-teaser--big .wysiwyg__title {
        font-size: calc(28rem/16);
        line-height: calc(23/18);
    }
}

@media (min-width: 1300px) {
    .content-text-teaser__content {
        padding: calc(139rem/16) calc(541rem/16);
    }
    .content-text-teaser__content.small-padding,
    .content-teaser--lg .content-text-teaser__content.small-padding{
        padding: calc(70rem/16) calc(541rem/16);
    }
    .content-text-teaser__content.small-padding-top,
    .content-teaser--lg .content-text-teaser__content.small-padding-top {
        padding: calc(70rem/16) calc(541rem/16) calc(139rem/16) calc(541rem/16);
    }
    .content-text-teaser__content.small-padding-bottom,
    .content-teaser--lg .content-text-teaser__content.small-padding-bottom {
        padding: calc(139rem/16) calc(541rem/16) calc(70rem/16) calc(541rem/16);
    }

}
@media (min-width: 1200px) {
    .content-text-teaser__content {
        padding: calc(130rem/16) calc(400rem/16);
    }
    .content-text-teaser__content.small-padding,
    .content-teaser--lg .content-text-teaser__content.small-padding{
        padding: calc(65rem/16) calc(400rem/16);
    }
    .content-text-teaser__content.small-padding-top,
    .content-teaser--lg .content-text-teaser__content.small-padding-top {
        padding: calc(65rem/16) calc(400rem/16) calc(130rem/16) calc(400rem/16);
    }
    .content-text-teaser__content.small-padding-bottom,
    .content-teaser--lg .content-text-teaser__content.small-padding-bottom {
        padding: calc(130rem/16) calc(400rem/16) calc(65rem/16) calc(400rem/16);
    }
}


/*@media (min-width: 1400px) {*/
/*    .content-text-teaser__text {*/
/*        width: 50%;*/
/*    }*/
/*}*/

/* CONTENT TEXT TEASER SMALL */

.content-teaser__wysiwyg-left {
    margin-top: calc(24rem/16);
    display: flex;
    align-items: baseline;
}

.content-teaser__wysiwyg-center .wysiwyg{
    text-align: center;
}
.content-teaser__wysiwyg-right{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.content-teaser__wysiwyg-right .wysiwyg{
    text-align: right;
}
.content-teaser__wysiwyg-container{
    display: flex;
    flex-direction: column;
}
.content-teaser__wysiwyg-container .content-teaser__wysiwyg-right{
    justify-content: flex-end;
}
.content-teaser__wysiwyg-container .content-teaser__wysiwyg-center{
    justify-content: center;
}
.content-teaser__wysiwyg-center,
.content-teaser__wysiwyg-right {
    margin-top: calc(24rem/16);
}

.content-teaser__wysiwyg-left .icon,
.content-teaser__wysiwyg-center .icon,
.content-teaser__wysiwyg-right .icon {
    margin-right: calc(8rem/16);
}

.content-teaser__wysiwyg-icon {
    font-size: calc(22rem/16);
    position: relative;
    top: calc(3rem/16);
}

.content-teaser__wysiwyg-title {
    font-family: var(--font-title-default);
    font-size: calc(18rem/16);
    margin: unset;
}

.content-text-teaser--big .content-teaser__wysiwyg-left .wysiwyg,
.content-text-teaser--big .content-teaser__wysiwyg-center .wysiwyg,
.content-text-teaser--big .content-teaser__wysiwyg-right .wysiwyg {
    font-size: calc(16rem/16);
}

@media (min-width: 768px) {
    .content-text-teaser--big .content-teaser__wysiwyg-left .wysiwyg,
    .content-text-teaser--big .content-teaser__wysiwyg-center .wysiwyg,
    .content-text-teaser--big .content-teaser__wysiwyg-right .wysiwyg {
        font-size: calc(25rem/16);
    }
}

@media (min-width: 1200px) {
    .content-teaser__wysiwyg-title {
        font-size: calc(22rem / 16);
    }

    .content-teaser__wysiwyg-left {
        margin-top: calc(46rem / 16);
    }
}

/* CONTENT TEXT TEASER BIG */

.content-teaser--lg .content-text-teaser__content {
    padding: calc(68rem / 16) calc(18rem / 16);
}

.content-text-teaser__title {
     font-size: calc(30rem/16);
     line-height: calc(30/30);
     margin-bottom: calc(23rem/16);
}

.content-text-teaser.text-white .content-text-teaser__btn {
    color: var(--color-white);
    border: 1px var(--color-white) solid;
}

.content-text-teaser.text-default .content-text-teaser__btn {
    color: var(--color-default);
    border: 1px var(--color-default) solid;
}

.content-text-teaser__btn {
    position: relative;
    transition: all .3s ease-in-out;
    background: transparent;
    border-radius: 50vh;
    width: fit-content;
}

.text-white .content-text-teaser__btn:hover {
    background: var(--color-white);
    color: var(--color-default);
}

.text-default .content-text-teaser__btn:hover {
    background: var(--color-default);
    color: var(--color-white);
}



@media (min-width: 768px) {
    .content-teaser--lg .content-text-teaser__content {
        padding: calc(67rem/16) calc(120rem/16);
    }
}

@media (min-width: 1200px) {
    .content-teaser--lg .content-text-teaser__content {
        padding: calc(100rem/16) calc(400rem/16) calc(140rem/16);
    }
}

@media (min-width: 1300px) {
    .content-teaser--lg .content-text-teaser__content {
        padding: calc(131rem/16) calc(534rem/16) calc(148rem/16);
    }

    .content-text-teaser__title {
        font-size: calc(50rem/16);
        line-height: calc(60/50);
    }
}