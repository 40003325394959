.share-btn__text--active {
    color: #65EF76;
    display: flex;
    align-items: center;
    padding: 0 calc(12.8rem/16);
}
.btn-default.btn-share{
    font-size: 1rem;
    line-height: calc(26/16);
    display: flex;
    justify-content: center;
}
#shareLink {
    background-color: var(--color-white);
}
.share-modal__title {
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    margin-bottom: calc(40rem / 16);
    font-family: var(--font-title-default);
}
.share-modal__subtitle {
    font-size: calc(10rem/16);
    line-height: calc(15/10);
    font-family: var(--font-title-default);
}
@media screen and (min-width: 768px) {
    .share-modal__title {
        font-size: calc(30rem/16);
        line-height: calc(35/30);
        margin-bottom: calc(50rem / 16);
    }
    .share-modal__subtitle {
        font-size: calc(12rem/16);
        line-height: calc(20/12);
    }
}
@media screen and (max-width: 767px) {
    .btn-share{
        width: 100%;
        margin-top: calc(20rem/16);
    }
    .share-modal .modal-body{
        padding-bottom: calc(40rem/16);
    }
}