.search-text-teaser {
    background-color: var(--color-light-grey);
    padding: calc(24rem/16) calc(30rem/16);
    margin-bottom: calc(20rem/16);
    @media (min-width: 768px) {
        padding: calc(32rem/16) calc(40rem/16);
        width: 70%;
    }
}
.search-text-teaser__title {
    font-family: var(--font-title-default);
    font-size: calc(13rem / 16);
    line-height: calc(18 / 13);
    margin-bottom: calc(8rem / 16);
    color: #999999;

    @media (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(26 / 18);
        margin-bottom: calc(17rem / 16);
    }
}
.search-text-teaser__details-container {
    display: flex;
    margin-bottom: calc(9rem / 16);
    @media (min-width: 768px) {
        margin-bottom: calc(17rem / 16);
    }
}
.search-text-teaser__detail {
    display: flex;
    align-items: center;
}
.search-text-teaser__detail:not(:last-child){
    margin-right: calc(18rem / 16);
}
.search-text-teaser__detail-text {
    font-family: var(--font-default-bold);
    font-size: calc(10rem / 16);
    line-height: calc(12 / 10);
    @media (min-width: 768px) {
        font-size: calc(12rem / 16);
        line-height: calc(15 / 12);
    }
}
.search-text-teaser__detail-text--time{
    white-space: nowrap;
}
.search-text-teaser__detail-icon {
    margin-right: calc(8rem/16);
}
.search-text-teaser__description {
    font-size: calc(12rem / 16);
    line-height: calc(16 / 12);
    margin-bottom: calc(4rem / 16);
    @media (min-width: 768px) {
        font-size: calc(14rem / 16);
        line-height: calc(20 / 14);
        margin-bottom: calc(7rem / 16);
    }
}
.search-text-teaser__link {
    font-size: calc(13rem / 16);
    font-family: var(--font-default-bold);
    text-decoration: underline;
    line-height: calc(24 / 13);
}
.search-text-teaser__type {
    color: #999;
    margin-bottom: calc(4rem / 16);
}