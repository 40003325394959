.color-checkbox{
    position: relative;
}

.color-checkbox label{
    cursor: pointer;
}

.color-checkbox:before,
.color-checkbox:after {
    content: " ";
    display: table;
}.color-checkbox:after {
     clear: both;
 }
.color-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.color-checkbox__circle{
    width: calc(28rem/16);
    height: calc(28rem/16);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 1px solid var(--color-light-grey);
}

.color-checkbox__circle:before{
    content: '';
    position: absolute;
    top: calc(-4rem/16);
    bottom: calc(-4rem/16);
    left: calc(-4rem/16);
    right: calc(-4rem/16);
    border: 1px solid var(--color-grey);
    border-radius: 50%;
    transition: border-color 250ms ease;
}

.color-checkbox__input:checked ~ .color-checkbox__circle:before,
.color-checkbox:hover .color-checkbox__circle:before {
    border-color: var(--color-dark);
}

.color-checkbox__text{
    line-height: 1.2;
    @media screen and (max-width: 767px){
       margin-left: calc(7rem/16);
    }
}