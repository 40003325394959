html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}

body.nav-open,
body.overlay-open{
    overflow: hidden;
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1746rem/16); /* 1590px container breite + 2x36px padding */
    width: 100%;
    padding-right: calc(30.5rem/16);
    padding-left: calc(30.5rem/16);

    @media screen and (min-width: 768px){
        padding-right: calc(18rem/16);
        padding-left: calc(18rem/16);
    }
}

.container .container.container{
    padding: 0;
}

.container-medium{
    max-width: calc(1402rem/16); /* 1330px container breite + 2x36px padding */
    width: 100%;
    padding-right: calc(20rem/16);
    padding-left: calc(20rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        padding-right: calc(18rem/16);
        padding-left: calc(18rem/16);
    }
}

.container-narrow{
    max-width: calc(1082rem/16); /* 1010px container breite + 2x36px padding */
    width: 100%;
    padding-right: calc(20rem/16);
    padding-left: calc(20rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        padding-right: calc(18rem/16);
        padding-left: calc(18rem/16);
    }
}

.container-xsmall{
    max-width: calc(792rem/16); /* 720px container breite + 2x36px padding */
    width: 100%;
    padding-right: calc(20rem/16);
    padding-left: calc(20rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        padding-right: calc(18rem/16);
        padding-left: calc(18rem/16);
    }
}

.container.container-half{
    max-width: calc(715rem/16);
    margin-left: auto;
    margin-right: 0;
}

.container-outer{
    overflow: hidden;
}

@media screen and (min-width: 768px) {
    .container__break-right-half-md {
        width: calc(50vw - calc(36px / 2));
    }

}

.bg-light-grey+.page-wrapper__bottom{
    background-color: var(--color-light-grey);
}

.page-wrapper__bottom{
    padding-top: calc(40rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(70rem/16);
    }
}

.main-content{
    position: relative;
    /* z-index: 96;*/
    padding-top: calc(90rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(115rem/16);
    }
}

.main-content:not(.main-content--no-margin){
    margin-bottom: calc(50rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(100rem/16);
    }
}

.highlight-filter--color{
    color: var(--color-highlight);
}
