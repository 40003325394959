.slick-slide a:not(.product-teaser__tab):focus,
.slick-slide img:not(.product-teaser__tab):focus{
    outline: none;
    border: none;
}

.slider__arrow{
    width: calc(40rem/16);
    height: calc(40rem/16);
    font-size: calc(15rem/16);
    background-color: #000;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    line-height: 1;
    padding: calc(10rem/16) calc(15rem/16);

    @media screen and (min-width: 992px){
        width: calc(80rem/16);
        height: calc(80rem/16);
        font-size: calc(25rem/16);
    }
    @media screen and (min-width: 768px){
       width: calc(60rem/16);
       height: calc(60rem/16);
        font-size: calc(22rem/16);
        padding: calc(20rem/16) calc(32rem/16);
    }
}
.slider__arrow:hover {
    cursor: pointer;
}

.slider__arrow.slider__arrow--small{
    border-radius: 50%;
    border: 1px var(--color-light-grey) solid;
    width: calc(38rem/16);
    height: calc(38rem/16);
    font-size: calc(9rem/16);
    padding: 0;

    @media screen and (min-width: 1200px){
        width: calc(45rem/16);
        height: calc(45rem/16);
        font-size: calc(8rem/16);
    }
}

.slider__arrow.slider__arrow--prev {
    position: absolute;
    top: 50%;
    left: -5%;

    @media screen and (min-width: 768px){
        left: -1%;
    }
}

.slider__arrow.slider__arrow--next {
    position: absolute;
    top: 50%;
    right: 4%;
    @media screen and (min-width: 768px){
        right: 6%;
    }
}

.slider__arrow.slider__arrow--small:hover{
    border: 1px solid var(--color-primary);
    cursor: pointer;
}

.slider__arrow.slider__arrow--light{
    background-color: #fff;
    color: var(--color-default);
}
.slider__arrow__icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.slider__arrow--small .slider__arrow__icon {
    font-size: 0.7rem;
    font-weight: bolder;
}
.slider__arrow.slick-prev{
    right: calc(40rem/16);
}

.slider__arrow--big.slick-prev {
    @media screen and (min-width: 768px){
        right: calc(64rem/16);
    }

    @media screen and (min-width: 992px){
        right: calc(80rem/16);
    }
}

.slick-arrow.slick-disabled{
    opacity: 0;
    cursor: none;
}

.slider__arrow.slick-disabled.slider__arrow--small:hover {
    cursor: none;
}


.slider__arrow.slider__arrow--white{
    background-color: #fff;
    color: #000;
}

/* dots */
.slider--hide-dots .slick-dots {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.slick-dots {
    text-align: center;
    list-style: none;
    padding: 20px 0 0;
    margin: 0;

    @media screen and (min-width: 768px) {
        padding: 40px 0 0;
    }
}
.slick-dots > li {
    display: inline-block;
    float: none;
}
.slick-dots button {
    border: 0;
    background: #fff;
    text-indent: -9999em;
    width: 100%;
    height: calc(3rem/16);
    border-radius: 0;
    opacity: 0.99;
    margin: 0;
    transition: opacity 250ms ease-in-out;
    padding: 0;
}

.slick-dots li.slick-active button{
    background: var(--color-default);
}

/* slider same-heigt */
.slider--same-height .slick-track{
    display: flex;
}

.slider--same-height .slick-slide{
    display: flex;
    flex-direction: column;
    height: auto;
}

.slider--same-height .slick-slide>div{
    height: 100%;
}