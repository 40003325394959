.modal{
    z-index: 9999;
}
.modal-content{
    border-radius: 0;
}

.modal-header{
    border: none;
}
.modal .close,
.modal .close:not(:disabled):not(.disabled):hover{
    opacity: 1;
    z-index: 5;
    position: relative;
}

.modal .close{
    font-size: calc(16rem/16);
    text-transform: none;
}

.modal-body__title{
    font-size: calc(20rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(40rem/16);
}

.modal-body{
    padding: 0 calc(20rem/16) calc(20rem/16);
    font-size: calc(14rem/16);
    @media screen and (min-width: 768px){
        padding: 0 calc(40rem/16) calc(40rem/16);
    }
}
.modal--products-look .modal-dialog {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        max-width: calc(950rem/16);
    }
}

/* Modal Cart */

.modal-cart__strong{
    font-size: calc(14rem/16);
    line-height: calc(20/14);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
}


/* Modal Country Select */

.modal-country-select .modal-body{
    @media screen and (min-width: 768px){
       padding: 0 calc(120rem/16) calc(60rem/16);
    }
}
.modal-country-select .modal-dialog {
    /*@media screen and (min-width: 768px){
        min-width: calc(750rem/16);
    }*/
}

.modal-country-select__list>li{
    display: flex;
    align-items: center;
    color: #7F7F7F;
}

.modal-country-select__list>li.active{
    color: var(--color-default);
}

.modal-country-select__list>li+li{
    margin-top: calc(18rem/16);
}

.modal-country-select__list .img-circle{
    height: calc(22rem/16);
    width: calc(22rem/16);
}

.modal-country-select__list-container {
    display: flex;
    align-items: center;
}

.modal-country-select__flag{
    position: relative;
    margin-right: calc(13rem/16);
    line-height: 1;
}

.modal-country-select__list .modal-country-select__flag:before{
    content: '';
    position: absolute;
    top: calc(-3rem/16);
    bottom: calc(-3rem/16);
    left: calc(-3rem/16);
    right: calc(-3rem/16);
    border: 1px solid var(--color-default);
    border-radius: 50%;
    display: none;
}

.modal-country-select__list>li.active .modal-country-select__flag:before{
    display: block;
}

.modal-country-select__list > li.active .modal-country-select__item{
    text-decoration: underline;
    color: var(--color-default);
}

.modal-country-select__title{
    font-size: calc(17rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem/16);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(3rem/16);
        font-size: calc(18rem/16);
    }
}


.modal-country-select__hl{
    font-size: calc(14rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(2rem/16);
    margin-bottom: calc(12rem/16);

    @media screen and (min-width: 768px){
        letter-spacing: calc(2.5rem/16);
        font-size: calc(15rem/16);
    }
}

.modal-country-select__item{
    color: #7f7f7f;
    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
    }
}

.modal-country-select__item:hover,
.modal-country-select__item.active{
    color: var(--color-theme-active);
}

.modal-backdrop{
    z-index: 5000;
}

@media screen and (min-width: 768px) and (max-width: 992px){
   .modal-xl{
       max-width: 1000px;
   }
}

.modal-information .modal-content{
    min-height: calc(300rem/16);
    @media screen and (min-width: 768px){
       min-height: calc(250rem/16);
    }
}
.modal-information .modal-body{
    display: flex;
    align-items: center;
}
