.content-anchor-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: calc(20rem/16);
    border-top: calc(4rem/16) solid #000000;
    align-items: center;
    gap: calc(25rem/16);
}

.content-anchor-nav__item-icon {
    font-size: calc(60rem/16);
}

.content-anchor-nav__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    width: 45%;
}

.content-anchor-nav__item-title {
    /*width: calc(150rem/16);*/
    word-wrap: break-word;
    display: flex;
    flex: 1;
    hyphens: auto;
}

.content-anchor-nav__wordwrap::after {
    content: '\A';
    white-space: pre;
}

.content-anchor-nav__icon-arrow {
    font-size: calc(40rem/16);
    rotate: calc(90deg);
    width: fit-content;
}

.content-anchor-nav__header {
    font-size: calc(28rem/16);
}

@media (min-width: 768px) {
    .content-anchor-nav {
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-evenly;
    }

    .content-anchor-nav__header {
        font-size: calc(18rem/16);
    }

    .content-anchor-nav__icon-arrow {
        font-size: calc(24rem/16);
    }

    .content-anchor-nav__item {
        width: 100%;
        margin-left: calc(10rem/16);
    }

    .content-anchor-nav__item-title {
        /*width: calc(105rem/16);*/
        word-wrap: break-word;
    }
}

@media (min-width: 1200px) {
    .content-anchor-nav {
        grid-template-columns: 20% 80%;
        grid-template-rows: 1fr;
        grid-column-gap: 0;
        grid-row-gap: unset;
        padding-top: calc(80rem/16);
        padding-bottom: calc(80rem/16);
    }

    .content-anchor-nav__header {
        font-size: calc(22rem/16);
    }

    .content-anchor-nav__icon-arrow {
        font-size: calc(25rem/16);
    }

    .content-anchor-nav__item-icon {
        font-size: calc(85rem/16);
    }

    .content-anchor-nav__item-title {
        font-size: calc(15rem/16);
        /*width: calc(122rem/16);*/
    }

}

@media (min-width: 1400px) {
    .content-anchor-nav__item-icon {
        font-size: calc(109rem/16);
    }

    .content-anchor-nav__header {
        font-size: calc(28rem/16);
    }

    .content-anchor-nav__item {
        margin-left: calc(20rem/16);
    }

    .content-anchor-nav__icon-arrow {
        font-size: calc(40rem/16);
    }

    .content-anchor-nav__item-title {
        /*width: calc(170rem/16);*/
        font-size: calc(18rem/16);
    }

}


