.newsletter-slide{
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    z-index: 10;
    font-size: calc(14rem/16);
    color: #fff;

    @media screen and (min-width: 1200px){
        padding-top: 18.19%;
    }

    @media screen and (min-width: 768px){
        padding-top: 21%;
        background-size: cover;

    }
    @media screen and (max-width: 767px){
        padding: calc(50rem/16) calc(20rem/16) calc(40rem/16);
    }
}
.newsletter-slide.newsletter-slide--bg-color{
    padding: 0;
}
.newsletter-slide.newsletter-slide--bg-color .newsletter-slide__body{
    position: relative;
    transform: none;
    left: auto;
    top: auto;
    padding: calc(50rem/16) calc(40rem/16);
    max-width: calc(740rem/16);
    margin: 0 auto;
}

.newsletter-slide__body{
    @media screen and (min-width: 768px){
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.newsletter-slide__hl{
    font-family: var(--font-title-bold);
    font-size: calc(25rem/16);
    line-height: calc(32/25);
    text-transform: uppercase;
    margin-bottom: calc(11rem/16);

    @media screen and (min-width: 768px){
        font-size: clamp(calc(25rem/16), 0.423rem + 2.373vw, calc(40rem/16));
        line-height: calc(60/40);
    }

    @media (min-width: 1400px) {
        font-size: calc(40rem/16);
    }
}

.newsletter-slide__text{
    font-size: calc(13rem/16);
    line-height: calc(24/13);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(26/16);
    }
}

.newsletter-slide .input-group{
    max-width: calc(628rem/16);
    margin: 0 auto;
}

.newsletter-slide .btn{
    @media screen and (max-width: 767px) {
        padding: calc(10rem / 16);
        font-size: calc(10rem / 16);
        height: calc(50rem/16);
        width: 100%;
    }
}

.newsletter-slide .form-control{
    font-size: calc(16rem/16);

    @media screen and (max-width: 767px){
        font-size: calc(14rem/16);
        height: calc(45rem/16);
    }
}