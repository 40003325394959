.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: calc(80rem/16);
}
.loading-spinner__item {
    width: calc(18rem/16);
    height: calc(18rem/16);
    background-color: var(--color-theme-active);

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}

.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 100;
    text-align: center;
}

.loading-spinner--fixed{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.loading-animation{
    width: calc(70rem/16);
}