/*Margin Helper*/

.mt30{
    margin-top: calc(30rem/16);
}

/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-dark-grey { color: var(--color-dark-grey); }

.theme-active .text-theme{
    color: var(--color-theme-active);
}

.theme-style .text-theme{
    color: var(--color-theme-style);
}



/* ----- Text gradients ----- */

/* Für alle Browser die das supporten */
@supports ( -webkit-background-clip: text) {
    .text-primary-gradient,
    .theme-active .text-theme-gradient{
        background-image: linear-gradient(90deg,var(--color-third) 8%,var(--color-theme-active) 55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1.1;
        padding-top: 0.5rem

    }

    .text-style-gradient,
    .theme-style .text-theme-gradient{
        background-image: linear-gradient(90deg,var(--color-third) 8%,var(--color-theme-style) 55%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 1.1;
        padding-top: 0.5rem
    }
}


/* Fallback für IE z.b. */
.text-primary-gradient,
.theme-active .text-theme-gradient,
.text-style-gradient,
.theme-style .text-theme-gradient{
    display: inline-block;
    color: #000;
}

/* --------------------- */

.theme-style.gradient-fallback-white .text-theme-gradient,
.theme-active.gradient-fallback-white .text-theme-gradient{
    color: #fff;
}

.bg-active-gradient,
.theme-active .bg-theme-gradient{
    background: linear-gradient(90deg, var(--color-third) 14%, var(--color-theme-active) 55%);
    color: #fff;
}

.theme-active .bg-theme-gradient-vertical{
    background: linear-gradient(0deg, var(--color-third) 14%, var(--color-theme-active) 55%);
    color: #fff;
}

.bg-style-gradient,
.theme-style .bg-theme-gradient{
    background: linear-gradient(90deg, var(--color-third) 14%, var(--color-theme-style) 55%);
    color: #fff;
}

.theme-style .bg-theme-gradient-vertical{
    background: linear-gradient(0deg, var(--color-third) 14%, var(--color-theme-style) 55%);
    color: #ffffff;
}


.bg-grey{
    background-color: var(--color-grey);
    color: #000;
}
.bg-black{
    background-color: #000000;
    color: #ffffff;
}

.bg-light-grey {
    background-color: var(--color-light-grey);
    color: #000;
}

.bg-light-grey .btn {

}

.bg-season-1 {
    background: var(--color-season-1);
}

.bg-season-2 {
    background: var(--color-season-2);
}

.bg-season-3 {
    background: var(--color-season-3);
}

.bg-season-4 {
    background: var(--color-season-4);
}

.bg-season-5 {
    background: var(--color-season-5);
}

.bg-season-6 {
    background: var(--color-season-6);
}

.bg-season-7 {
    background: var(--color-season-7);
}

.img-circle{
    border-radius: 50%;
}

.text-with-icon{
    display: block;
}


.text-with-icon>.icon{
    margin-right: calc(5rem/16);
    font-size: calc(20rem/16);

    vertical-align: calc(-4rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(12rem/16);
        font-size: calc(22rem/16);
    }
}
.text-with-icon>.icon.icon-mail{
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
    }
}

.text-icon>.icon{
    vertical-align: calc(-2rem/16);
    margin-right: calc(3rem/16);
}
.icon-rotate-315 {
    transform: rotate(315deg);
}

.js-go-to-link{
    cursor: pointer;
}

.btn-dynamic-arrow__arrange {
    box-sizing: border-box;
    display: table;
    margin: 0;
    min-width: 100%;
    padding: 0;
    table-layout: auto;
}

.btn-dynamic-arrow__size-fill,
.btn-dynamic-arrow__size-fit {
    display: table-cell;
    padding: 0;
}

.btn-dynamic-arrow__size-fill {
    width: 100%;
}

.js-tilt-animation{
    position: relative;
}

.pull-in-front {
    z-index: 10 !important;
}

.centered{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.fs-12 {
    font-size: 12px;
}

@media screen and (max-width: 767px){
    .fs-xs-10{
        font-size: calc(10rem/16);
    }
}

.img-object-fit-cover{
    height: 100%;
}

.img-object-fit-cover img{
    object-fit: cover;
    height: 100%;
}

.wysiwyg a.anchor-link{
    color: var(--color-default);
    text-decoration: none;
    scroll-margin-top: 13rem;
}

.wysiwyg a.anchor-link:hover{
    color: var(--color-default);
    text-decoration: none;
}

.alert__icon{
    font-size: 20px;
    margin-right: 12px;
    margin-top: 5px;
}

.alert a{
    text-decoration: underline;
}

.satellite-bubble.chspt-wrapper.svelte-1rdizjn.svelte-1rdizjn{
    z-index: 10!important; /* overwrite z-index from chspt-wrapper widget */
}
[hidden] {
    display: none !important;
}
.overflow-hidden{
    overflow: hidden;
}

.gap-2 {
    gap: calc(8rem/16);
}

.pe-none {
    pointer-events: none;
}