.wysiwyg{
    font-size: calc(16rem/16);
    line-height: calc(26/16);

    @media screen and (max-width: 768px) {
        font-size: calc(13rem/16);
        line-height: calc(24/13);
    }
}

.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(20rem/16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
}

.wysiwyg h2,
.wysiwyg .h2{
    font-size: calc(30rem/16);
}
/*table styling in _tables.scss*/


.wysiwyg ul{
    list-style: none;
    padding-left: 0;
}

.wysiwyg li{
    padding-left: calc(20rem/16);
    position: relative;
}

.wysiwyg li+li{
    margin-top: calc(10rem/16);
}

.wysiwyg li:before{
    content: '';
    position: absolute;
    left: 0;
    top: calc(14rem/16);
    width: calc(5rem/16);
    height: calc(1rem/16);
    background-color: var(--color-default);

    @media screen and (max-width: 768px) {
        top: calc(11rem/16);
    }
}