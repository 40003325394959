.mollie-cc{
    @media screen and (min-width: 768px){
        width: 80%;
    }
}

.mollie-component{
    border-radius: 0;
    border: 1px solid var(--color-text-muted);
    padding: calc(15rem/16) calc(20rem/16);
    position: relative;
    transition: all 0.4s ease;
    height: calc(56rem/16);
}

.mollie-component.is-invalid {
    border-color: var(--color-danger);
    transition: 0.3s border-color cubic-bezier(0.4, 0, 0.2, 1);
}

.mollie-cc label{
    font-family: var(--font-default-bold);
}

.field-error {
    font-size: 12px;
    margin-top: 2px;
    color: var(--color-danger);
    font-weight: 400;
}