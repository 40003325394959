.footer{
    position: relative;
    z-index: 2;
}
.footer__main{
    background-color: #fff;
    color: var(--color-default);
    padding: calc(40rem/16) 0 calc(30rem/16);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        padding: calc(60rem/16) 0 calc(50rem/16);
        font-size: calc(12rem/16);
    }

    @media screen and (min-width: 1200px){
       font-size: calc(14rem/16);
    }
}

.footer__hl{
    font-family: var(--font-title-default);
    font-size: calc(10rem/16);
    line-height: calc(15/10);
    text-transform: uppercase;
    margin-bottom: calc(17rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        line-height: calc(20/12);
        margin-bottom: calc(18rem/16);
    }
}

.footer-collapse__btn{
    color: var(--color-default);
    @media screen and (max-width: 767px){
       margin-bottom: 0;
        position: relative;
        padding: calc(20rem/16) calc(12rem/16);
        padding-right: calc(40rem/16);
        width: 100%;
        border-top: 1px solid var(--color-grey);
    }
}

.footer-collapse__btn--last.collapsed{
    @media screen and (max-width: 767px){
        border-bottom: 1px solid var(--color-grey);
    }
}

.collapsed .footer-collapse__icon{
    transform: rotate(0);
}

.footer-collapse__icon{
    position: absolute;
    right: calc(10rem/16);
    top: 24px;
    transform: rotate(45deg);

}

.footer__country-select{
    padding: 0;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--color-default);
}

.footer__country-select:hover .footer__country-select-text{
    text-decoration: underline;
}

.footer__country-select .img-circle{
    @media screen and (max-width: 767px){
        width: calc(19rem/16);
        height: calc(19rem/16);
        margin-top: calc(-2rem/16);
    }
}

.footer-collapse__body{
    font-size: calc(16rem/16);
    line-height: calc(26/16);

    @media screen and (max-width: 767px){
        padding: 0 15px 15px;
        font-size: calc(14rem/16);
    }
}

.show .footer-collapse__body{
    @media screen and (max-width: 767px){
        border-bottom: 1px solid var(--color-grey);
    }
}

.footer-link-list {
    font-size: calc(16rem/16);
    line-height: calc(26 /16);

    @media screen and (max-width: 767px){
        padding: 0 15px 15px;
        font-size: calc(14rem/16);
    }
}

.footer-link-list>li+li{
    margin-top: calc(5rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(10rem/16);
    }
}

.footer-link-icon{
    display: flex;
    align-items: center;
    margin-top: calc(10rem/16);
}

.footer-link-icon .icon{
    width: calc(30rem/16);
    font-size: calc(17rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);

    }

    @media screen and (min-width: 1200px){
        font-size: calc(17rem/16);
    }
}

.footer-link-icon .icon.icon-mobile-phone{
    font-size: calc(22rem/16);
}

.footer__subtitle{
    font-size: calc(12rem/16);
    line-height: calc(26/12);
    text-transform: none;
    margin-top: calc(16rem/16);
}

.list-footer-social a{
    font-size: calc(24rem/16);
    color: var(--color-dark);
    margin-right: calc(23rem/16);

    @media screen and (min-width: 1200px){
        margin-right: calc(24rem/16);
    }

    @media screen and (min-width: 768px){
        margin-right: calc(10rem/16);
    }
}

.list-footer-social a:hover{
    color: #3b3b3b;
}

.footer__bottom{
    background-color: var(--color-default);
    padding: calc(18rem/16) calc(47rem/16);
    font-size: calc(10rem/16);
    line-height: calc(15/10);
    color: var(--color-grey);
    
    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        line-height: calc(17/12);
        padding: calc(20rem/16) calc(194rem/16) calc(23rem/16) calc(158rem/16) ;

    }
    
    @media screen and (max-width: 767px){
       text-align: center;
    }
}

.list-links .list-inline-item:not(:last-child){
    margin-right: calc(17rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(34rem/16);
    }
}

.footer a:hover{
    text-decoration: underline;
}

.footer .lang-switch{
    @media screen and (max-width: 767px){
       display: flex;
        justify-content: center;
    }
}
.footer-social-column{
    @media screen and (max-width: 767px){
        display: flex;
        flex-flow: column-reverse;
    }
}
.footer-sponsor-logo__grid-item{
    margin-right: calc(5rem/16);
}
a.footer-sponsor-logo__grid-item:hover {
    text-decoration: none;
}
.footer-sponsor-logo__grid-item:hover .footer-sponsor-logos__img{
    outline: 1px solid var(--color-default);
}

.footer-sponsor-logo__container {
    display: flex;
    width: 100%;
}
@media(max-width: 767px){
    .footer-sponsor-logo__container{
       justify-content: center;
    }
}