.product-teaser__img{
    background: var(--color-light-grey);
}
.product-teaser__content{
    padding: calc(15rem/16) 0;
}
.product-teaser__title{
    font-size: calc(18rem/16);
    line-height: calc(22.5/18);
    font-family: var(--font-title-default);
    margin-bottom: calc(9rem/16);
    text-transform: none;
}
.product-teaser__subtitle{
    font-size: calc(12rem/16);
    line-height: calc(14/12);
}
.product-teaser__price{
    font-family: var(--font-title-default);
    font-size: calc(12rem/16);
    line-height: calc(16/12);
}
.product-teaser__discount{
    font-size: calc(12rem/16);
    line-height: calc(16/12);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
    gap: calc(8rem/16);
}
.product-teaser__discount-value{
    opacity: 0.5;
    text-decoration: line-through;
}

.product-teaser__placeholder{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: calc(4rem/16);
    margin-top: calc(4rem/16);
    overflow: hidden;
    position: relative;
}
.product-teaser__placeholder:after{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        #FFFFFF00 0,
        #FFFFFF33 20%,
        #FFFFFF80 60%,
        #FFFFFF00
    );
    animation: shimmer 1000ms infinite;
    content: '';
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
.product-teaser__colors.blaze-slider {
    /*please keep important here because of blaze slider bug*/
    --slides-to-show: 6 !important;
    --slide-gap: 4px;
    margin-top: calc(4rem/16);
    position: relative;
    z-index: 2;
}
.product-teaser__colors.blaze-slider.static .product-teaser__color-prev,
.product-teaser__colors.blaze-slider.static .product-teaser__color-next{
    display: none;
}
.product-teaser__color{
    background: var(--color-light-grey);
    border: none;
    display: block;
    cursor: pointer;
}
.product-teaser__color:focus{
    outline: none;
}
.product-teaser__color.is-active,
.product-teaser__color:hover{
    border: calc(1rem/16) solid var(--color-dark);
}
.product-teaser__color-img{
    padding: calc(7.5rem/16);
}

.stretch-link button:not(.stretch-link__link).product-teaser__color-prev,
.stretch-link button:not(.stretch-link__link).product-teaser__color-next{
    background: var(--color-light-grey);
    border: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: calc(calc(100% - calc(5 * calc(4rem/16))) / 6);
    height: 100%;
    transition: opacity 200ms ease-in-out;
}
.product-teaser__color-prev{
    left: 0;
}
.product-teaser__color-next{
    right: 0;
}
.product-teaser__color-prev:focus,
.product-teaser__color-next:focus{
    outline: none;
}
.stretch-link button:not(.stretch-link__link).product-teaser__color-prev:hover,
.stretch-link button:not(.stretch-link__link).product-teaser__color-next:hover{
    background: var(--color-dark);
    color: var(--color-light-grey);
}
.product-teaser__colors.blaze-slider.start .product-teaser__color-prev,
.product-teaser__colors.blaze-slider.end .product-teaser__color-next{
    opacity: 0;
    pointer-events: none;
}

/*badge from old project*/
.product-teaser__season-feature{
    width: fit-content!important;
    height: fit-content;
    margin-bottom: 0.3rem;
    color: var(--color-white);
    font-size: calc(9rem/16);
    line-height: calc(12/10);
    padding: calc(5rem/16) calc(7rem/16) calc(3rem/16);
    background-color: var(--color-dark);
    z-index: 1;
    @media (max-width: 768px){
        font-size: calc(7rem/16);
        line-height: 1.22222;
    }
}
.product-teaser__badge-container{
    width: 100%;
    position: absolute;
    top: 0;
    padding: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media (max-width: 767px) {
        padding: 0.5rem 0;
    }
}
.product-teaser__badge-inner-container{
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.product-teaser__badge{
    background-color: var(--color-dark);
    color: #fff;
    font-size: calc(7rem/16);
    letter-spacing: calc(1rem/16);
    line-height: calc(11/9);
    text-transform: uppercase;
    padding: calc(5rem/16) calc(7rem/16) calc(3rem/16);
    width: fit-content!important;
    margin-bottom: 0.3rem;
    z-index: 1;
    white-space: nowrap;

    @media screen and (min-width: 768px){
        font-size: calc(9rem/16);
        letter-spacing: calc(1.8rem/16);
    }
}
.product-teaser__info-text{
    font-size: calc(14rem/16);

    display: inline-block;
    padding: calc(4rem/16) calc(10rem/16);
    background-color: var(--color-grey);
}