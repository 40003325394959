.form-errors {
    color: var(--color-danger);
}
.form-errors:empty {
    display: none;
}
.form-errors ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.parsley-errors-list{
    list-style: none;
    padding: 0;
    font-size: calc(12rem/16);
    position: relative;
    color: var(--color-danger);
    line-height: calc(15/12);
    z-index: 5;
}
.animate-border ~ .form-errors  .parsley-errors-list {
    position: absolute;
}

.custom-checkbox .parsley-errors-list{
    position: static;
    padding-left: calc(24rem/16);
}


.has-error .form-control{
    border: 2px solid var(--color-danger);
}
