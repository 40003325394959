.styled-product-slider{
    @media screen and (min-width: 768px){
        margin: 0 -5.8%;
    }
}

.styled-product-slider__title{
    letter-spacing: 7.5px;
    line-height: 1;
    color: #fff;
    position: relative;

    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding-left: calc(45rem/16);
    }

    @media screen and (min-width: 768px){
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        padding-right: calc(100rem/16);
        padding-left: calc(180rem/16);
        width: 80%;
    }
    
    @media screen and (max-width: 767px){
       font-size: calc(30rem/16);
        padding: 0 calc(15rem/16);
        color: #000;
    }

    @media screen and (min-width: 321px) and (max-width: 767px){
        top: calc(-15rem/16);
    }
}

.styled-product-slider .row--skew__reskew-md{
    @media screen and (min-width: 768px){
        margin: 0 -10.5%;
    }
}

.styled-product-slider__slider{
    @media screen and (min-width: 768px){
       margin-left: calc(-100rem/16);
        height: 100%;
        transform: skew(15deg);
        position: relative;
    }
    
    @media screen and (max-width: 767px){
        position: absolute;
        top: 50%;
        right: 0;
        width: 100%;
        padding-left: 40%;
        transform: translateY(-50%);
    }
}

.styled-product-slider .slick-list{
    @media screen and (min-width: 768px){
        position: relative;
        top: 7%;
    }
}

.styled-product-slider__slider .slick-slide{
    @media screen and (min-width: 768px){
        width: calc(376rem/16);
        margin: 0 calc(18rem/16);
    }
    @media screen and (max-width: 767px){
        width: calc(150rem/16);
        margin: 0 calc(5rem/16);
    }
}

.styled-product-slider__slider .slick-arrow{
    position: absolute;
    bottom: 0;
    width: calc(40rem/16);
    height: calc(40rem/16);
    background-color: #000;
    color: #fff;
    font-size: calc(15rem/16);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease;
    z-index: 3;

    @media screen and (min-width: 768px){
        right: 9.4% ;
        width: calc(110rem/16);
        height: calc(110rem/16);
        font-size: calc(30rem/16);
    }

    @media screen and (max-width: 767px){
        left: 0;
    }
}

.styled-product-slider__slider .slick-arrow.slick-prev{

    border-right: 1px solid #303030;

    @media screen and (min-width: 768px){
        right: calc(9.4% + 110px);
    }
}

.styled-product-slider__slider .slick-arrow.slick-next{
    @media screen and (max-width: 767px){
        left: calc(40rem/16);
    }
}

.styled-product-slider__slider .slick-arrow:not(.slick-disabled):hover{
    background-color: #303030;
}


.styled-product-slider .product-teaser{
    @media screen and (max-width: 767px){
       padding: calc(10rem/16) calc(5rem/16);
        height: 100%;
    }
}

.styled-product-slider .product-teaser__title{
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}
