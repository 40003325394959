:root {
    --cart-overlay-padding: calc(15rem/16) calc(20rem/16);
    
    @media screen and (min-width: 768px){
        --cart-overlay-padding-md: calc(20rem/16) calc(20rem/16);
    }

}


.cart-overlay{
    position: fixed;
    right: 0;
    top: calc(90rem/16);
    bottom: 0;
    background-color: #fff;
    width: 100%;
    z-index: 10;

    transform: translateX(100%);
    transition: all 350ms ease;

    @media screen and (min-width: 768px){
        width: calc(376rem/16);
        top: calc(80rem/16);
        box-shadow: 0 20px 30px 0 rgb(0 0 0 / 20%);
    }
}

.has-navbar-top .cart-overlay{
    @media screen and (min-width: 768px){
        top: calc(115rem/16);

    }
}

.is-sticky .cart-overlay{
    top: calc(60rem/16);
    @media screen and (min-width: 768px){
       top: calc(80rem/16);
    }
}

.cart-overlay.is-open{
    transform: translateX(0);
}

.cart-overlay__loading-content{
    height: calc(100% - 65px);
}

.cart-overlay__head{
    padding: var(--cart-overlay-padding);
    background-color: var(--color-light-grey);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    font-family: var(--font-title-default);
    line-height: 1.1;
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px){
        padding: var(--cart-overlay-padding-md);
    }
}

.cart-overlay__close{
    border: 0;
    width: calc(25rem/16);
    height: calc(25rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: var(--color-default);
}

.cart-overlay__body{
    padding: var(--cart-overlay-padding);
    overflow-y: auto;
    height: calc(100% - 63px);

    @media screen and (min-width: 768px){
        padding: var(--cart-overlay-padding-md);
        height: auto;
    }
}

.cart-overlay__notify{
    display: flex;
}

.cart-overlay__notify-text{
    font-size: calc(9rem/16);
    letter-spacing: calc(1.8rem/16);
    text-transform: uppercase;
}

.cart-overlay__notify-circle {
    background-color: var(--color-success-light);
    color: #fff;
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(8rem/16);
    margin-top: calc(-3rem/16);
    margin-right: calc(8rem/16);
}

.cart-overlay__notify-circle.is-not-available {
    background-color: var(--color-danger-light);
}

.cart-overlay__matching {
    margin-top: calc(20rem/16);
    margin-bottom: calc(20rem/16);
}

.cart-overlay__summary{
    margin-top: calc(10rem/16);
    margin-bottom: calc(10rem/16);
}
.cart-overlay__summary-price{
    font-size: calc(20rem/16);
}

.cart-overlay__summary-info{
    font-size: calc(10rem/16);
    margin-top: calc(-3rem/16);
}

.cart-overlay__footer{
    padding: var(--cart-overlay-padding);
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.2);

    @media screen and (min-width: 768px){
        padding: var(--cart-overlay-padding-md);
    }
    
    @media screen and (max-width: 767px){
        position: fixed;
        background: #fff;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.cart-overlay__title{
    font-size: calc(12rem/16);
    text-transform: none;
    font-family: var(--font-title-default);
    margin-bottom: calc(10rem/16);
}

.cart-overlay__wrapper{
    @media screen and (max-width: 767px){
       overflow-y: auto;
        padding-bottom: calc(80rem/16);
    }
}
.summary-info__legal{
    @media screen and (max-width: 768px) {
        font-size: calc(9rem/16);
    }
}