.acc-order-item{
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: calc(36rem/16);
    padding: calc(28rem/16) 0 calc(30rem/16) 0;
    border-bottom: calc(1rem/16) solid var(--color-grey);
    @media (min-width: 768px) {
        grid-template-columns: 1.3fr 8fr 2fr;
        grid-gap: calc(36rem/16);
    }
    @media (max-width: 767px) {
        grid-template-areas:
        "image info"
        "empty price";
    }
}
.acc-order-item.acc-order-item--canceled > *:not(:first-child),
.acc-order-item.acc-order-item--canceled .acc-order-item__image img{
    opacity: 0.5;
}
.acc-order-item__title{
    font-size: calc(16rem/16);
    @media (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
.acc-order-item__price-container{
    grid-area: price;
}
.acc-order-item__price{
    font-size: calc(14rem/16);
    font-family: var(--font-title-default);
    line-height: calc(21/12);
    display: flex;
    gap: calc(11rem/16);
}
.acc-order-item__price-strike{
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    margin-top: calc(3rem/16);
    color: var(--color-text-muted);
    text-decoration: line-through;
}
.acc-order-item__badge{
    color: #FFFFFF;
    background-color: #000000CC;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: calc(6rem/16) calc(15rem/16);
    border-radius: calc(23rem/16);
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
}