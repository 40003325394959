.img-slider__arrow{
    width: calc(40rem/16);
    height: calc(40rem/16);
    font-size: calc(15rem/16);
    background-color: #000;
    color: #fff;
    position: absolute;
    bottom: calc(-45rem/16);
    right: 0;
    z-index: 1;
    line-height: 1;
    cursor: pointer;

    @media screen and (min-width: 768px){
        width: calc(60rem/16);
        height: calc(60rem/16);
        font-size: calc(20rem/16);
        bottom: calc(-65rem/16);
    }
}

.img-slider__arrow.slick-prev{
    right: calc(40rem/16);

    @media screen and (min-width: 768px){
        right: calc(60rem/16);
    }
}

/* hover */
.img-slider__item {
    position: relative;
}
.img-slider__item .img-gallery__overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: all 0.4s ease-in-out 0s;
}

.img-slider__item:hover .img-gallery__overlay{
    opacity: 1;
}
.img-gallery__button-wrapper {
    position: absolute;
    text-align: center;
    padding-left: calc(16rem/16);
    padding-right: calc(16rem/16);
    width: 100%;
    top: 50%;
    left: 20%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out 0s;
}
.img-slider__item:hover .img-gallery__button-wrapper{
    top: 50%;
    left: 50%;
    opacity: 1;
}
.img-gallery__button {
    height: calc(50rem/16);
    width: calc(50rem/16);
    border-radius: 50%;
    background-color: #fff;
}