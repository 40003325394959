.product-slider{
    @media screen and (max-width: 767px){
       margin: 0 calc(-20rem/16) 0 calc(-5rem/16);
    }
}

.product-slider--justify-left .slick-track{
    @media screen and (min-width: 768px){
       margin: 0;
    }
}


.product-slider>.slick-list{
    @media screen and (min-width: 768px){
        margin: 0 calc(-17rem/16);
        overflow: visible;
    }
}

.product-slider>.slick-list>.slick-track>.slick-slide{
    margin: 0 calc(5rem/16);

    @media screen and (min-width: 768px){
       margin: 0 calc(15rem/16);
    }
}

.product-slider__item{
    height: 100%;
}

.product-slider__item.product-slider__item--small-shadow {

    @media screen and (min-width: 768px){
        padding: 0 0 calc(32rem/16);
    }
}

.product-teaser-simple-row__button {
    margin-top: calc(20rem/16);
    
    @media screen and (min-width: 768px){
        margin-top: calc(45rem/16);
    }
}

.product-slider .slider__arrow.slick-next{
    right: calc(5rem/16);
    top: 35%;

    @media screen and (min-width: 1850px){
        right: calc(-25rem/16);
    }
}

.product-slider .slider__arrow.slick-prev{
    left: calc(5rem/16);
    top: 35%;

    @media screen and (min-width: 1850px){
        left: calc(-25rem/16);
    }
}

.product-slider .slider__arrow.slick-disabled{
    display: none!important;
}