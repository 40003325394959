
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .js-tilt-animation {
        -webkit-transform: none !important;
        transform: none !important;
    }
}

@supports (-ms-ime-align:auto){
    .js-tilt-animation {
        -webkit-transform: none !important;
        transform: none !important;
    }
}

@media screen and (max-width: 767px) {
    .js-tilt-animation {
        -webkit-transform: none !important;
        transform: none !important;
    }
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(-10%);
        opacity: 0;
    }
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}
.fade-in-left{
    transform: translateX(-10%);
    opacity: 0;
    animation: 1200ms ease-in-out 350ms 1 fadeInLeft;
    animation-fill-mode: forwards;
}

@keyframes bounceIn {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@media screen and (min-width: 768px){


    .bounce-in__item {
        transform: scale(0);
        animation: .5s linear 1.2s 1 bounceIn;
        animation-fill-mode: forwards;
    }

    .bounce-in>.bounce-in__item:nth-child(2){
        animation-delay: 1800ms;
    }
    .bounce-in>.bounce-in__item:nth-child(3){
        animation-delay: 2400ms;
    }
    .bounce-in>.bounce-in__item:nth-child(4){
        animation-delay: 3000ms;
    }
    .bounce-in>.bounce-in__item:nth-child(5){
        animation-delay: 3600ms;
    }
}

