.text-with-img{
    margin-top: calc(57rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(137rem/16);
        margin-bottom: calc(80rem/16);
    }
}
.text-with-img__title {
    margin-bottom: calc(18rem/16);

    @media screen and (max-width: 992px) {
        margin-top: calc(10rem/16);
        font-size: calc(18rem/16);
        line-height: calc(24/18);
        margin-bottom: calc(20rem/16);
    }
}

.text-with-img__toptitle{
    text-transform: uppercase;
    font-size: calc(12rem/16);
    letter-spacing: calc(1rem/16);
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
    }
}

.text-with-img__button {
    padding: calc(15rem/16) calc(30rem/16);
    margin-bottom: calc(10rem/16);

    @media screen and (max-width: 768px){
        padding: calc(12rem/16) calc(30rem/16);
        font-size: calc(12rem/16);
        line-height: calc(21/12);
    }
}

.text-with-img__content{
    @media screen and (max-width: 768px){
        margin-top: calc(21rem/16);
    }

    @media screen and (min-width: 768px) {
        padding-left: calc(30rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1200px){
        width: calc(470rem/16);
    }
@media screen and (min-width: 1200px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

    @media screen and (min-width: 1600px){
        padding-left: calc(109rem/16);
        width: calc(656rem/16);
    }

}

.is-left .text-with-img__content{
    @media screen and (min-width: 768px){
        padding-right: calc(30rem/16);
        padding-left: 0;
    }

    @media screen and (min-width: 1500px){
        padding-right: calc(120rem/16);
    }

}

.text-with-img__image{
    max-height: calc(209rem/16);
    max-width: calc(314rem/16);
    margin: auto;
    width: 100%!important;

    @media screen and (min-width: 768px) {
        max-height: calc(660rem/16);
        max-width: calc(982rem/16);
    }
}