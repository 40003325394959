.custom-checkbox{
    position: relative;
}
.custom-checkbox label{
    position: relative;
    transition: all 200ms ease;
    display: block;
    cursor: pointer;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid var(--color-dark-grey);
    background: #ffffff;
    width: calc(20rem/16);
    height: calc(20rem/16);
    margin-top: calc(2rem/16);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(9rem/16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
    color: #fff;
}

.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}

.custom-checkbox__input:focus ~ .custom-checkbox__box ~ .custom-checkbox__text,
.custom-checkbox__input:checked ~ .custom-checkbox__box ~ .custom-checkbox__text {
   color: var(--color-primary);
}

.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}

.custom-checkbox__text {
    display: block;
    overflow: hidden;
    color: var(--color-dark-grey);
    transition: color 150ms ease;
    
    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
    }
}

.custom-checkbox label:hover .custom-checkbox__text{
    color: var(--color-primary);
}
.custom-checkbox__text a{
    text-decoration: underline;
}

/* For Checkbox in Bootstrap Dropdown */
.custom-checkbox__input.custom-checkbox__input--full-and-hide{
    clip: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.custom-checkbox__count{
    font-size: calc(11rem/16);
}