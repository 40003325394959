.custom-select-dropdown.show{
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11);
}
.custom-select-dropdown__button{
    border: none;
    background-color: #fff;
    padding: calc(9rem/16) calc(30rem/16) calc(9rem/16) calc(30rem/16);
    text-align: left;
    font-size: calc(12rem/16);
    line-height: calc(17/14);
    border-radius: 50vh;
    height: calc(51rem/16);
    
    @media screen and (max-width: 1200px){
        height: calc(45rem/16);
        padding: calc(7rem/16) calc(20rem/16) calc(7rem/16) calc(20rem/16);
        font-size: calc(14rem/16);
        line-height: calc(14/14);
    }
}

.custom-select-dropdown__button:not(:disabled):hover{
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-white);
}
.custom-select-dropdown__button:disabled{
    background-color: var(--color-white);

    .custom-select-dropdown__icon{
        display: none;
    }
}

.custom-select-dropdown__label-wrapper{
    display: flex;
    align-items: center;
}

.custom-select-dropdown__label{
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(14/12);

    @media screen and (min-width: 768px){
        margin-bottom: calc(2rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(2rem/16);
    }
}

.custom-select-dropdown__label-img{
    margin-right: calc(10rem/16);
}

.custom-select-dropdown__icon{
    position: absolute;
    right: calc(33.5rem/16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(5rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(8rem/16);
    }
}

.custom-select-dropdown__menu {
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    box-shadow: 0 27px 31px 0 rgba(0,0,0,0.11);
}

.custom-select-dropdown__menu[x-placement="top-start"]{
    box-shadow: 0 -22px 30px 0 rgba(0,0,0,0.11);

}