.modal-dialog--add-cart{
    max-width: calc(1230rem/16);

    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}
.modal-dialog--add-cart .modal-body{
    padding: 0 calc(50rem/16) calc(50rem/16) calc(50rem/16);
    
    @media screen and (max-width: 768px) {
        padding: 0 calc(30rem/16) calc(40rem/16) calc(30rem/16);
    }
}
.modal-dialog--add-cart .close{
    font-size: calc(23rem/16);
    padding: calc(6rem/16);
    margin-right: 0;
    margin-top: 0;

    @media screen and (max-width: 768px) {
        font-size: calc(16rem/16);
        padding: calc(5rem/16);
        margin-bottom: 0;
    }
}
.modal-dialog--add-cart .modal-header{
    padding: calc(14rem/16)
}
.modal-dialog--add-cart .modal-content-text{
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 768px) {
        margin-top: calc(14rem/16);
    }
}
.modal-dialog--add-cart .modal-title{
    font-family: var(--font-title-bold);
    font-size: calc(30rem/16);
    line-height: calc(35/30);
    margin-bottom: calc(13rem/16);

    @media screen and (max-width: 768px) {
        font-size: calc(16rem/16);
        line-height: calc(20/16);
        margin-bottom: calc(15rem/16);
    }
}
.modal-dialog--add-cart .modal-price{
    font-size: calc(22rem/16);
    line-height: calc(26/22);
    margin-right: calc(10rem/16);

    @media screen and (max-width: 768px) {
        font-size: calc(18rem/16);
        line-height: calc(18/16);
        margin-right: calc(8rem/16);
    }
}
.modal-dialog--add-cart .modal-info{
    font-size: calc(14rem/16);
    line-height: calc(18/14);

    @media screen and (max-width: 768px) {
        font-size: calc(13rem/16);
    }
}
.modal-dialog--add-cart .modal-priceInfo{
    margin-bottom: calc(13rem/16);

    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
}
.modal-dialog--add-cart .product-size__row{
    @media screen and (max-width: 768px) {
        margin-top: calc(14rem/16);
        width: 100%
    }
}

.modal-dialog--add-cart .discount{
    padding: calc(5rem/16);
}

.modal-dialog--add-cart .product-detail__buttons .btn{
    max-width: calc(356rem/16);

    @media screen and (max-width: 768px) {
        width: 100%
    }
}
.modal-dialog--add-cart .close__size-table{
    font-size: calc(16rem/16);
    padding-left: calc(14rem/16);
    align-self: center;
}

.modal-dialog--add-cart  .change-modal__btn:disabled{
    cursor: not-allowed;
    opacity: 1;
}