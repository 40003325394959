.content-header {
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--color-white);
    @media screen and (min-width: 768px){
        grid-row-gap: calc(5rem/16);
    }
}

.content-header.text-right {
    text-align: right;
}

.content-header__media .content-header__header,
.content-header__content .content-header__header {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(39rem/16) calc(30rem/16);
}

.content-header--double-image .content-header__media{
    @media screen and (max-width: 767px){
       display: none;
    }
}

.content-header--text-right .content-header__content{
    @media screen and (min-width: 768px){
        order: 1;

    }
}

.content-header.content-header--season .content-header--season__header {
    font: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(39rem/16) calc(30rem/16);
}

.content-header--season__header {
    display: none;
}


.content-header__title {
    font-size: calc(25rem/16);
    font-family: var(--font-title-default);
    line-height: calc(32/25);
    hyphens: auto;
    margin: 0;
}

.content-header__subtitle {
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    text-transform: uppercase;
}


@media screen and (min-width: 768px) {
    .content-header {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: calc(8rem/16);
        grid-row-gap: 0;
    }

    .content-header__title {
        font-size: calc(25rem/16);
        font-family: var(--font-title-default);
        line-height: calc(30/25);
    }

    .content-header__subtitle {
        font-size: calc(16rem/16);
        line-height: calc(23/16);
        text-transform: uppercase;
    }

    .content-header.text-second-picture .content-header__media .content-header__header {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: calc(40rem/16);
    }
    /* Text im linken Bild */
    .content-header .content-header__content .content-header__header {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: calc(40rem/16);
    }

    .content-header.content-header--season .content-header--season__header {
        height: 100%;
        padding: calc(40rem/16);
    }

}

@media screen and (min-width: 1200px) {
    .content-header__title {
        font-size: calc(40rem/16);
        line-height: calc(50/40);
    }

    .content-header__subtitle {
        font-size: calc(18rem/16);
        line-height: calc(26/18);
    }

    .content-header .content-header__media .content-header__header {
        padding: calc(96rem/16) calc(106rem/16);
    }

    .content-header .content-header__content .content-header__header {
        padding: calc(96rem/16) calc(106rem/16);
    }

    .content-header.content-header--season .content-header--season__header {
        padding: calc(96rem/16) calc(106rem/16);
    }

}