.page-item .page-link{
    color: var(--color-default);
    height: calc(50rem/16);
    width: calc(50rem/16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14rem/16);
    margin: 0 calc(3rem/16);
    border-radius: 50%;
    border: 1px solid #e3e3e3;
}

.page-item--arrow .page-link{
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    line-height: 0;
}

.page-item.active .page-link{
    background-color: var(--color-default);
    border-color: var(--color-default);
}

.page-link:hover{
    color: var(--color-default);
    background-color: #f0f0f0;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link{
    border-radius: 50%;
}
span.page-link__arrow {
    font-size: calc(14rem/16);
    padding: calc(2rem/16) calc(6rem/16);
    vertical-align: calc(-2rem/16);
}
.page-item--arrow .icon {
    font-weight: bold;
}