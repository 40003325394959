.job-list {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: calc(1rem/16) solid var(--color-grey);
    padding: calc(27rem/16) 0;
}
.job-list:last-child{
    border-bottom: calc(1rem/16) solid var(--color-grey);
}
.job-list__title {
    font-family: var(--font-title-default);
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    margin-bottom: calc(5rem/16);
    text-transform: none;
}
.job-list__title--sm{
    font-size: calc(12rem/16);
    line-height: calc(24/12);
}
.job-list__details {
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    font-family: var(--font-default);
    color: #474743;
    display: flex;
}
.job-list__btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn.btn-outline-default.job-list__btn {
    position: unset;
}
.job-list__details-item{
    display: flex;
    align-items: center;
}
.job-list__details-item:last-child{
    margin-left: calc(19rem/16);
}
@media(max-width: 768px){
    .job-list__title {
        font-size: calc(14rem/16);
        line-height: calc(24/14);
    }
    .job-list__title--sm {
        font-size: calc(8rem/16);
        line-height: calc(24/8);
    }
    .job-list__details {
        font-size: calc(12rem/16);
    }
    .job-list__details{
        flex-direction: column;
    }
    .job-list__details-item:last-child{
        margin-left: 0;
        margin-top: calc(8rem/16);
    }
}