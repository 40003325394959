.cart__heading h1 {
    text-transform: uppercase;
    font-family: var(--font-title-default);
    font-size: calc(30rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(15rem/16);
    }
}
.cart__back-link {
    font-size: calc(16rem/16);
}
.cart__content {
    margin-top: calc(70rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(20rem/16);
    }
    
    @media screen and (min-width: 768px){
       min-height: calc(100vh - 430px);
    }
}


.cart__summary {
    padding: calc(17rem/16) calc(17rem/16) calc(35rem/16) calc(17rem/16);
    background-color: var(--color-light-grey);
}
.cart__summary-heading {
    font-family: var(--font-title-default);
    text-transform: uppercase;
    font-size: calc(16rem/16);
}
.cart__summary-item {
    margin-top: calc(8rem/16);
}
.cart__summary-price {
    border-top: 1px solid #E3E3E3;
    padding-top: calc(8rem/16);
    margin-top: calc(8rem/16);
}
.cart__summary-button:hover .btn.btn-active-gradient {
    background-position: right center;
}
.cart__summary-backlink{
    float: right;
    font-size: calc(18rem/16);
}
.cart__summary-backlink:hover{
    text-decoration: underline;
}

.cart__summary-icon{
    height: calc(40rem/16);
}

.cart__summary-icon.is-applepay{
    height: calc(27rem/16);
}
.cart__summary-voucher{
    gap: calc(5rem/16);
    align-items: center;

    @media screen and (min-width: 768px){
        display: flex;

    }
}
.cart__summary-voucher-remove{
    font-size: calc(16rem/16);
}

.cart-empty {
    background-color: var(--color-light-grey);
    padding: 2.1875rem 1.0625rem;
    text-align: center;
    @media screen and (min-width: 768px) {
        padding: calc(60rem/16);
    }
}

.cart-empty__content {
    margin: 0 auto;
    @media screen and (min-width: 768px) {
        max-width: 65%;
    }
}

.cart-empty .icon {
    font-size: calc(60rem/16);
    margin-bottom: calc(28rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(80rem/16);
        margin-bottom: calc(28rem/16);
    }
}

.cart-empty .cart-empty__button {
    @media screen and (max-width: 767px) {
        margin-top: calc(12rem/16);
    }
}

.cart-payment__icon {
    width: auto;
    height: 2rem;
    margin: 0.25rem;
    @media screen and (min-width: 768px){
        margin: 0 0.5rem;
        height: calc(40rem/16)
    }
}

.cart-payment__icon-apple-pay{
    height: calc(20rem/16);
    @media screen and (min-width: 768px){
       height: calc(27rem/16);
    }
}

.cart__summary-shipping {
    font-family: var(--font-default);
    color: var(--color-text-default);
    text-transform: none;
}
.cart__summary-shipping__site-cart{
    @media (min-width: 768px) {
        font-size:calc(14rem/16);
    }
}
.cart__text--green {
    color: var(--color-success-dark);
}
.btn-continue-shopping {
    font-size: 0.8rem;
    font-family: var(--font-default);
    text-transform: none;
    text-decoration: underline;
}
.btn-continue-shopping:hover{
    text-decoration: underline;
}
.cart__summary-newsletter{
    padding: 1rem;
    border: 1px solid #e3e3e3;
}

.cart__summary-delivery{
    font-size: calc(12rem/16);
    @media screen and (max-width: 767px){
       font-size: calc(10rem/16);
    }
}