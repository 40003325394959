.order-accordion__item{
    padding: calc(26rem/16) calc(19rem/16);
    border-bottom: 1px solid var(--color-grey) !important;
}
.order-accordion__item.is-inactive{
    opacity: 0.5;
}
.order-accordion__item:has(.order-accordion__btn:hover),
.order-accordion__item:has(.order-accordion__btn:focus-visible){
    background-color: var(--color-light-grey);
}
.order-accordion__btn {
    appearance: none;
    border: none;
    background: transparent;
    width: 100%;
    cursor: pointer;
}
.order-accordion__btn:active,
.order-accordion__btn:focus{
    outline: none;
}
.order-accordion__btn-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: calc(16rem/16);
    column-gap: calc(8rem/16);
    font-family:  var(--font-default);
    color: var(--color-text-default);
}
.order-accordion__btn-nr {
    text-align: left;
    width: 100%;
    font-family:  var(--font-title-default);
    font-size: calc(18rem/16);
    color: var(--color-text-default);
}
.order-accordion__btn-icon{
    transition: transform 200ms ease-in-out;
    height: fit-content;
    @media (max-width: 767px) {
        margin-top: calc(18rem/16);
    }
}
.order-accordion__btn[aria-expanded=true] .order-accordion__btn-icon{
    transform: rotate(225deg);
}
.order-accordion__btn-info-status{
    display: flex;
    align-items: center;
    gap: calc(8rem/16);
}
.order-accordion__btn-info-status-icon{
    width: calc(5rem/16);
    height: calc(5rem/16);
    border-radius: 50%;
    background-color: var(--color-success);
}
.order-accordion__btn-info-status-icon.order-accordion__btn-info-status-icon--warning{
    background-color: var(--color-warning);
}
.order-accordion__btn-info-status-icon.order-accordion__btn-info-status-icon--danger{
    background-color: var(--color-danger);
}

/*content*/
.order-accordion__content{
    padding: calc(5rem/16) 0;
}