.person-item__img {
    position: relative;
    margin: 0 auto;
}

.person-item__img img {
    width: 100%;
    height: auto;
    @media screen and (min-width: 768px) {
        filter: grayscale(0);
        transition: filter 0.2s ease;
    }
}

.person-item__contents:hover .person-item__img img {
    @media screen and (min-width: 768px) {
        filter: grayscale(1);
    }
}

.person-item__body {
    padding: 0 calc(24rem/16) calc(24rem/16) ;
    color: var(--color-white);
    background: linear-gradient(0deg, rgba(25,25,20,0.6) 0%, rgba(25,25,20,0.4) 15%, rgba(25,25,20,0.1) 35%, rgba(25,25,20,0) 45%);

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;



    @media screen and (min-width: 768px) {
        opacity: 0;
        transition: opacity 0.2s ease;
    }
}

.person-item__contents:hover .person-item__body {
    @media screen and (min-width: 768px) {
        opacity: 1;
    }
}

.person-item__name {
    font-family: var(--font-title-default);
    text-transform: uppercase;
    font-size: calc(20rem/16);
    line-height: calc(25rem/16);
    transition: color 0.2s ease;
}

.person-item__info {
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    line-height: calc(15rem/16);
    margin-top: 10px;
}

.person-item__phone,
.person-item__mail {
    transition: color 0.2s ease;
    line-height: 1.5;
}
.person-item__phone:hover,
.person-item__mail:hover{
    color: var(--color-third);
}
.person-item__contact {
    margin-top: calc(8rem/16);
}