.breadcrumb {
    background-color: transparent;
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(15/16);
    padding: calc(35rem/16) 0 calc(26rem/16);
    z-index: 20;
    position: relative;
    margin-bottom: 0;

    @media screen and (min-width: 768px){
        max-width: 80%;
    }
    @media screen and (max-width: 767px){
        font-size: calc(13rem/16);
        padding-top: calc(20rem/16);
        padding-bottom: calc(17rem/16);
        margin-bottom: 0;
    }
}
.breadcrumb--pdp{
    @media screen and (max-width: 768px) {
        margin-left: calc(-18rem/16)
    }
}

.breadcrumb-item{
    opacity: 1;
    position: relative;
    color: var(--color-dark-grey);
    font-size: calc(11rem/16);
    line-height: calc(16/11);
}


.breadcrumb .breadcrumb-item.active{
    color: #000;
    opacity: 1;
    font-family: var(--font-default);
}


.breadcrumb--dark{
    color: #fff;
}

.breadcrumb--dark .breadcrumb-item.active{
    color: #fff;
    opacity: 1;
}

.breadcrumb--dark .breadcrumb-item:before{
    color: #fff;
}

.breadcrumb-item+.breadcrumb-item,
.breadcrumb:not(.breadcrumb-collapsible_overflow) .breadcrumb-collapsible__toggle+.breadcrumb-item{
    padding-left: calc(24rem/16);
}

.breadcrumb .breadcrumb-item+.breadcrumb-item:before,
.breadcrumb:not(.breadcrumb-collapsible_overflow) .breadcrumb-collapsible__toggle+.breadcrumb-item:before{
    content: '';
    width: calc(1rem/16);
    height: calc(11rem/16);
    position: absolute;
    left: calc(12rem/16);
    background: var(--color-dark-grey);
    padding: 0;
    transform: rotate(20deg);
    top: calc(2.5rem/16);
}


.breadcrumb--dark .breadcrumb-item+.breadcrumb-item:before{
    background: #fff;
}

.breadcrumb-collapsible {
    width: 100%;
    white-space: nowrap;
    list-style: none;
    display: block;
    gap: calc(12rem/16) 0;
}


.breadcrumb-collapsible li {
    display: inline-block;
}

.breadcrumb-collapsible .breadcrumb-collapsible__toggle {
    display: none;
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow {
    white-space: normal;
    margin-bottom: calc(-10rem/16);
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow li {
    display: none;
    margin-bottom: calc(10rem/16);
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow li:last-child,
.breadcrumb-collapsible.breadcrumb-collapsible_overflow li:first-child,
.breadcrumb-collapsible.breadcrumb-collapsible_overflow li.breadcrumb-collapsible__toggle,
.breadcrumb-collapsible.breadcrumb-collapsible_overflow li.last {
    display: inline-block;
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow li:first-child a:after{
    content: '';
    width: calc(1rem/16);
    height: calc(11rem/16);
    position: absolute;
    right: calc(-12rem/16);
    background: var(--color-dark-grey);
    padding: 0;
    transform: rotate(20deg);
    top: calc(2.5rem/16);
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow .breadcrumb-collapsible__toggle a:before {
    content: "...";
    margin-left: calc(22rem/16);
    color: var(--color-dark-grey);
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow.breadcrumb-collapsible_expanded li:nth-child(2){
    padding-left: calc(22rem/16);
}

.breadcrumb-collapsible.breadcrumb-collapsible_overflow.breadcrumb-collapsible_expanded {
    white-space: normal;
}
.breadcrumb-collapsible.breadcrumb-collapsible_overflow.breadcrumb-collapsible_expanded .breadcrumb-collapsible__toggle a:before {
    display: none;
    content: "--";
}


.breadcrumb-collapsible.breadcrumb-collapsible_overflow.breadcrumb-collapsible_expanded li {
    display: inline-block;
}

/*  .breadcrumb-collapsible.breadcrumb-collapsible_overflow.breadcrumb-collapsible_expanded li:first-child a:after{
      display: none;
  }*/