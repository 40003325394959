.country-suggestion-modal .modal-dialog {
    max-width: calc(520rem/16);
}

.country-suggestion-modal .modal-header {
    padding: calc(20rem/16) calc(20rem/16) 0 calc(20rem/16);

    @media (min-width: 768px) {
        padding: calc(40rem/16) calc(40rem/16) 0 calc(40rem/16);
    }
}

.country-suggestion-modal__title {
    font-size: calc(17rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.country-suggestion-modal .close {
    padding-top: calc(24rem/16);
}

.country-suggestion-modal__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(16rem/16);
}

.country-suggestion-modal__suggestion {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(8rem/16);
    width: 100%;
}