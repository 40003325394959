.btn-back-in-stock {
    display: flex;
    align-items: center;
    justify-content: center;
}
.back-in-stock-modal .modal-dialog {
    @media (min-width: 768px) {
        min-width: calc(646rem/16) !important;
    }
    @media (max-width: 767px) {
        min-width: 100vw !important;
        margin: 0;
    }
}
.back-in-stock-modal__title {
    color: var(--color-text-default);
    font-family: var(--font-title-default);
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    margin-bottom: calc(15rem/16);

    @media (min-width: 768px) {
        font-size: calc(30rem/16);
        line-height: calc(35/30);
    }
}
.back-in-stock-modal__price {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(18rem / 16);
    line-height: 1;
    @media (min-width: 768px) {
        font-size: calc(22rem / 16);
        line-height: calc(26/22);
    }
}
.back-in-stock-modal__price-text {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(18/14);
    }
}
.back-in-stock-modal__text {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    line-height: calc(16/12);
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(18/14);
    }
}
.back-in-stock-modal__text--bold {
    font-family: var(--font-default-bold);
    color: var(--color-text-default);
    font-size: calc(12rem/16);
    line-height: calc(16/12);
    margin-right: calc(8rem/16);
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(18/14);
    }
}

.back-in-stock-modal__subtitle {
    font-family: var(--font-title-default);
    color: var(--color-text-default);
    text-transform: uppercase;
    font-size: calc(10rem / 16);
    line-height: calc(15 / 10);
    margin-bottom: calc(10rem / 16);
    @media (min-width: 768px) {
        font-size: calc(12rem / 16);
        line-height: calc(20 / 12);
    }
}
.back-in-stock-modal__description {
    font-family: var(--font-default);
    color: var(--color-text-default);
    font-size: calc(14rem / 16);
    line-height: calc(26 / 14);
    @media (min-width: 768px) {
        font-size: calc(16rem / 16);
        line-height: calc(26 / 16);
    }
}
.back-in-stock-modal__data-container {
    display: flex;
    margin-top: calc(30rem / 16);
}
.back-in-stock-modal__data-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.back-in-stock-modal__img {
    border: 1px solid #191914;
    margin-right: calc(20rem / 16);
    padding: calc(4rem / 16);
}
.back-in-stock-modal__text-container {
    margin-top: calc(40rem / 16);
    margin-bottom: calc(20rem / 16);
}
.back-in-stock-modal__wysiwyg {
    font-size: calc(10rem / 16);
    line-height: calc(16 / 10);
    color: #474743;
    margin-top: calc(21rem / 16);
}
.back-in-stock-modal__input {
    @media (max-width: 767px) {
        height: calc(45rem/16);
        margin-bottom: calc(20rem / 16);
    }
}
.back-in-stock-modal__input ~ .parsley-errors-list.filled {
    position: absolute;
    bottom: 0;
    top: 100%;
    left: 2rem;
}
.back-in-stock-modal__btn{
    @media (max-width: 767px) {
        width: 100%;
    }
}