.magazine-detail-hero__content-meta {
    display: flex;
    margin-bottom: calc(21rem/16);
    @media(min-width: 768px) {
        margin-bottom: calc(43rem/16);
    }
    @media(max-width: 767px) {
        flex-wrap: wrap;
        gap: 0.3rem;
    }
}
.magazine-detail-hero__meta{
    display: flex;
    align-items: center;
    margin-right: calc(20rem/16);
    @media(min-width: 768px) {
        margin-right: calc(40rem/16);
    }
}
.magazine-detail-hero__meta-text {
    @media(min-width: 768px) {
        font-size: calc(16rem/16);
        line-height: calc(15/16);
    }
    font-size: calc(10rem/16);
    line-height: calc(12/10);
    font-family: "HelveticaNow-Bold";
}
.magazine-detail-hero__meta-icon {
    margin-right: calc(4rem/16);
    @media(min-width: 768px) {
        margin-right: calc(11rem/16);
        font-size: calc(25rem/16);
    }
}
.magazine-detail-hero__title{
    margin-bottom: calc(19rem / 16);
    @media(min-width: 768px) {
        margin-bottom: calc(28rem / 16);
    }
}
.magazine-detail-hero__content{
    margin-top: calc(31rem/16);
    @media(min-width: 768px) {
        margin-top: calc(65rem/16);
    }
}