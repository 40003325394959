.dealer-map{
    position: relative;

    @media screen and (min-width: 768px){
        flex: 1 1 0;
        display: flex;
        min-height: 0;
        overflow: hidden;
    }
}

.dealer-map__list-container {

    @media screen and (min-width: 768px){
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 50%;
        padding-top: calc(70rem/16);
    }
    position: relative;

    background-color: #fff;
    padding-top: calc(70rem/16);
    padding-bottom: calc(50rem/16);
}

.dealer-map__map-container{
    height: 100vh;

    @media screen and (min-width: 768px){
        flex: 1 1 0;
        position: relative;
    }

    @media screen and (max-width: 767px){
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateX(100%);
        z-index: 200;
        transition: all 250ms ease;
    }
}

.dealer-map__map-container.is-open{
    transform: translateX(0);
}

.dealer-map__top-bar{
    @media screen and (max-width: 767px){
        padding: calc(15rem/16);
        background-color: #fff;
        color: #000;
        z-index: 1;
        position: relative;
        font-family: var(--font-default-bold);
    }
}

.dealer-map__close .icon{
    font-size: calc(13rem/16);
    margin-right: calc(5rem/16);
}

.dealer-map__canvas{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.dealer-map__hl{
    letter-spacing: calc(7.5rem/16);
    font-size: calc(24rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(44rem/16);
    }
}


.dealer-map__list{
    max-width: calc(510rem/16);
    width: 100%;
}

.dealer-list-item{
    border-bottom: 1px solid #f1f1f1;
    padding: calc(20rem/16) calc(15rem/16) calc(15rem/16);
    transition: all 150ms ease;
    font-size: calc(16rem/16);
}

.dealer-list-item:hover,
.dealer-list-item.is-active{
    background-color: #f5f5f5;
}

.dealer-list-item__head.collapsed .dealer-list-item__title{
    color: var(--color-default);
}

.dealer-list-item__title{
    text-transform: uppercase;
}

.is-active .dealer-list-item__title{
    color: var(--color-theme-active);
}

.dealer-list-item__body .card-body{
    border-radius: 0;
    border: none;
    display: block;
    padding: 15px 5px;
}

.dealer-list-item__map-toggle{
    font-size: calc(13rem/16);
}

.dealer-list-item__map-toggle>.icon{
    font-size: calc(9rem/16);
}