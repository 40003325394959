.checkout-steps {
    display: flex;
    justify-content: space-between;
    max-width: calc(280rem/16);
    margin: 1rem auto;
    @media screen and (min-width: 768px){
        max-width: calc(370rem/16);
    }
}

.checkout-steps__item{
    border-radius: 50%;
    height: calc(30rem/16);
    width: calc(30rem/16);
    background-color: var(--color-grey);
    position: relative;
}
.checkout-steps__item:hover{
    cursor: default;
}

.checkout-steps__item span {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.checkout-steps__item.checkout-steps__item--active{
    background-color: var(--color-text-default);
}
.checkout-steps__item.checkout-steps__item--finished{
    background-color: var(--color-success-dark);
}
.checkout-steps__item.checkout-steps__item--active:hover{
    cursor: pointer;
}
.checkout-steps__item.checkout-steps__item--active:hover .checkout-steps__description{
    text-decoration: underline;
}
.checkout-steps__item.checkout-steps__item--active .checkout-steps__description {
    color: var(--color-text-default);
    transition: all 0.1s ease-in;
}
.checkout-steps__description{
    position: absolute;
    color: var(--color-grey);
    top: 2.25rem;
    font-size: calc(14rem/16);
    width: unset;
    left: 50%;
    transform: translateX(-50%);
}

.checkout-steps__item:not(:first-child):before{
    content: '';
    position: absolute;
    background-color: var(--color-grey);
    right: calc(15rem/16);
    top: calc(10rem/16);
    height: calc(10rem/16);
    width: calc(85rem/16);
    z-index: -1;
    @media screen and (min-width: 768px){
        width: calc(115rem/16);
    }
}
.checkout-steps__item.checkout-steps__item--active:not(:first-child):before{
    background-color: var(--color-text-default);
}