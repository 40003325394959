.twitter-typeahead {
    /*override inline stlye*/
  /*  display: block !important;*/
    width: 100%;
    /*color: #4c4c4c;*/
    /*text-align: left;*/
}
.tt-input,
.tt-input:focus {
    box-shadow: none;
}
.tt-menu {
    right: 0;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0 2px 6px rgba(7,9,9,.25);
    padding: calc(15rem/16);
    z-index: 100;
    min-width: calc(130rem/16);

    @media screen and (min-width: 768px){
        padding: calc(15rem/16) calc(30rem/16) calc(40rem/16);

    }
}

.tt-dataset:not(:last-child){
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: calc(15rem/16);
}

.tt-dataset-heading {
    position: relative;
    padding: calc(5rem/16) 0;
    font-family: var(--font-default-bold);
    
    @media screen and (max-width: 767px){
       margin-bottom: calc(5rem/16);
    }
}

.tt-dataset-heading__title{
    font-size: calc(18rem/16);
}
.tt-dataset-heading__link {
    font-size: calc(16rem/16);
}

.tt-dataset + .tt-dataset {
    margin-top: 10px;
}
.tt-dataset:empty {
    display: none;
}

.tt-suggestion mark {
    background: none;
    padding: 0;
    color: inherit;
    font-family: var(--font-default-bold);
}
.tt-empty-container {
    padding: 0 10px;
}

.tt-product{
    padding: calc(5rem/16) calc(10rem/16);
    font-size: calc(14rem/16);
    cursor: pointer;
}

.tt-product:hover{
    background-color: #f5f5f5;
}


.tt-product__img{
    width: calc(40rem/16);

    @media screen and (min-width: 768px){
        width: calc(70rem/16);
    }
}

.tt-simple-links{
    font-size: calc(16rem/16);
    padding: calc(5rem/16) 0;
}

.tt-simple-links a:hover{
    text-decoration: underline;
}

/* meili search typeahead*/

.meili-search-form .tt-menu{
    width: 100vw;
    background: #f0f0f0;
    box-shadow: none;
    border: none;
    position: fixed !important;
    left: 0 !important;
    top: calc(107rem/16)!important;
    padding-left: calc(30.5rem/16);
    height: calc(350rem/16);
    overflow: hidden;
    @media screen and (min-width: 767px){
        padding-left: clamp(1rem, -64.107rem + 59.538vw, calc(105rem/16));
    }
    @media screen and (max-width: 767px){
        max-height: 50vh;
        overflow-y: scroll;
    }
}
.typeahead-heading--meili-search {
    font-family: var(--font-default-bold);
    font-size: calc(13rem / 16);
    line-height: calc(24 / 13);
    color: #000;
    padding-bottom: calc(13rem/16);
}

.typeahead-suggestion--meili-search.tt-cursor .tt-product__title--meili-search{
    text-decoration: underline;
}
.tt-product__title--meili-search {
    font-family: var(--font-default);
    font-size: 1rem;
    line-height: calc(30 / 16);
    color: #868E96;
    padding-bottom: calc(13rem/16);
}
.tt-product__title--meili-search .tt-highlight--meili-search {
    color: #000;
}
.meili-search-typeahead__loading-spinner {
    /*width: 110vw;*/
    /*height: 21.875rem;*/
    /*position: absolute;*/
    /*background-color: #f0f0f0;*/
    /*z-index: 110;*/
    /*top: calc(57rem / 16);*/
    /*transform: translateX(-10%);*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.tt-selectable.tt-cursor{
    text-decoration: underline;
    color: #868E96;
}