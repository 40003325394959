.full-width-slide {
    position: relative;
    width: 100%;
    height: auto;
}

.full-width-slide__title {
    font-size: calc(30rem/16);

    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}
.full-width-slide__img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(270deg, rgba(26,40,51,0) 0%, #1A2833 100%);
    opacity: 0.5;
    z-index: 1;
}
.full-width-slide__img {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.full-width-slide__content {
    padding-bottom: calc(40rem/16);
    padding-top: calc(40rem/16);
}
.full-width-slide__button {
    margin-bottom: calc(10rem/16);
}

.full-width-slide__body {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}