@font-face {
    font-family: 'HelveticaNow-Regular';
    src: url("fonts/HelveticaNow-Regular.woff2") format('woff2');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: 'HelveticaNow-Bold';
    src: url("fonts/HelveticaNow-Bold.woff2") format('woff2');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'DrukTextWide-Medium-Web';
    src: url("fonts/DrukTextWide-Medium-Web.woff2") format('woff2');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: 'DrukWide-Medium-Web';
    src: url("fonts/DrukWide-Medium-Web.woff2") format('woff2');
    font-weight: bold;
    font-display: swap;
}
