/*
.img-teaser-slide{
    margin: 0 -73px;
}
*/

.main-content--portal .img-teaser-slide.content-block{
    margin-top: 0;
}

.img-teaser{
    position: relative;

}

.img-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.3;
    z-index: 1;
}

.img-teaser__title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: transparent;
    z-index: 2;
    letter-spacing: calc(5rem/16);
}

.img-teaser img {
    transform: scale(1);
    transition: all 0.3s ease;
}
.img-teaser:hover img {
    @media screen and (min-width: 768px) {
        transform: scale(1.1);
        transition: all 0.3s ease;
    }
}