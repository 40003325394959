.product-quick-add{
    background-color: var(--color-light-grey);
    padding: calc(10rem/16);
}
.product-quick-add__img{
    width: calc(55rem/16);
}

.product-quick-add__title{
    font-family: var(--font-title-default);
    display: inline;
    line-height: 1.5;
    word-break: break-word;
}

.product-quick-add__price{
    font-size: calc(12rem/16);
    line-height: 1;
    margin-top: calc(2rem/16);
}

.product-quick-add__btn{
    padding: 0;
    width: calc(50rem/16);
    height: calc(50rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: var(--color-default);
    border: none;
    font-size: calc(11rem/16);
    transition: all 250ms ease;
}

.product-quick-add__btn:hover{
    background-color: var(--color-default);
    color: #fff;
}

.dropdown-toggle.product-quick-add__btn:after{
    display: none;
}

.product-quick-add-list>li+li{
    margin-top: calc(5rem/16);
}

.product-quick-add__dropdown{
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    border: 0;
    border-radius: 0;
    padding: calc(10rem/16);
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.2);
    max-width: none;
    min-width: calc(180rem/16);
}

.product-quick-add__dropdown-title{
    text-transform: uppercase;
    letter-spacing: calc(1.8rem/16);
    font-size: calc(9rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(8rem/16);
    display: inline-block;
}
.product-quick-add__discountprice {
    font-size: calc(12rem/16);
    line-height: calc(14/12);
}
.product-quick-add__strikeprice {
    font-size: calc(10rem/16);
    line-height: calc(13/10);
    margin-right: 0.5rem;
}
.product-quick-add__discount{
    font-size: calc(10rem/16);
    line-height: calc(13/10);
    padding: calc(4rem/16);
}