
.img-content-teaser{
    position: relative;

    @media screen and (max-width: 767px){
       margin: 0 calc(-20rem/16);
    }
}

.img-content-teaser__badge{
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: calc(14rem/16) calc(25rem/16);
}

.img-content-teaser.has-bottom-space{
    margin-bottom: calc(100rem/16);

    @media screen and (max-width: 767px){
       margin-bottom: calc(60rem/16);
    }
}

.img-content-teaser__body{
    position: absolute;
    bottom: calc(-44rem/16);
    left: calc(15rem/16);
    right: calc(15rem/16);
    box-shadow: 0 50px 40px 0 rgba(0,0,0,0.15);

    @media screen and (min-width: 768px){
        bottom: calc(-55rem/16);
        left: calc(20rem/16);
        right: calc(20rem/16);
        transform: translateZ(100px);
    }

    @media screen and (min-width: 992px){
        left: 13%;
        right: 13%;
    }
}
.img-content-teaser__body:hover .btn.btn-active-gradient,
.img-content-teaser__body:hover .btn.btn-theme-gradient{
    background-position: right center;
}

.img-content-teaser__text{
    background-color: #fff;
    padding: calc(15rem/16) calc(10rem/16);

    @media screen and (min-width: 768px){
        padding: calc(18rem/16);
    }

    @media screen and (min-width: 992px){
        padding: calc(24rem/16) calc(30rem/16);
    }

}

.img-content-teaser__toptitle{
    font-size: calc(12rem/16);
    text-transform: uppercase;
    margin-bottom: calc(3rem/16);
}

.img-content-teaser__title{
    font-size: calc(15rem/16);
    margin-bottom: 0;
}

.img-content-teaser .btn-md{
    border-radius:  0 ;
    @media screen and (max-width: 767px){
        padding: calc(10rem/16) calc(25rem/16);
        font-size: calc(13rem/16);
        line-height: calc(21/13);
    }
}

/* slider */
.img-content-teaser-slider>.slick-list>.slick-track>.slick-slide{
    @media screen and (min-width: 768px){
        margin: 0 calc(15rem/16);
    }

    @media screen and (min-width: 992px){
        margin: 0 calc(20rem/16);
    }
}
.img-content-teaser-slider__item {
    padding: 0 0 calc(160rem/16);

    @media screen and (max-width: 767px){
        padding: 0 0 calc(88rem/16);
    }
}

/*.img-content-teaser-slider__item-reduced{
    @media screen and (min-width: 768px){
       max-width: calc(377rem/16);
    }
}*/
.img-content-teaser-slider{
    @media screen and (max-width: 767px){
        padding-bottom: calc(50rem/16);
    }
}

.img-content-teaser-slider .img-content-teaser{
    @media screen and (max-width: 767px){
        margin: 0 calc(10rem/16);
    }
}
.img-content-teaser-slider .img-content-teaser__body {
    @media screen and (max-width: 767px){
        box-shadow: 0 calc(20rem/16) calc(40rem/16) 0 rgba(0,0,0,0.15);
    }
}

.img-content-teaser-slider .slick-list{

    @media screen and (min-width: 992px){
        margin: 0 calc(-20rem/16);
    }
    @media screen and (min-width: 768px){
        margin: 0 calc(-15rem/16);

    }
}