.complete-the-look{
    background-color: var(--color-light-grey);
    position: relative;
}

.complete-the-look-area{
    margin-top: calc(68rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 1200px) {
        margin-top: calc(150rem/16);
        margin-bottom: calc(210rem/16);
    }
}

.complete-the-look-area .content-heading__title{
    margin-bottom: calc(23rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(36rem/16);
    }
}

.complete-the-look__body{
    padding-top: calc(24rem/16);

    @media screen and (min-width: 1400px) {
        padding-top: calc(27rem/16);
    }
}

.complete-the-look__img{
    background-color: var(--color-light-grey);
    width: calc(216rem/16);
    height: calc(216rem/16);
    margin: 0 auto;
    
    @media screen and (min-width: 1600px){
        width: calc(321rem/16);
        height: calc(321rem/16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        width: calc(250rem/16);
        height: calc(250rem/16);
    }
}

.complete-the-look__teaser{
    padding: calc(30rem/16) calc(31rem/16);

    @media screen and (min-width: 1600px) {
        padding: calc(94rem/16) calc(135rem/16)  calc(94rem/16)  calc(175rem/16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        padding: calc(50rem/16) calc(100rem/16);
    }
}

.complete-the-look__main-img{
    background-color: var(--color-light-grey);
}

.complete-the-look__title{
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    margin-bottom: calc(5rem/16);
    font-family: var(--font-title-bold);
    
    @media screen and (min-width: 1400px) {
        font-size: calc(25rem/16);
        line-height: calc(36/25);
        margin-bottom: calc(18rem/16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px) {
        font-size: calc(20rem/16);
        line-height: calc(20/20);
    }
}

.complete-the-look__text{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 1400px) {
        font-size: calc(16rem/16);
        line-height: calc(26/16);
        margin-bottom: calc(14rem/16);
    }

@media screen and (min-width: 1200px) and (max-width: 1400px) {
        font-size: calc(14rem/16);
        line-height: calc(24/14);
    }
}

.complete-the-look__price{
    font-family: var(--font-title-default);
    font-size: calc(16rem/16);
    line-height: calc(18/16);
    margin-right: calc(10rem/16);

    @media screen and (max-width: 1200px) {
        font-size: calc(18rem/16);
        line-height: calc(18/18);
        margin-right: calc(8rem/16);
    }
}

.complete-the-look__price-info{
    font-size: calc(14rem/16);
    line-height: calc(18/14);

    @media screen and (max-width: 1200px) {
        font-size: calc(13rem/16);
        line-height: calc(14/13);
    }
}

.complete-the-look__options{
    margin-top: calc(36rem/16);

    @media screen and (max-width: 1400px) {
        margin-top: calc(24rem/16);
    }
}

.complete-the-look__options .dropdown{
    margin-bottom: calc(20rem/16);

    @media screen and (max-width: 1400px) {
        margin-bottom: calc(11rem/16);
    }
}

.complete-the-look .btn.btn-cart{
    font-size: calc(10rem/16);
    letter-spacing: calc(1.2rem/16);
    padding: calc(10rem/16);
    height: calc(50rem/16);

    @media screen and (min-width: 1550px){
        font-size: calc(11rem/16);
        letter-spacing: calc(2rem/16);
        padding: calc(10rem/16) calc(25rem/16);
    }
    
    @media screen and (min-width: 768px){
        font-size: calc(9rem/16);
        height: calc(64rem/16);
    }
}

.complete-the-look__loading .loading-spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.complete-the-look__discountprice {
    font-family: var(--font-default-bold);
    font-size: calc(22rem/16);
    line-height: calc(26/22);
    margin-right: calc(10rem/16);

    @media screen and (max-width: 1200px) {
        font-size: calc(18rem/16);
        line-height: calc(18/18);
        margin-right: calc(8rem/16);
    }
}
.complete-the-look__strikeprice {
    font-size: calc(10rem/16);
    line-height: calc(11/10);
    margin-right: 0.5rem;

    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(13/14);
        margin-right: calc(10rem/16);
    }
}
.complete-the-look__discount {
    font-size: calc(10rem/16);
    line-height: calc(11/10);
    padding: calc(4rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(13/14);
    }
}