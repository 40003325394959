.img-split-teaser {
    padding: calc(56rem/16) 0 calc(56rem/16) calc(30rem/16);
}

.img-split-teaser.small-padding {
    padding: calc(25rem/16) 0 calc(25rem/16) calc(30rem/16);
}

.img-split-teaser.small-padding-top {
    padding: calc(25rem/16) 0 calc(56rem/16) calc(30rem/16);
}

.img-split-teaser.small-padding-bottom {
    padding: calc(56rem/16) 0 calc(25rem/16) calc(30rem/16);
}

.img-split-teaser__item {
    padding: calc(5rem/16) calc(37rem/16) calc(5rem/16) 0;
}
@media(max-width: 767px){
    .img-split-teaser__item {
        padding: 0 calc(16rem/16) calc(20rem/16) 0;
    }
}

.img-split-teaser--offset div:nth-of-type(2n) {
    margin-top: calc(20rem / 16);
}


@media (min-width: 768px) {
    .img-split-teaser {
        padding: calc(80rem/16) 0 calc(80rem/16) calc(60rem/16);
    }
    .img-split-teaser.small-padding {
        padding: calc(65rem/16) 0 calc(80rem/16) calc(60rem/16);
    }

    .img-split-teaser.small-padding-top {
        padding: calc(65rem/16) 0 calc(80rem/16) calc(60rem/16);
    }

    .img-split-teaser.small-padding-bottom {
        padding: calc(80rem/16) 0 calc(65rem/16) calc(60rem/16);
    }

    .img-split-teaser__title {
        font-size: calc(25rem/16);
        line-height: calc(30/25);
        margin-bottom: calc(21rem/16);
    }

    .img-split-teaser__content {
        margin-bottom: calc(45rem/16);
    }

    .img-split-teaser--offset div:nth-of-type(2n) {
        margin-top: calc(50rem/16);
    }

}

@media (min-width: 1200px) {
    .img-split-teaser {
        padding: calc(150rem/16) 0 calc(150rem/16) calc(105rem/16);
    }

    .img-split-teaser.small-padding {
        padding: calc(80rem/16) 0 calc(80rem/16) calc(105rem/16);
    }

    .img-split-teaser.small-padding-top {
        padding: calc(80rem/16) 0 calc(150rem/16) calc(105rem/16);
    }

    .img-split-teaser.small-padding-bottom {
        padding: calc(150rem/16) 0 calc(80rem/16) calc(105rem/16);
    }

    .img-split-teaser__text,
    .img-split-teaser__text--bold {
        width: 50%;
        margin: unset;
    }
}

.img-split-teaser__content {
    margin-bottom: calc(11rem/16);
}

.img-split-teaser__title {
    font-size: calc(18rem/16);
    margin-bottom: calc(9rem/16);
    font-family: var(--font-title-bold);
    text-transform: uppercase;
}

@media (min-width: 768px) {
    .img-split-teaser__title {
        font-size: calc(50rem/16);
        margin-bottom: calc(19rem/16);
    }
    .img-split-teaser__text {
        font-size: calc(16rem/16);
    }
}

.img-split-teaser .content-anchor-nav__item-icon{
    display: none;
}

.img-split-teaser .btn-link-underline {
    font-size: calc(11rem/16);
}

.img-split-teaser .slick-track {
    margin-left: 0;
}

/* IMAGE SPLIT TEASER ANCHOR NAV */

@media (min-width: 768px) {
    .img-split-teaser--anchor-teaser .img-split-teaser__title {
        font-size: calc(50rem/16);
        margin-bottom: calc(19rem/16);
    }

    .img-split-teaser--anchor-teaser .content-anchor-nav__item-icon {
        font-size: calc(119rem/16);
    }

    .img-split-teaser--anchor-teaser .img-split-teaser__text {
        font-size: calc(18rem/16);
        line-height: calc(26/18);
        margin-bottom: calc(32rem/16);
        font-family: var(--font-default);
    }

    .img-split-teaser--anchor-teaser .img-split-teaser__text--bold {
        font-size: calc(18rem/16);
        line-height: calc(26/18);
        margin-bottom: calc(32rem/16);
        font-family: var(--font-title-bold);
    }

    .img-split-teaser--anchor-teaser .img-split-teaser__content {
        margin-bottom: calc(45rem/16);
    }

}

.img-split-teaser--anchor-teaser .img-split-teaser__title {
    font-size: calc(18rem/16);
    margin-bottom: calc(9rem/16);
}

.img-split-teaser--anchor-teaser .img-split-teaser__text {
    font-size: calc(16rem/16);
    margin-bottom: calc(24rem/16);
    font-family: var(--font-default);
}

.img-split-teaser--anchor-teaser .img-split-teaser__text--bold {
    font-family: var(--font-title-bold);
    font-size: calc(14rem/16);
    line-height: calc(16/14);
}

.img-split-teaser--anchor-teaser .content-anchor-nav__item-icon {
    display: block;
    font-size: calc(67rem/16);
    margin-bottom: calc(20rem/16);
}

.img-split-teaser--anchor-teaser .img-split-teaser__content {
    margin-bottom: calc(24rem/16);
}
