.form-control{
    border-radius: 0;
    border: 1px solid var(--color-text-muted);
    padding: calc(8rem/16) calc(20rem/16);
    position: relative;
    transition: all 0.4s ease;
    height: calc(56rem/16);
}

.text-area .form-control {
    padding: calc(14rem/16) calc(20rem/16);
}

.form-control:focus{
    border-color: var(--color-dark-grey);
    box-shadow: none;
}

.form-control--lg{
    height: calc(60rem/16);
}
.form-group {
    margin-bottom: calc(20rem/16);
    position: relative;
}

.form-group__icon {
    position: absolute;
    right: calc(10rem/16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(20rem/16);
}

.select{
    position: relative;
}

.select>select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    padding-right: calc(40rem/16);
    cursor: pointer;
}
.select select::-ms-expand {
    display: none;
}

.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    speak: none;
    position: absolute;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    right: calc(15rem/16);
    font-size: calc(6rem/16);
    pointer-events: none;
    display: flex;
    align-items: center;
    color: var(--color-dark-grey);
    z-index: 1;
}

/* floating labels */
.form-group .floating-label,
.input-group .floating-label {
    color: var(--color-text-muted);
    font-size: calc(16rem/16);
    font-family: var(--font-default);
    z-index: 3;
    top: calc(13rem/16);
}
.text-area.form-group .floating-label {
    top: 11%;
}
.form-control.has-value~.floating-label,
.form-control:focus~.floating-label,
select~.floating-label,
.form-control.has-value-always~.floating-label{
    transform: translateY(-110%) scale(.72);
    left:0;
}

textarea.form-control.has-value~.floating-label,
textarea.form-control:focus~.floating-label {
    transform: translateY(-120%) scale(.72);
    left:0;
}

/* focus effect */
.form-control ~ .animate-border:before,
.form-control ~ .animate-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--color-dark);
    transition: all 0.3s ease;
}
.form-control ~ .animate-border:after {
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
}
.form-control ~ .animate-border i:before,
.form-control ~ .animate-border i:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 0;
    background-color: var(--color-dark);
    transition: all 0.4s ease;
}
.form-control ~ .animate-border i:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
}
.form-control:focus ~ .animate-border:before,
.form-control:focus ~ .animate-border:after {
    width: 100%;
    transition: all 0.3s ease;
}
.form-control:focus ~ .animate-border i:before,
.form-control:focus ~ .animate-border i:after {
    height: 100%;
    transition: all 0.4s ease;
}

.form-filter__item {
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
}
.form-filter__item label {
    min-width: calc(120rem/16);
}

.input-group-append .btn,
.row--form-group .btn{
    line-height: calc(24/14);
}


.form-section:not(.form-section--no-margin){
    margin-top: calc(35rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(60rem/16);
    }
}

.form-section__titles{
    font-family: var(--font-title-default);
    letter-spacing: 0;
    font-size: calc(16rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.form-section__titles--same-height{
    @media screen and (min-width: 768px){
       min-height: calc(72rem/16);
    }
}

.form__add-form-section{
    margin-top: calc(30rem/16);
    border: none;
    border-radius: 0;
    background-color: #f5f5f5;
    padding: calc(20rem/16) calc(30rem/16);
    letter-spacing: calc(4rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    cursor: pointer;

    @media screen and (min-width: 768px){
        margin-top: calc(50rem/16);
        padding: calc(30rem/16) calc(60rem/16);
    }
}
.form__add-form-section:hover,
.form__add-form-section:focus{
    background-color: var(--color-lightgrey);
}

.form__add-form-section.hide{
    display: none;
}

.form__add-form-section .icon{
    font-size: calc(17rem/16);
    vertical-align: calc(-2rem/16);
}

.form-section--alternative.hide{
    display: none;
}

.form__add-form-section.hide-totally{
    display: none;
}

.custom-checkbox.disable{
    opacity: 0.5;
}
.custom-checkbox.disable label{
    cursor: auto;
}


.input-group-md {
    @media screen and (min-width: 768px){
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }

}

.input-group-md>.custom-file,
.input-group-md>.custom-select,
.input-group-md>.form-control {
    @media screen and (min-width: 768px){
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
}

.date-input .btn{
    position: absolute;
    right: 0;
    bottom: 1px;
    top: 1px;
    padding: 15px 20px;
    font-size: 17px;
    background-color: var(--color-default);
    color: #fff;
}

.ui-datepicker{
    z-index: 10!important;
}
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
    border-width: 2px;
}
.form-group.is-valid::after,
.form-group.has-value::after{
    content: var(--icon-checkmark);
    font-size: 1rem;
    color: #28a745;
    font-family: iconfont;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
.form-group.is-invalid::after{
    content: var(--icon-close);
    font-size: 1rem;
    color: #dc3545;
    font-family: iconfont;
    position: absolute;
    right: 1rem;
    top: 30%;
    transform: translateY(-30%);
}

.input-group-rounded {
    .form-control {
        border-radius: 5000px 0 0 5000px;
        padding-left: calc(30rem/16);
        text-align: center;
    }

    .input-group-append button {
        border-radius:  0 5000px 5000px 0;
    }

    @media screen and (max-width: 768px) {
        .form-control{
            border-radius:  50vh;
        }
        .input-group-append button{
            border-radius:  50vh;
        }
    }
}