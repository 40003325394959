.article-preview {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    position: relative;
    padding-bottom: calc(65rem/16);

    @media screen and (max-width: 767px) {
        padding-bottom: calc(80rem/16);
    }
}
.article-preview:before {
    position: absolute;
    content: '';
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 30%);
    width: 100%;
    height: 100%;
}

.article-preview__body {
    position: relative;
}

.article-preview__date-wrapper {
    position: relative;
    width: calc(135rem/16);
    height: auto;
}
.article-preview__text-wrapper {
    position: relative;
    max-width: calc(510rem/16);
    height: auto;

    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}

.article-preview__img {
    max-width: calc(546rem/16);
    height: auto;
}

.article-preview__title {
    font-size: calc(20rem/16);
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(40rem/16);
        font-size: calc(35rem/16);
    }
}
.article-preview__category {
    text-transform: uppercase;
    letter-spacing: 0.6px;
    margin-bottom: calc(10rem/16);

    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}
.article-preview__date {
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    color: #1A2833;
    opacity: 0.4;
    transform-origin: center;
    letter-spacing: 5px;
    line-height: calc(55/30);

    @media screen and (min-width: 768px) {
        transform: rotate(-90deg) translateX(-98px);
        font-size: calc(30rem/16);
    }
}

.article-preview__author {
    margin-bottom: calc(30rem/16);
}
.article-preview__author-img {
    position: relative;
    margin-right: calc(20rem/16);
}
.article-preview__author-img img {
    position: relative;
    z-index: 2;
}
.article-preview__author-img:after{
    content: '';
    position: absolute;
    width: calc(65rem/16);
    height: calc(65rem/16);
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    opacity: 0.6;
    z-index:0;
}
.article-preview__author-name {
    font-family: var(--font-special);
    font-size: calc(25rem/16);
}

.article-preview__button {
    margin-top: calc(30rem/16);
}