
.cart-item {
    padding: calc(15rem/16) 0;
    border-bottom: 1px solid var(--color-grey);
}

.cart-item__img{
    width: calc(190rem/16)
}

.cart-item--overlay .cart-item__img{
    width: calc(78rem/16);
}

.cart-item__body {
    line-height: calc(20/14);
    font-size: calc(14rem/16);
    padding-left: calc(10rem/16);
}

.cart-item__title {
    font-family: var(--font-title-default);
    font-size: calc(16rem/16);
    margin-bottom: calc(3rem/16);
    display: inline-block;
}

.cart-item__price {
    font-family: var(--font-default);
    font-size: calc(14rem/16);
}

.cart-item--overlay .cart-item__price{
    display: none;
}

.btn.cart-item__delete {
    font-size: calc(14rem/16);
    padding: 0;
    color: var(--color-default);
}
.cart-item__delete:hover {
    @media screen and (min-width: 786px) {
        color: var(--color-danger);
    }
}
.cart-item__delete .icon {
    vertical-align: -1px;
}
.cart-item__amount .form-control {
    padding: calc(5rem/16) calc(15rem/16);
    min-width: calc(60rem/16);
    height: calc(35rem/16);
}

.cart-item .number-spinner{
    margin-top: calc(8rem/16);
}

.cart-item--overlay .alert__icon{
    display: none;
}

.cart-item--overlay .alert{
    font-size: calc(12rem/16);
    line-height: 1.5;
    padding: calc(7rem/16) calc(10rem/16);
}
.cart-item__discountprice{
    font-size: calc(12rem/16);
    line-height: calc(14/12);
}
.cart-item__strikeprice {
    font-size: calc(10rem/16);
    line-height: calc(13/10);
}
.cart-item__discount{
    padding: calc(4rem/16);
    margin-left: calc(10rem/16);
    font-size: calc(10rem/16);
    line-height: calc(13/10);
}
.cart-item__body-item .btn {
    border-radius: 0;
    border: none;
}