.spinner {
    width: calc(16rem/16);
    height: calc(16rem/16);
    border: calc(2rem/16) solid rgba(255, 255, 255, 0.1);
    border-top: calc(2rem/16) solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}