.size-checkbox{
    position: relative;
    display: flex;
}

.size-checkbox label{
    cursor: pointer;
}

.size-checkbox:before,
.size-checkbox:after {
    content: " ";
    display: table;
}.size-checkbox:after {
     clear: both;
 }
.size-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.size-checkbox__text{
    width: calc(47rem/16);
    height: calc(45rem/16);
    font-size: calc(9rem/16);
    line-height: calc(11/9);
    letter-spacing: calc(1.8rem/16);
    background-color: var(--color-light-grey);
    transition: background-color 200ms ease, color 200ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    hyphens: auto;
}

.size-checkbox__input:checked ~ .size-checkbox__text,
.size-checkbox__text:hover {
    background-color: var(--color-dark);
    color: #fff;
}
