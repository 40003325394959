.img-content-sujet{
    position: relative;
    padding-bottom: calc(115rem/16);

    @media screen and (min-width: 768px){
        padding-bottom: calc(100rem/16);
    }

    @media screen and (min-width: 1200px){
        padding-bottom: calc(180rem/16);
    }

}

.img-content-sujet__bg{
    position: absolute;
    top: calc(100rem/16);
    bottom: -20%;
    left: 0;
    right: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    transform: skewY(-8deg);
    
    @media screen and (min-width: 768px){
        top: calc(-64rem/16);

    }
}

.img-content-sujet__inner>.container{
    position: relative;
    z-index: 2;
}

.img-content-sujet__img-container{
    position: relative;
}

.img-content-sujet__body{
    @media screen and (min-width: 768px){
       margin-top: 22%;
    }
    
    @media screen and (min-width: 1200px){
       max-width: calc(360rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1200px){
       padding-left: calc(50rem/16);
    }
}

.img-content-sujet__img-small{
    width: 64.33333%;
    position: absolute;
    right: -24%;
    top: calc(80rem/16);
    z-index: 2;

    @media screen and (min-width: 768px){
        width: calc(50% - 36px);
        right: calc(-16% + 18px);
        bottom: -32%;
        top: auto;
        box-shadow: 0 30px 50px 0 rgba(0,0,0,0.3);
    }

    @media screen and (min-width: 1200px){
        right: calc(-25% + 18px);
        bottom: -25%;
    }
}

.card-video{
    object-fit: cover;
}

.img-content-sujet__img-small.no-box-shadow{
    @media screen and (min-width: 768px){
       box-shadow: none;
    }
}

.img-content-sujet__title{
    font-family: var(--font-title-default);
    font-size: calc(18rem/16);
    line-height: calc(24/18);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
        line-height: calc(36/25);
        margin-left: -13%;
        width: 100%;
    }

    @media screen and (min-width: 1200px){
        margin-left: -35%;
    }
}

.img-content-sujet__text{
    font-size: calc(13rem/16);
    line-height: calc(24/13);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
        line-height: calc(26/16);
    }
}

.img-content-sujet__body .btn-inline-list{
    margin-top: calc(25rem/16);
}

.img-content-sujet__body .btn-inline-list .btn{
    margin-bottom: calc(15rem/16);
}

.img-content-sujet__body .btn-inline-list .btn:not(:last-child){
    margin-right: calc(15rem/16);
}