.acc-order__prices{
    padding: calc(30rem/16) 0;
    border-bottom: calc(1rem/16) solid var(--color-grey);
    @media (min-width: 768px) {
        padding: calc(45rem/16) 0;
    }
}
.acc-order__prices-list{
    display: flex;
    flex-direction: column;
    gap: calc(5rem/16);
}
.acc-order__prices-item{
    font-size: calc(14rem/16);
    font-family: var(--font-title-default);
}
.acc-order__prices-total{
    font-size: calc(16rem/16);
}
.acc-order__prices-total .acc-order__prices-item{
    font-size: calc(18rem/16);
}

.acc-order__status{
    display: flex;
    gap: calc(8rem/16);
    align-items: center;
}
.acc-order__infos{
    padding: calc(44rem/16) 0;
    border-bottom: calc(1rem/16) solid var(--color-grey);
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}
.acc-order__status-indicator{
    display: inline-block;
    width: calc(5rem/16);
    height: calc(5rem/16);
    border-radius: 50%;
    background-color: var(--color-success);
}
.acc-order__status-indicator.acc-order__status-indicator-warning{
    background-color: var(--color-warning);
}
.acc-order__status-indicator.acc-order__status-indicator-danger{
    background-color: var(--color-danger);
}

.acc-order__bottom{
    padding-top: calc(44rem/16);
    padding-bottom: calc(35rem/16);
}