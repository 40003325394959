.nav-tabs .nav-link{
    font-family: var(--font-default);
    font-size: calc(15rem/16);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    padding: calc(12rem/16) calc(15rem/16);
}

.nav-tabs .nav-link:after{
    content: '';
    position: absolute;
    width: 100%;
    height: calc(3rem/16);
    background-color: #000;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    transition: transform 250ms ease;
}

.nav-tabs .nav-link:not(.active):hover{
    border-color: transparent;
}

@media screen and (max-width: 767px){
    .nav-tabs .nav-link:not(.active):after{
        background-color: transparent;
    }
}
.nav-tabs .nav-link:not(.active):hover:after,
.nav-tabs .nav-link.active:after {
     transform: translateX(-50%) translateY(0);
}

.nav-tabs--search .nav-link{
    @media screen and (max-width: 767px){
       padding: calc(10rem/16);
        font-size: calc(11rem/16);
    }
}

/* Primary */

.nav-tabs--primary {
    margin-bottom: calc(22rem/16);
    display: flex;
    justify-content: center;
}
.nav-tabs--primary .nav-link {
    border: none;
    background-color: var(--color-white);
    font-size: 1rem;
    line-height: calc(26/16);
    color: #000000;
    font-family: var(--font-default);
    padding: 0;
    margin: 0 calc(30rem/16);
    text-transform: none;
    padding-bottom: calc(5rem/16);
}

.nav-tabs--primary .nav-link:focus {
    outline: none;
}