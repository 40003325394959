.products-campaign-slide{
    margin-top: calc(57rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(99rem/16);
    }
}
.products-campaign-slide .content-heading{
    margin-top: 0;
    margin-bottom: calc(27rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(36rem/16);
        margin-bottom: calc(35rem/16);
    }
}

.products-campaign-slide.slide{
    position: relative;
    
    @media screen and (max-width: 767px){
       padding-top: 0;
    }
}

.products-campaign-slide .product-slider{
    @media screen and (max-width: 768px){
        padding-top: calc(111rem/16);
        margin-right: calc(-30.5rem/16);
    }
}

.products-campaign-slide .product-slider .slider__arrow.slick-next{
    @media screen and (min-width: 768px){
        right: calc(-10rem/16);
    }

    @media screen and (min-width: 1200px){
        right: calc(10rem/16);
    }

    @media screen and (min-width: 1600px){
        right: calc(20rem/16);
    }
}
.products-campaign-slide .product-slider .slider__arrow.slick-prev{
    @media screen and (min-width: 768px){
        left: calc(-15rem/16);
    }

    @media screen and (min-width: 1200px){
        left: calc(-15rem/16);
    }

    @media screen and (min-width: 1600px){
        left: calc(-20rem/16);
    }
}

.products-campaign-slide .products-slider .product-color__slide-btn{
    @media screen and (max-width: 768px) {
        width: calc(43rem/16);
        height: calc(43rem/16);
    }
}
.products-campaign-slide .product-teaser__tab{
    @media screen and (max-width: 768px) {
        width: calc(43rem/16);
        height: calc(43rem/16);
        padding: calc(6rem/16);
    }
}
.products-campaign-slide .product-teaser__tabs{
    @media screen and (max-width: 768px) {
        width: calc(100% - 43px);
    }
}

.products-campaign-slide .content-heading__toptitle{
    @media screen and (max-width: 768px) {
        margin-bottom: calc(11rem/16);
        letter-spacing: 0;
    }
}

.products-campaign-slide .product-slider .slick-list{
    //overflow: hidden;
}

.products-campaign-slide__img-container{
    position: relative;
    
    @media screen and (max-width: 767px){
       margin: 0 calc(-20rem/16);
    }
}

.products-campaign-slide__img-container img{
    @media screen and (max-width: 767px){
        max-width: 100%;
        height: auto;
    }
}

.products-campaign-slide__img-wrapper{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    @media screen and (max-width: 767px){
        left: calc(-30.5rem/16);
        right: calc(-30.5rem/16);
    }
}

.products-campaign-slide__img-wrapper .container{
    @media screen and (max-width: 767px){
       padding: 0;
    }
}

.product-slider-wrapper{
    @media screen and (max-width: 767px){
        position: relative;
    }
    @media screen and (min-width: 768px){
        clip-path: inset( -100vw -100vw -100vw -2rem );
    }
}

.products-campaign-slide .product-teaser__wrapper{
    background-color: #fff;
    margin-bottom: 2rem;
    @media screen and (min-width: 768px){
       padding: 0 calc(15rem/16);
    }
}

.content-heading__side{
    position: inherit;
    background-color: var(--color-white);
}