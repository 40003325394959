.product-grid-banner__content {
    position: absolute;
    bottom: calc(30rem/16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 calc(23rem/16);
}
.product-grid-banner__title {
    color: var(--color-white);
    font-size: calc(25rem/16);
    line-height: calc(30/25);
    margin-bottom: calc(19rem/16);
}