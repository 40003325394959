.magazine-teaser__background {
    position: relative;
    color: var(--color-white);
}
.magazine-teaser__background:before {
    content: " ";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(25,25,20,0) 0%, rgba(25,25,20,0.6) 48.55%, rgba(25,25,20,0.8) 100%);
    inset: 0;
}
.magazine-teaser__background--light {
    color: var(--color-default);
}
.magazine-teaser__background--light:before {
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.6) 48.55%, rgba(255,255,255,0.8) 100%);
}
.magazine-teaser__content{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (min-width: 768px) {
        padding: 0 calc(29rem/16) calc(31rem/16) calc(29rem/16);
    }
    padding: 0 calc(20rem/16) calc(20rem/16) calc(20rem/16);
}
.magazine-teaser__title {
    @media(min-width: 768px){
        font-size:  calc(20rem/16);
        line-height: calc(25/20);
        padding-bottom: calc(21rem/16);
        margin-bottom: calc(21rem/16);
    }
    word-break: break-word;
    font-size:  calc(14rem/16);
    line-height: calc(16/14);
    font-family: "DrukWide-Medium-Web";
    padding-bottom: calc(14rem/16);
    border-bottom: 2px solid;
    margin-bottom: calc(18rem/16);
}
.magazine-teaser__content-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
}
.magazine-teaser__meta{
    display: flex;
    align-items: center;
    margin-right: calc(18rem/16);
}
.magazine-teaser__meta-text {
    @media(min-width: 768px) {
        font-size: calc(12rem/16);
        line-height: calc(15/12);
    }
    font-size: calc(10rem/16);
    line-height: calc(12/10);
    font-family: "HelveticaNow-Bold";
}
.magazine-teaser__meta-icon {
    margin-right: calc(8rem/16);
   font-size: calc(16rem/16);
}
.magazine-teaser__tag-container {
    z-index: 2;
    position: absolute;
    right: calc(10rem/16);
    top: calc(10rem/16);
    @media(min-width: 768px) {
        right: calc(30rem/16);
        top: calc(20rem/16);
    }
}
.magazine-teaser__tag {
    font-size: calc(12rem/16);
    color: #000;
    line-height: calc(15/12);
    background-color: #fff;
    border-radius: calc(25rem/16);
    padding: calc(7rem/16) calc(14rem/16);
    margin-left: calc(7rem/16);
    font-family: "HelveticaNow-Bold";
}
.magazine-teaser__img{
    object-fit: cover;
}
.magazine-teaser__meta-reading {
    white-space: nowrap;
}
.magazine-teaser-row .magazine-teaser__title {
    @media(max-width: 1200px) {
        font-size: 1rem;
        line-height: 1.25;
        padding-bottom: 0.5625rem;
        margin-bottom: 0.5625rem;
    }
}
/*.magazine-teaser-row .magazine-teaser__content {
    @media(max-width: 1200px) {
        padding: 0 1rem;
    }
}*/

.magazine-teaser-row.product-teaser-row .content-heading__title{
    @media(min-width: 1000px){
        font-size: calc(50rem/16);
        line-height: calc(60/50);
    }
        word-break: break-word;
}
.magazine-teaser-row.product-teaser-row .content-heading{
    margin-top: 2rem;
    @media(max-width: 1300px) and (min-width: 768px) {
        margin-top: 0rem;
    }
}
