.anchor-nav .anchor-nav__item {
    text-transform: uppercase;
    border: 1px solid var(--color-dark);
    font-family: var(--font-default-bold);
    background-color: var(--color-white);
    margin-bottom: 0.5rem;
    @media screen and (min-width: 768px){
        margin-bottom: 0;
    }
}
.anchor-nav .anchor-nav__item .nav-link {
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    @media screen and (min-width: 768px){
        padding: 1rem 2.5rem;
    }
}
.anchor-nav .anchor-nav__item:active,
.anchor-nav .anchor-nav__item:focus,
.anchor-nav .anchor-nav__item:hover {
    color: var(--color-white);
    background-color: var(--color-dark);
}

.anchor-id-scroll-margin{
    scroll-margin-top:calc(60rem/16);
    @media screen and (min-width: 768px){
        scroll-margin-top:calc(80rem/16);
    }
}