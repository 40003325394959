.text-img-split-teaser .h2 {
    font-size: calc(24rem/16);
}

.text-img-split-teaser__content {
    padding: clamp(calc(50rem/16), 4.77vw + 0.447rem, calc(105rem/16));
    display: flex;
    flex-direction: column;

    height: 100%;
}
.text-img-split-teaser__content.text-img-split-teaser__content--center {
    justify-content: center;
}
.text-img-split-teaser__content.text-img-split-teaser__content--bottom {
    justify-content: flex-end;
}
.text-img-split-teaser__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.text-img-split-teaser__title {
    font-size: clamp(calc(18rem/16), 1.908vw + 0.112rem, calc(25rem/16));
    line-height: calc(32/25);
    font-family: var(--font-title-default);
}

/*@media (min-width: 768px) {*/
/*    .text-img-split-teaser__title {*/
/*        font-size: calc(30rem/16);*/
/*        hyphens: auto;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .text-img-split-teaser__title {*/
/*        font-size: calc(40rem/16);*/
/*    }*/
/*}*/

/* BUTTONS */
.text-img-split-teaser__btn {
    position: relative;
    transition: all .3s ease-in-out;
    background: transparent;
    border-radius: 50vh;

    @media screen and (max-width: 767px){
       padding-left: calc(30rem/16);
       padding-right: calc(30rem/16);
    }

}

.text-img-split-teaser__btn {
    @media screen and (max-width: 767px){
       text-transform: uppercase;
    }
}

.text-white .text-img-split-teaser__btn:hover,
.text-white .text-img-split-teaser__btn:focus{
    background-color: #fff;
    color: var(--color-text-default);
}

/*@media (min-width: 768px) {*/
/*    .text-img-split-teaser__content {*/
/*        padding: calc(70rem/16);*/
/*        height: 100%;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .text-img-split-teaser__content {*/
/*        padding: calc(105rem/16);*/
/*    }*/
/*}*/

