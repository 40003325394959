.quote-slide{
    padding: calc(100rem/16) 0;
    background-color: var(--color-light-grey);
    position: relative;
    z-index: 2;
}

.quote{
    max-width: calc(840rem/16);
    width: 100%;
    margin: 0 auto;
}

.quote__img{
    position: relative;
    margin: 0 auto;

    @media screen and (max-width: 767px){
       width: calc(236rem/16);
    }
}

.quote__img img{
    position: relative;
    z-index: 2;
    border-radius: 50%;
    @media screen and (min-width: 768px){
       width: calc(236rem/16);
       height: calc(236rem/16);
    }
}

.quote__img:before{
    content: '';
    position: absolute;
    width: calc(290rem/16);
    height: calc(290rem/16);
    background-color: #fff;
    top: calc(-27rem/16);
    left: calc(-27rem/16);
    border-radius: 50%;
    opacity: 0.6;

    @media screen and (max-width: 767px) {
        width: calc(240rem/16);
        height: calc(240rem/16);
        top: calc(-20rem/16);
        left: calc(-2rem/16);
    }
}

.quote__img--circle {
    border: 15px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    @media screen and (max-width: 767px) {
        max-width: calc(240rem/16);
        max-height: calc(240rem/16);
        margin: 0 auto;
    }
}

.quote__body{
    font-size: calc(20rem/16);
    line-height: calc(29/20);
    
    @media screen and (min-width: 768px){
        margin-left: calc(65rem/16);
        min-width: calc(540rem/16);
    }
    
    @media screen and (max-width: 767px){
        padding: 0 calc(18rem/16);
        margin-top: calc(50rem/16);
    }
}

.quote__info{
    font-size: calc(16rem/16);
}

.quote__author{
    font-family: var(--font-special);
    font-size: calc(40rem/16);
    line-height: calc(40/38);
}