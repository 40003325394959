.customer-account-header {
    border-bottom: 2px solid  var(--color-text-default);
    margin-bottom: calc(25rem/16);
}
.customer-account-header__title {
    color: var(--color-text-default);
    font-family: var(--font-title-default);
    font-size: calc(30rem/16);
    line-height: calc(35/30);
    text-align: center;
}
.customer-account-header__subtitle {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(26/16);
    text-align: center;
}